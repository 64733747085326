import AccordionButlletinProps from './AccordionButlletinProps'
import './AccordionButlletin.sass'
import styles from './AccordionButlletin.module.sass'
import Accordion from 'react-bootstrap/Accordion';
import CardButlletinDoc from './card_butlletin_doc/CardButlletinDoc';
import FlagExtra from '../../layout/flag_extra/FlagExtra';
import BtnSecondary from '../../layout/buttons/secondary/BtnSecondary';
import { useEffect, useState } from 'react';
import BtnLink from '../../layout/buttons/link/BtnLink';
import moment from 'moment';
import PDF from '../../../assets/icons/PDF.svg'
import CardSumari from '../../cards/card_sumari/CardSumari';
import loadingLogo from '../../../assets/loading/loading.gif';
import { Link } from 'react-router-dom';

function AccordionButlletin(props: AccordionButlletinProps) {

    const [isLinkVisited, setIsLinkVisited] = useState(false);

    useEffect(() => {
        const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)selectedItemsButlletins\s*=\s*([^;]*).*$)|^.*$/, "$1");
        const myArray = cookieValue ? JSON.parse(cookieValue) : [];
        const existeElemento = myArray.findIndex((item: any) => item == props.docName);        
        if (existeElemento != -1) {
            setIsLinkVisited(true);
        }
    },[])
    
    const handleLinkClick = () => {
        setIsLinkVisited(true);
        const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)selectedItemsButlletins\s*=\s*([^;]*).*$)|^.*$/, "$1");
        const selectedItemsButlletins = cookieValue ? JSON.parse(cookieValue) : [];
        selectedItemsButlletins.push(props.docName);
        document.cookie = `selectedItemsButlletins=${JSON.stringify(selectedItemsButlletins)};`;
    };

    let year = parseInt(moment(props.title).format('YYYY')) - 1988;
    let num = props.counter;
    let yearString = "";
    let numString = "";
    if(year.toString().length == 1) yearString = '00' + year
    else if(year.toString().length == 2) yearString = '0' + year
    if(num.toString().length == 1) numString = '00' + num
    else if(num.toString().length == 2) numString = '0' + num
    else numString = num
    let fileUri = yearString + '/' + yearString + numString

    // const itemsDocs = 3;
    // const [showMore, setShowMore] = useState(1);
    // const [itemCount, setItemCount] = useState(0);
    // const [documents, setDocuments] = useState([] as any[]);
    // const [loadingButlletins, setLoadingButlletins] = useState(true);

    // const initFilter = {
    //     anyFilter: [moment(moment(props.title).format('YYYYMMDD')).format('YYYY-MM-DD')],
    //     butlletiFilter: props.counter,
    //     skip: 0,
    //     orderBy: 'DataPublicacioButlleti asc,OrganismeOrder,OrganismeChildOrder'
    // };

    // const getPaginatedDocuments = async (documentWebFilters: any) => {
    //     props.apiService.GetPaginatedDocuments(documentWebFilters)
    //     .then(async (result: any) => {
    //         let i = 0;
    //         let docsAux: any[] = [];
    //         setItemCount(result.totalCount);
    //         result.paginatedDocuments.map((doc: any) => {
    //             let tags = [];
    //             // Llógica de tags
    //             if(doc.document.organismePare != null)
    //             {
    //                 let numOrganisme = parseInt(doc.document.organismePare.split('.')[0])

    //                 if(numOrganisme != 18 && numOrganisme!= 17) {
    //                     if (doc.document.organismePare != null) tags.push(doc.document.organismePare.includes('.') ? doc.document.organismePare.split('.')[1].trim() : doc.document.organismePare)
    //                     if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
    //                     if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
    //                 } else {
    //                     if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
    //                     if (doc.document.temaPare != null) tags.push(doc.document.temaPare.includes('.') ? doc.document.temaPare.split('.')[1].trim() : doc.document.temaPare)
    //                     if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
    //                 }

    //                 tags = Array.from(new Set(tags));
    //             }

    //             docsAux.push({
    //                 number: doc.document.numButlleti,
    //                 date: moment(doc.document.dataArticle).format('DD/MM/YYYY'),
    //                 extra: doc.document.isExtra != null ? doc.document.isExtra : false,
    //                 summary: decodeURIComponent(doc.document.sumari).replaceAll('+',' '),
    //                 tags: tags,
    //                 docName: doc.document.nomDocument
    //             })
    //         i++;
    //     })

    //         if(i == result.paginatedDocuments.length)
    //         {
    //             setDocuments(docsAux);
    //             setTimeout(() => {
    //                 setLoadingButlletins(false);
    //             }, 500);
    //         }
    //     })
    //     .catch((error: any) => {
    //         console.log(error);
    //         setLoadingButlletins(false);
    //     })
    // }

    let title = moment(props.title).format('D') + ( moment(props.title).format('MMMM') == 'abril' || moment(props.title).format('MMMM') == 'agost' || moment(props.title).format('MMMM') == 'octubre' ? " d'" : " de ") + moment(props.title).format('MMMM') + ' del ' + moment(props.title).format('YYYY')

    return (
        <Link className={"accordionButlleti"} onAuxClick={handleLinkClick} onClick={handleLinkClick} to={'/Documents?num=' + num + '&yearItem=' + moment(props.title).format('YYYY_MM')}>
            <Accordion id={`accordionButlleti_${props.counter}_${moment(props.title).format('YYYY')}`} defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                <Accordion.Header>
                            {/* <div className={props.extra==true ? styles.counter_extra : styles.counter}>{props.counter}</div> */}
                            <div className={styles.counter_extra}>
                                <div className={styles.counter_extra_title}>BOPA</div>
                                {props.counter}
                            </div>
                            <div className={styles.accordion_content}>
                                {/* <div className={isLinkVisited ? styles.title_visited : styles.title}>{'Any ' + moment(props.title).format('YYYY') + (moment(props.title).format('D') == '1' || moment(props.title).format('D') == '11' ? " de l'" : ' del ') + moment(props.title).format('DD/MM/YYYY')}</div> */}
                                <div className={isLinkVisited ? styles.title_visited : styles.title}>{title}</div>
                                <div className={styles.buttonsContent}>
                                    {props.extra == true ?
                                    <div className={styles.flag_extra}>
                                        <FlagExtra/>
                                    </div>
                                    : null}
                                    <div>
                                    <CardSumari
                                        icon={PDF}
                                        label="Sumari"
                                        path={fileUri}
                                    />
                                    </div>
                                </div>
                            </div>
                </Accordion.Header>
                </Accordion.Item>
            </Accordion>
        </Link>
    )
    // return (
    //         <Accordion onClick={preventClick} className={"accordionButlleti"} id={`accordionButlleti_${props.counter}_${moment(props.title).format('YYYY')}`} defaultActiveKey="1">
    //             <Accordion.Item eventKey="0">
    //                 {/* <Accordion.Header onClick={() => {getPaginatedDocuments(initFilter)}}> */}
    //                 <Accordion.Header onClick={preventClick}>
    //                     <div className={props.extra==true ? styles.counter_extra : styles.counter}>{props.counter}</div>
    //                     <div className={styles.accordion_content}>
    //                         <div className={styles.title}>{'Any ' + moment(props.title).format('YYYY') + (moment(props.title).format('D') == '1' || moment(props.title).format('D') == '11' ? " de l'" : ' del ') + moment(props.title).format('DD/MM/YYYY')}</div>
    //                         <div className={styles.buttonsContent}>
    //                             <div className={styles.flag_extra}>
    //                                 {props.extra == true ? <FlagExtra/> : null}
    //                             </div>
    //                             <div>
    //                             {/* <CardSumari
    //                                 icon={PDF}
    //                                 label="Sumari"
    //                                 // path={fileUri}
    //                             /> */}
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </Accordion.Header>

    //                 <Accordion.Body>
    //                     {/* {loadingButlletins == true ? 
    //                         <div className={styles.container_main}>
    //                         <div className="app-spinner">
                                
    //                             <img width={50} src={loadingLogo} alt="loading..." />
    //                         </div>
    //                         </div>
    //                     : 
    //                     <div>
    //                     {
    //                         documents.length < itemsDocs*(showMore + 1) ?
    //                          documents.map((doc: any, index) => {
    //                              return(
    //                                  <div className={index == 0 ? '' : 'mt-3'}>
    //                                      <CardButlletinDoc
    //                                          resumen={doc.summary}
    //                                          tags={doc.tags}
    //                                          path={'/Butlletins/Detall?doc=' + doc.docName}
    //                                      />
    //                                  </div>
    //                              )
    //                          })
    //                          : 
    //                          documents.slice(0,itemsDocs*showMore).map((doc: any, index) => {
    //                              return(
    //                                  <div className={index == 0 ? '' : 'mt-3'}>
    //                                      <CardButlletinDoc
    //                                          resumen={doc.summary}
    //                                          tags={doc.tags}
    //                                          path={'/Butlletins/Detall?doc=' + doc.docName}
    //                                      />
    //                                  </div>
    //                              )
    //                          })
    //                     }
    //                      {
    //                          documents.length >= itemsDocs*(showMore + 1) ?
    //                              <div className={styles.content_btns}>
    //                                  <BtnLink
    //                                      onClick={()=>{setShowMore(showMore + 1)}}
    //                                      text='Carrega més'
    //                                  />
    //                                  <div className='mx-3'>
    //                                      <BtnSecondary
    //                                          text='Veure tot'
    //                                          onClick={()=> { window.open('/Documents?num=' + num + '&yearItem=' + moment(props.title).format('YYYY_MM'), '_self') }}
    //                                      />
    //                                  </div>
    //                              </div>
    //                          : showMore > 1 ?
    //                              <div className={styles.content_btns}>
    //                                  <div className='mx-3'>
    //                                      <BtnSecondary
    //                                          onClick={()=>{ setShowMore(1) }}
    //                                          text='Veure menys'
    //                                      />
    //                                  </div> 
    //                              </div>
    //                          : null
    //                     }
    //                      </div>
                        
    //                     } */}

    //                 </Accordion.Body>
    //             </Accordion.Item>
    //         </Accordion>
    // )
}

export default AccordionButlletin