import ButtonIconPrimaryProps from "./ButtonIconPrimaryProps"
import styles from './ButtonIconPrimary.module.sass'
import Button from 'react-bootstrap/Button'

function ButtonIconPrimary(props:ButtonIconPrimaryProps) {
    return (
            <Button className={styles.btn_icon} onClick={props.onClick}>
                {props.icon} 
            </Button>
    )
}

export default ButtonIconPrimary