import MsgIlustrationTextProps from './MsgIlustrationTextProps'
import styles from './MsgIlustrationText.module.sass'


function MsgIlustrationText(props:MsgIlustrationTextProps) {
    return (
            <div className={styles.container}>
                <div>
                    <img src={props.ilustration} className={styles.icon} />
                </div>
                <div className={styles.overline_regular}>
                    {props.text}
                </div>
            </div>
    )
}

export default MsgIlustrationText