import RegistreProps from "./RegistreProps"
import styles from './Registre.module.sass'
import '../../styles/global.sass'


function Registre() {
    return(
        <div className={styles.container}>
            <div className='margin_top'></div>
            <div className='container'>
                <h1>Registra't</h1>
            </div>
        </div>                
        








        
    )
}

export default Registre