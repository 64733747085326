import BadgeDocProps from './BadgeDocProps'
import styles from './BadgeDoc.module.sass'

function BadgeDoc(props:BadgeDocProps) {
    return (
            <div className={styles.container} id={props.id} onClick={() => {props.deleteItem(props.id)}}>
                {props.label}
            </div>       
    )
}

export default BadgeDoc