import IniciSessionProps from "./IniciSessionProps"
import styles from './IniciSession.module.sass'
import '../../styles/global.sass'


function IniciSession() {
    return(
        <div className={styles.container}>
            <div className='margin_top'></div>
            <div className='container'>
                <h1>Inici sessió</h1>
            </div>
        </div>                
        








        
    )
}

export default IniciSession