import DocumentsProps from "./DocumentsProps"
import styles from './Documents.module.sass'
import '../../styles/global.sass'
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary"
import BtnSecondary from "../../components/layout/buttons/secondary/BtnSecondary"
import Badge from "../../components/layout/badge/Badge"
import MsgIlustrationText from "../../components/layout/mensajes/msg_ilustration_text/MsgIlustrationText"
import SinResultats from './../../assets/ilustrations/sin_resultados.svg'
import CardDocument from "../../components/cards/card_document/CardDocument"
import { BsArrowLeft, BsArrowRight, BsArrowUpCircle } from "react-icons/bs"
import { BiFilterAlt } from "react-icons/bi"
import { useEffect, useState } from "react"
import loadingLogo from '../../assets/loading/loading.gif'
import { Accordion, Col, Form, Modal, Row } from "react-bootstrap"
import moment from "moment"
import BtnAsLink from "../../components/layout/buttons/BtnAsLink/BtnAsLink"
import FiltresDocuments from "../../components/forms/filtres_documents/FiltresDocuments"
import AlertWarning from "../../components/Alerts/AlertWarning/AlertWarning"
import { toast } from 'sonner'
import AccordionFiltres2Nivel from "../../components/accordion/accordion_filtres/accordion_filtres_2_nivel/AccordionFiltres2Nivel"
import "../../components/accordion/accordion_filtres/AccordionFiltres.sass"
import "./Documents.sass"
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from "react-router-dom"

function Documents(props: DocumentsProps) {

    var orderByDesc = "DataPublicacioButlleti desc, DataArticle desc,OrganismeOrder,OrganismeChildOrder"
    var orderByAsc = "DataPublicacioButlleti asc, DataArticle asc,OrganismeOrder,OrganismeChildOrder"
    var orderByRelevance = "search.score() desc, DataPublicacioButlleti desc, DataArticle desc,OrganismeOrder,OrganismeChildOrder"
    var orderByProtocol = "OrganismeOrder, OrganismeChildOrder, DataArticle desc"

    const queryParameters = new URLSearchParams(window.location.search)
    const search = queryParameters.get("search");
    const yearItems = queryParameters.get("yearItem");
    const num = queryParameters.get("num");
    const itemOrganismes = queryParameters.get("itemOrganismes");
    const itemTemes = queryParameters.get("itemTemes");
    const initDate = queryParameters.get("initDate");
    const endDate = queryParameters.get("endDate");
    const initSearchMode = queryParameters.get("searchMode") != null && queryParameters.get("searchMode") == "2" ? 2 : 1;
    var orderBy:string = orderByRelevance

    const itemsPerPage = 100;
    const initFilter = {
            textSearch: "",
            temaFilter: [] as any,
            dateFilter: [] as any,
            organismeFilter: [] as any,
            butlletiFilter: num != null ? num : "",
            anyFilter: yearItems != null ? [`${yearItems.split('_')[0]}-${yearItems.split('_')[1]}-01`] as any : [] as any,
            size: itemsPerPage,
            skip: 0,
            orderBy: orderBy,
            searchMode: initSearchMode
        };

    let navigate = useNavigate();
    const [hasMore, setHasMore] = useState(true)
    const [textValue, setTextValue] = useState(search);
    const [butlletiValue, setButlletiValue] = useState(num);
    const [initDateValue, setInitDateValue] = useState(initDate != null ? initDate :'');
    const [endDateValue, setEndDateValue] = useState(endDate != null ? endDate :'');
    const [searchFilters, setSearchFilters] = useState([] as any[]);
    const [pagination, setPagination] = useState(1);
    const [loadingFilters, setLoadingFilters] = useState(true);
    const [loadingButlletins, setLoadingButlletins] = useState(true);
    const [order, setOrder] = useState(0);
    const [documents, setDocuments] = useState([] as any[]);
    const [itemCount, setItemCount] = useState(0);
    const [documentWebFilters, setdocumentWebFilters] = useState(initFilter);
    const [modal, setModal] = useState(false);
    const [modalSave, setModalSave] = useState(false);
    const [userName, setName] = useState(null as any);
    const [activeKey, setActiveKey] = useState("");
    const [activeKeyTemes, setActiveKeyTemes] = useState("");
    const [activeKeyOrganismes, setActiveKeyOrganismes] = useState("");
    const [showTopBtn, setShowTopBtn] = useState(false);
    const [backUrl, setBackUrl] = useState(false);
    const [cookiesReady, setCookiesReady] = useState(false);
    const [searchMode, setSearchMode] = useState(initSearchMode);

    useEffect(() => {
        if(pagination > (Math.ceil(itemCount/itemsPerPage))) setPagination(1)
    }, [documents]);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const writeCookies = () => {
        sessionStorage.setItem('documentsFilters', JSON.stringify(documentWebFilters))
        sessionStorage.setItem('positon', window.pageYOffset.toString())
        sessionStorage.setItem('activeKey', activeKey != undefined ? activeKey : '')
        sessionStorage.setItem('activeKeyOrganismes', activeKeyOrganismes != undefined ? activeKeyOrganismes : '' )
        sessionStorage.setItem('activeKeyTemes', activeKeyTemes != undefined ? activeKeyTemes : '' )

        document.cookie = "documentsFilters=" + JSON.stringify(documentWebFilters).replace(/.$/, '') +  ', "positon":'  + window.pageYOffset 
        +  ', "activeKey":"'  + activeKey +  '", "activeKeyOrganismes":"'  + activeKeyOrganismes +  '", "activeKeyTemes":"'  + activeKeyTemes + '"}';
    }

    useEffect(() => {
        var butlletiFilter = sessionStorage.getItem('butlletiFilters')

        if(butlletiFilter != null && butlletiFilter != '') 
        {
            setBackUrl(true);          
        }

        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });

        const cookieValue = document.cookie.split(';').find(cookie => cookie.trim().startsWith('clientToken='))?.split('=')[1] || '';
        if(cookieValue != null && cookieValue != "")
        {
            setName("");
        }
        else
        {
            setName(null)
        }

    }, []);

    const onInputChange = (event: any) => {
        if(event.code == "Enter"){
            event.preventDefault();
            onFormSubmit(event);
          }
    }

    const handleOnChangeTextSearchType = (event: any) => {
        let docsAux = documentWebFilters
        docsAux.searchMode = event.target.value;
        setdocumentWebFilters(docsAux)
        setSearchMode(event.target.value);
    }

    const handleSelect = (eventKey: any) => {
        setActiveKey(eventKey);
    }

    const handleSelectKeyOrganismes = (eventKey: any) => {
        setActiveKeyOrganismes(eventKey);
    }

    const handleSelectKeyTemes = (eventKey: any) => {
        setActiveKeyTemes(eventKey);
    }
    
    const saveFavoriteModal = (event: any) => {
        event.preventDefault();
        if(userName != null) setModalSave(true)
        else setModal(true);
    }

    const fetchData = () => {
        documentWebFilters.skip = documentWebFilters.skip + itemsPerPage;
        props.apiService.GetPaginatedDocuments(documentWebFilters)
        .then(async (result) => {
            if(result.totalCount < documentWebFilters.skip)
            {
                setLoadingButlletins(false);
                setHasMore(false)
            }
            else {
                let i = 0;
                let docsAux: any[] = [];
                setItemCount(result.totalCount);
                result.paginatedDocuments.map((doc: any) => {
                    let tags = [];
                    // Llógica de tags
                    if(doc.document.organismePare != null)
                    {
                        let numOrganisme = parseInt(doc.document.organismePare.split('.')[0])
                        if(numOrganisme != 18 && numOrganisme!= 17) {
                            if (doc.document.organismePare != null) tags.push(doc.document.organismePare.includes('.') ? doc.document.organismePare.split('.')[1].trim() : doc.document.organismePare)
                            if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
                            if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
                        } else {
                            if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
                            if (doc.document.temaPare != null) tags.push(doc.document.temaPare.includes('.') ? doc.document.temaPare.split('.')[1].trim() : doc.document.temaPare)
                            if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
                        }
                        tags = Array.from(new Set(tags));
                    }
                    
                    let summary = '-'
                    if(doc.document.sumari != null)
                    {
                        summary = decodeURIComponent(doc.document.sumari)
                        summary = summary.replaceAll('+', ' ')
                    }

                    let dataPublicacio = moment(doc.document.dataPublicacioButlleti).format('DD/MM/YYYY')
                    // Revisar si el BOPA es anterior al 08/06/2023
                    if(moment(doc.document.dataPublicacioButlleti).diff('2023-06-08T00:00:00.000Z', 'minutes') < 0)
                    {
                        dataPublicacio = moment(doc.document.dataPublicacioButlleti).add(1,'day').format('DD/MM/YYYY')
                    }                  

                    if(!documents.some((docAux) => docAux.docName == doc.document.nomDocument)) {
                        docsAux.push({
                            number: parseInt(doc.document.numButlleti),
                            date: dataPublicacio,
                            extra: doc.document.isExtra != null ? doc.document.isExtra : false,
                            summary: summary,
                            tags: tags,
                            docName: doc.document.nomDocument != null ? doc.document.nomDocument : '',
                            highlights: doc.highlights
                        })
                    }
                    
                    i++;
                })

                if(i == result.paginatedDocuments.length)
                {
                    setDocuments(oldArray => [...oldArray, ...docsAux]);
                    setTimeout(() => {
                        setLoadingButlletins(false);
                    }, 1000);
                } 
            }
            
        })
        .catch((error) => {
            console.log(error);
            setLoadingButlletins(false);
        })
    }

    const saveFavorite = async () => {
        var serialize = require('form-serialize');
        var form = document.querySelector('#saveForm');
        var obj1 = serialize(form);
        if(obj1.includes('title'))
        {
            let variables1 = obj1.split('&');
            let title = "";
            let description = "";
            let active = false;
            variables1.map((item: any)=> {
                let itemName = item.split('=')[0]
                let itemValue = item.split('=')[1]
                if(itemName == 'title') title = itemValue
                else if(itemName == 'description') description = itemValue
                else if(itemName == 'alerts' && itemValue == 'on') active = true
            })

            let url = '';
            var form2 = document.querySelector('#filterForm');
            var obj2 = serialize(form2);
            let variables2 = obj2.split('&');
            variables2.map((item: any) => {
                let itemName = item.split('=')[0]
                let itemValue = item.split('=')[1]
                if(itemName == 'initDate' || itemName == 'endDate')
                {
                    url += '&' + itemName + '=' + itemValue
                }
                else if(itemName == 'butlletiNumber')
                {
                    url += '&num=' + itemValue
                }
                else if(itemName == 'textSearch')
                {
                    url += '&search=' + (textValue || '')
                }
                else
                {
                    if(itemValue == 'on')
                    {
                        let itemName2 = itemName.split('_')[0]
                        if(itemName2 == 'Organismes' || itemName2 == 'Temes')
                        {
                            let itemUriName = '&item' + itemName2 + '='
                            if(url.includes(itemUriName))
                            {
                                url = url.split(itemUriName)[0] + itemUriName + itemName.split('_')[1] + ',' + url.split(itemUriName)[1]
                            }
                            else
                            {
                                url += itemUriName + itemName.split('_')[1] 
                            } 
                        }
                        else if(itemName2 == 'Any')
                        {
                            if(url.includes('&yearItem='))
                            {
                                url = url.split('&yearItem=')[0] + '&yearItem=' + itemName.split('_')[1] + '_' + itemName.split('_')[2] + ',' + url.split('&yearItem=')[1]
                            }
                            else
                            {
                                url += '&yearItem=' + itemName.split('_')[1] + '_' + itemName.split('_')[2]
                            }
                        }
                    }
                }
            })
            url = '/Documents?' + url.slice(1);

            let search = {
                titol: title.replaceAll("'","''"),
                descripcio: description.replaceAll("'","''"),
                activa: active,
                consulta: url.replaceAll("'","''")
            }
            
            await props.apiService.SaveSearch(search)
            .then(() => {
                setModalSave(false)
                toast.success('Cerca desada correctament')
            })
            .catch(() => {
                setModalSave(false)
                toast.error('Error al desar la cerca.')
            })
        }      
    }

    const changeTextValue = (event: any) => {
        setTextValue(event.target.value)
    }

    const changeButlletiValue = (event: any) => {
        setButlletiValue(event.target.value)
    }

    const changeInitDateValue = (event: any) => {
        if(event != null) setInitDateValue(moment(event).format('DD/MM/YYYY'))
        else setInitDateValue("")
        
        setTimeout(() => {
            onFormSubmit(event)
        }, 500);     
    }

    const changeEndDateValue = (event: any) => {
        if(event != null) setEndDateValue(moment(event).format('DD/MM/YYYY'))
        else setEndDateValue("")

        setTimeout(() => {
            onFormSubmit(event)
        }, 500);     
    }

    const deleteItem = (event: any) => {
        setLoadingButlletins(true)
        if(event.split('_')[0] == 'textSearch')
        {
            setTextValue('');
            documentWebFilters.textSearch = ''
        }
        else if(event.split('_')[0] == 'butlleti')
        {
            setButlletiValue('');
            documentWebFilters.butlletiFilter = '';
        }
        else if(event.split('_')[0] == 'date')
        {
            if(event.split('_')[1] == 'ge')
            {
                setInitDateValue('');
                documentWebFilters.dateFilter = documentWebFilters.dateFilter.filter((obj: any) => {
                    return(obj.filterType != 'ge')
                });
            }
            else if(event.split('_')[1] == 'le')
            {
                setEndDateValue('');
                documentWebFilters.dateFilter = documentWebFilters.dateFilter.filter((obj: any) => {
                    return(obj.filterType != 'le')
                });
            }
        }
        else 
        {
            let elements = document.getElementsByName(decodeURI(event));
            elements.forEach((obj: any) => {
                obj.checked = false;
            })

            if(event.split('_')[0] == 'Organismes')
            {
                documentWebFilters.organismeFilter = documentWebFilters.organismeFilter.filter((obj: any) => {
                    return(obj.filterValue != event.split('_')[1])
                });
            }
            else if(event.split('_')[0] == 'Temes')
            {
                documentWebFilters.temaFilter = documentWebFilters.temaFilter.filter((obj: any) => {
                    return(obj.filterValue != event.split('_')[1])
                });
            }
            else if(event.split('_')[0] == 'Any')
            {
                documentWebFilters.anyFilter = documentWebFilters.anyFilter.filter((obj: any) => {
                    return(obj != `${event.split('_')[1]}-${event.split('_')[2]}-01`)
                });
            }             
        }
        setdocumentWebFilters(documentWebFilters);
        getPaginatedDocuments(documentWebFilters);
    }
    
    const clearFilters = (event: any) => {
        event.target.reset();
        sessionStorage.setItem('documentsFilters', JSON.stringify(initFilter))
        sessionStorage.setItem('positon', '0')
        sessionStorage.setItem('activeKey', '')
        sessionStorage.setItem('activeKeyOrganismes', '')
        sessionStorage.setItem('activeKeyTemes', '')
        // document.cookie = "documentsFilters=" + JSON.stringify(initFilter).replace(/.$/, '') +  ', "positon":0' + '"}';
        setActiveKey("");
        setActiveKeyTemes("");
        setActiveKeyOrganismes("");
        setTextValue("");
        setButlletiValue("");
        setInitDateValue("");
        setEndDateValue("");
        setSearchMode(1);
        setHasMore(true)
        setdocumentWebFilters(initFilter);
        if(num != null && num != "" && yearItems != null && yearItems != "") {
            props.apiService.GetDocumentsByBOPA(yearItems.split('_')[0], num)
            .then(async (result) => {
                setHasMore(false)
                let i = 0;
                let docsAux: any[] = [];
                setItemCount(result.totalCount);
                result.paginatedDocuments.map((doc: any) => {
                    let tags = [];
                    // Llógica de tags
                    if(doc.document.organismePare != null)
                    {
                        let numOrganisme = parseInt(doc.document.organismePare.split('.')[0])
                        if(numOrganisme != 18 && numOrganisme!= 17) {
                            if (doc.document.organismePare != null) tags.push(doc.document.organismePare.includes('.') ? doc.document.organismePare.split('.')[1].trim() : doc.document.organismePare)
                            if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
                            if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
                        } else {
                            if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
                            if (doc.document.temaPare != null) tags.push(doc.document.temaPare.includes('.') ? doc.document.temaPare.split('.')[1].trim() : doc.document.temaPare)
                            if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
                        }
                        tags = Array.from(new Set(tags));
                    }
                    
                    let summary = '-'
                    if(doc.document.sumari != null)
                    {
                        summary = decodeURIComponent(doc.document.sumari)
                        summary = summary.replaceAll('+', ' ')
                    }
    
                    let dataPublicacio = moment(doc.document.dataPublicacioButlleti).format('DD/MM/YYYY')
                    // Revisar si el BOPA es anterior al 08/06/2023
                    if(moment(doc.document.dataPublicacioButlleti).diff('2023-06-08T00:00:00.000Z', 'minutes') < 0)
                    {
                        dataPublicacio = moment(doc.document.dataPublicacioButlleti).add(1,'day').format('DD/MM/YYYY')
                    }      
                    
                    docsAux.push({
                        number: parseInt(doc.document.numButlleti),
                        date: dataPublicacio,
                        extra: doc.document.isExtra != null ? doc.document.isExtra : false,
                        summary: summary,
                        tags: tags,
                        docName: doc.document.nomDocument != null ? doc.document.nomDocument : '',
                        highlights: doc.highlights
                    })
                    i++;
                })
    
                if(i == result.paginatedDocuments.length)
                {
                    setDocuments(docsAux);
                    setTimeout(() => {
                        setLoadingButlletins(false);
                    }, 500);
                }  
            })
            .catch((error) => {
                console.log(error);
                setLoadingButlletins(false);
            })
        }else {
            getPaginatedDocuments(initFilter);
        }
    }

    const changeOrder = (event: any) => {
        setLoadingButlletins(true);
        setOrder(event.target.value);
        let documentWebFiltersAux = documentWebFilters;
        if(event.target.value == 0) documentWebFiltersAux.orderBy = orderByRelevance
        if(event.target.value == 1) documentWebFiltersAux.orderBy = orderByDesc
        if(event.target.value == 2) documentWebFiltersAux.orderBy = orderByAsc
        orderBy = documentWebFiltersAux.orderBy;
        setdocumentWebFilters(documentWebFiltersAux);
        getPaginatedDocuments(documentWebFilters, event.target.value);
    }

    const handleSubmit  = (event: any) => {
        event.preventDefault();
        if(event.nativeEvent.submitter.name  == "cleaner")
        {
            clearFilters(event);
        } else if (event.nativeEvent.submitter.name  == "submitter") {
            onFormSubmit(event);
        }
        else if (event.nativeEvent.submitter.name  == "saver") {
            saveFavoriteModal(event);
        }
    }

    const getPaginatedDocuments = async (documentWebFiltersParameter: any, orderAux?: any) => {
        let documentWebFiltersParameterAux = JSON.parse(JSON.stringify(documentWebFiltersParameter));
        if(documentWebFiltersParameterAux.skip > documents.length) 
        {
            documentWebFiltersParameterAux.size = documentWebFiltersParameterAux.skip
            documentWebFiltersParameterAux.skip = 0           
        }

        if(num != null && num != "" && yearItems != null && yearItems != "" && 
        documentWebFilters.anyFilter.length == 0 && documentWebFilters.temaFilter.length == 0 && documentWebFilters.textSearch == "" && 
        documentWebFilters.dateFilter.length == 0 && documentWebFilters.organismeFilter.length == 0 && documentWebFilters.butlletiFilter == "")
        {
            documentWebFiltersParameterAux.orderBy = orderByProtocol;
        }
        else if(orderAux == null) {
            if(order == 0) documentWebFiltersParameterAux.orderBy = orderByRelevance
            if(order == 1) documentWebFiltersParameterAux.orderBy = orderByDesc
            if(order == 2) documentWebFiltersParameterAux.orderBy = orderByAsc
        }

        props.apiService.GetPaginatedDocuments(documentWebFiltersParameterAux)
        .then(async (result) => {
            let i = 0;
            let docsAux: any[] = [];
            setItemCount(result.totalCount);
            result.paginatedDocuments.map((doc: any) => {
                let tags = [];
                // Llógica de tags
                if(doc.document.organismePare != null)
                {
                    let numOrganisme = parseInt(doc.document.organismePare.split('.')[0])
                    if(numOrganisme != 18 && numOrganisme!= 17) {
                        if (doc.document.organismePare != null) tags.push(doc.document.organismePare.includes('.') ? doc.document.organismePare.split('.')[1].trim() : doc.document.organismePare)
                        if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
                        if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
                    } else {
                        if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
                        if (doc.document.temaPare != null) tags.push(doc.document.temaPare.includes('.') ? doc.document.temaPare.split('.')[1].trim() : doc.document.temaPare)
                        if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
                    }
                    tags = Array.from(new Set(tags));
                }
                
                let summary = '-'
                if(doc.document.sumari != null)
                {
                    summary = decodeURIComponent(doc.document.sumari)
                    summary = summary.replaceAll('+', ' ')
                }

                let dataPublicacio = moment(doc.document.dataPublicacioButlleti).format('DD/MM/YYYY')
                // Revisar si el BOPA es anterior al 08/06/2023
                if(moment(doc.document.dataPublicacioButlleti).diff('2023-06-08T00:00:00.000Z', 'minutes') < 0)
                {
                    dataPublicacio = moment(doc.document.dataPublicacioButlleti).add(1,'day').format('DD/MM/YYYY')
                }      

                docsAux.push({
                    number: parseInt(doc.document.numButlleti),
                    date: dataPublicacio,
                    extra: doc.document.isExtra != null ? doc.document.isExtra : false,
                    summary: summary,
                    tags: tags,
                    docName: doc.document.nomDocument != null ? doc.document.nomDocument : '',
                    highlights: doc.highlights
                })
                
                i++;
            })

            if(i == result.paginatedDocuments.length)
            {
                setDocuments(docsAux);
                setTimeout(() => {
                    setLoadingButlletins(false);
                }, 500);
            }
        })
        .catch((error) => {
            console.log(error);
            setLoadingButlletins(false);
        })
    }

    const isURIEncoded = (str: any) => {
        try {
          decodeURIComponent(str);
          return true;
        } catch (error) {
          return false;
        }
      };

    const onFormSubmit = (event: any) => {
        // event.preventDefault();
        setHasMore(true)
        setLoadingButlletins(true)
        var serialize = require('form-serialize');
        var form = document.querySelector('#filterForm');
        var obj = serialize(form);
        let variables = obj.split('&');
        let documentWebFiltersAux = initFilter;

        variables.map((item: any) => {
            let key = item.split('=')[0];
            let value = item.split('=')[1];
            if(key == 'textSearch') documentWebFiltersAux.textSearch = textValue || ''
            else if(key == 'initDate') {
                value = moment(value.replaceAll('%2F', '/'), "DD/MM/YYYY").format('YYYY-MM-DD')
                let datevalue = documentWebFiltersAux.dateFilter.find((obj: any) => {
                    return obj.filterType === 'ge';
                  });
                  if(datevalue == undefined) documentWebFiltersAux.dateFilter.push({filterType: 'ge', filterValue: value})
                  else if (moment(value).isBefore(datevalue.filterValue)) value.filterValue = value;
            }
            else if(key == 'endDate') {
                value = moment(value.replaceAll('%2F', '/'), "DD/MM/YYYY").format('YYYY-MM-DD')
                let datevalue = documentWebFiltersAux.dateFilter.find((obj: any) => {
                    return obj.filterType === 'le';
                  });
                if(datevalue == undefined) documentWebFiltersAux.dateFilter.push({filterType: 'le', filterValue: value})
                else if (moment(value).isAfter(datevalue.filterValue)) value.filterValue = value;
            }
            // else if(key == 'butlletiNumber') documentWebFiltersAux.butlletiFilter = value;
            else if(value == 'on' && key.split('_')[0] == "Organismes") {
                documentWebFiltersAux.organismeFilter.push({
                    filterType: 'eq', 
                    filterValue: (key.split('_')[1]),
                    filterName: document.getElementsByName(key)[0].id
                })
            }
            else if(value == 'on' && key.split('_')[0] =="Temes") {
                documentWebFiltersAux.temaFilter.push({
                    filterType: 'eq', 
                    filterValue: (key.split('_')[1]),
                    filterName: document.getElementsByName(key)[0].id
                })
            } 
            // else if(value == 'on' && key.split('_')[0] =="Any") {
            //     documentWebFiltersAux.anyFilter.push(`${key.split('_')[1]}-${key.split('_')[2]}-01`)
            // }
        })
        documentWebFiltersAux.butlletiFilter = documentWebFilters.butlletiFilter;
        documentWebFiltersAux.anyFilter = documentWebFilters.anyFilter;
        documentWebFiltersAux.searchMode = searchMode;
        setdocumentWebFilters(documentWebFiltersAux);
        getPaginatedDocuments(documentWebFiltersAux);
    }

    useEffect(()=> {
        if(cookiesReady == true)
        {
            props.apiService.GetFilters()
            .then((result) => {
                let auxFilters: any[] = [];
                let auxOrganismes: any[] = [];
                let auxTemes: any[] = [];

                result.organismes.forEach((organisme: any) => {
                    auxOrganismes.push({
                        secondarytitle: organisme.organismePare,
                        labels: organisme.organismes
                    })
                    
                    if(auxOrganismes.length == result.organismes.length)
                    {
                        auxFilters.push({
                            title: "Organismes",
                            secondLevel: auxOrganismes
                        })
                    }
                });            

                result.temes.forEach((tema: any) => {
                    auxTemes.push({
                        secondarytitle: tema.temaPare,
                        labels: tema.temes
                    })

                    if(auxTemes.length == result.temes.length)
                    {
                        auxFilters.push({
                            title: "Temes",
                            secondLevel: auxTemes.sort(function(a, b) {
                                if(a.secondarytitle.toLowerCase() < b.secondarytitle.toLowerCase()) return -1;
                                if(a.secondarytitle.toLowerCase() > b.secondarytitle.toLowerCase()) return 1;
                                return 0;
                            })
                        })
                    }
                });            

                setSearchFilters(auxFilters);
                setLoadingFilters(false);

                setTimeout(()=> {
                    
                    if(search != null && search != "") documentWebFilters.textSearch = search.replaceAll(' ', '+')
                    if(num != null && num != "") documentWebFilters.butlletiFilter = num;

                    if(num != null && num != "" && yearItems != null && yearItems != "") {
                        setLoadingFilters(true);
                        props.apiService.GetFiltersByBOPA(yearItems.split('_')[0], num)
                        .then((result) => {
                            setHasMore(false)
                            let auxFilters: any[] = [];
                            let auxOrganismes: any[] = [];
                            let auxTemes: any[] = [];

                            result.organismes.forEach((organisme: any) => {
                                auxOrganismes.push({
                                    secondarytitle: organisme.organismePare,
                                    labels: organisme.organismes
                                })
                                if(auxOrganismes.length == result.organismes.length)
                                {
                                    auxFilters.push({
                                        title: "Organismes",
                                        secondLevel: auxOrganismes
                                    })
                                }
                            });            

                            result.temes.forEach((tema: any) => {
                                auxTemes.push({
                                    secondarytitle: tema.temaPare,
                                    labels: tema.temes
                                })

                                if(auxTemes.length == result.temes.length)
                                {
                                    auxFilters.push({
                                        title: "Temes",
                                        secondLevel: auxTemes.sort(function(a, b) {
                                            if(a.secondarytitle.toLowerCase() < b.secondarytitle.toLowerCase()) return -1;
                                            if(a.secondarytitle.toLowerCase() > b.secondarytitle.toLowerCase()) return 1;
                                            return 0;
                                        })
                                    })
                                }
                            });      

                            // if(backUrl == false) setSearchFilters(auxFilters);
                            setSearchFilters(auxFilters);

                            setTimeout(() => {
                                if(itemOrganismes != null && itemOrganismes != "")
                                {
                                    let items = itemOrganismes.split(',');
                                    items.map((item: any) => {
                                        let elements = document.getElementsByName('Organismes_' + item);
                                            elements.forEach((obj: any) => {
                                                obj.checked = true;
                                            })
                                        documentWebFilters.organismeFilter.push({
                                            filterType: 'eq', 
                                            filterValue: item
                                        })
                                    })
                                }
    
                                if(itemTemes != null && itemTemes != "")
                                {
                                    let items = itemTemes.split(',');
                                    items.map((item: any) => {
                                        let elements = document.getElementsByName('Temes_' + item);
                                            elements.forEach((obj: any) => {
                                                obj.checked = true;
                                            })
    
                                        documentWebFilters.temaFilter.push({
                                            filterType: 'eq', 
                                            filterValue: item
                                        })
                                    })
                                }
    
                                if(documentWebFilters.organismeFilter.length > 0)
                                {
                                    documentWebFilters.organismeFilter.map((item: any) => {
                                        let elements = document.getElementsByName('Organismes_' + item.filterValue);
                                            elements.forEach((obj: any) => {
                                                obj.checked = true;
                                            })
                                    })
                                }
                                if(documentWebFilters.temaFilter.length > 0)
                                {
                                    documentWebFilters.temaFilter.map((item: any) => {
                                        let elements = document.getElementsByName('Temes_' + item.filterValue);
                                            elements.forEach((obj: any) => {
                                                obj.checked = true;
                                            })
                                    })
                                }
                            }, 200)

                            setLoadingFilters(false);
                        })

                        orderBy = orderByProtocol;
                        documentWebFilters.orderBy = orderByProtocol;
                    }
                    else {
                        if(itemOrganismes != null && itemOrganismes != "")
                        {
                            let items = itemOrganismes.split(',');
                            items.map((item: any) => {
                                let elements = document.getElementsByName('Organismes_' + item);
                                    elements.forEach((obj: any) => {
                                        obj.checked = true;
                                    })
                                documentWebFilters.organismeFilter.push({
                                    filterType: 'eq', 
                                    filterValue: item
                                })
                            })
                        }

                        if(itemTemes != null && itemTemes != "")
                        {
                            let items = itemTemes.split(',');
                            items.map((item: any) => {
                                let elements = document.getElementsByName('Temes_' + item);
                                    elements.forEach((obj: any) => {
                                        obj.checked = true;
                                    })

                                documentWebFilters.temaFilter.push({
                                    filterType: 'eq', 
                                    filterValue: item
                                })
                            })
                        }

                        if(documentWebFilters.organismeFilter.length > 0)
                        {
                            documentWebFilters.organismeFilter.map((item: any) => {
                                let elements = document.getElementsByName('Organismes_' + item.filterValue);
                                    elements.forEach((obj: any) => {
                                        obj.checked = true;
                                    })
                            })
                        }
                        if(documentWebFilters.temaFilter.length > 0)
                        {
                            documentWebFilters.temaFilter.map((item: any) => {
                                let elements = document.getElementsByName('Temes_' + item.filterValue);
                                    elements.forEach((obj: any) => {
                                        obj.checked = true;
                                    })
                            })
                        }
                    }

                    
                    if(initDate != null && initDate != "") documentWebFilters.dateFilter.push({filterType: 'ge', filterValue: initDate})
                    if(endDate != null && endDate != "") documentWebFilters.dateFilter.push({filterType: 'le', filterValue: endDate})

                    if(num != null && num != "" && yearItems != null && yearItems != "") {
                        props.apiService.GetDocumentsByBOPA(yearItems.split('_')[0], num)
                        .then(async (result) => {
                            let i = 0;
                            let docsAux: any[] = [];
                            setItemCount(result.totalCount);
                            result.paginatedDocuments.map((doc: any) => {
                                let tags = [];
                                // Llógica de tags
                                if(doc.document.organismePare != null)
                                {
                                    let numOrganisme = parseInt(doc.document.organismePare.split('.')[0])
                                    if(numOrganisme != 18 && numOrganisme!= 17) {
                                        if (doc.document.organismePare != null) tags.push(doc.document.organismePare.includes('.') ? doc.document.organismePare.split('.')[1].trim() : doc.document.organismePare)
                                        if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
                                        if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
                                    } else {
                                        if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
                                        if (doc.document.temaPare != null) tags.push(doc.document.temaPare.includes('.') ? doc.document.temaPare.split('.')[1].trim() : doc.document.temaPare)
                                        if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
                                    }
                                    tags = Array.from(new Set(tags));
                                }
                                
                                let summary = '-'
                                if(doc.document.sumari != null)
                                {
                                    summary = decodeURIComponent(doc.document.sumari)
                                    summary = summary.replaceAll('+', ' ')
                                }
                
                                let dataPublicacio = moment(doc.document.dataPublicacioButlleti).format('DD/MM/YYYY')
                                // Revisar si el BOPA es anterior al 08/06/2023
                                if(moment(doc.document.dataPublicacioButlleti).diff('2023-06-08T00:00:00.000Z', 'minutes') < 0)
                                {
                                    dataPublicacio = moment(doc.document.dataPublicacioButlleti).add(1,'day').format('DD/MM/YYYY')
                                }      
                
                                docsAux.push({
                                    number: parseInt(doc.document.numButlleti),
                                    date: dataPublicacio,
                                    extra: doc.document.isExtra != null ? doc.document.isExtra : false,
                                    summary: summary,
                                    tags: tags,
                                    docName: doc.document.nomDocument != null ? doc.document.nomDocument : '',
                                    highlights: doc.highlights
                                })
                                i++;
                            })
                
                            if(i == result.paginatedDocuments.length)
                            {
                                setDocuments(docsAux);
                                setTimeout(() => {
                                    setLoadingButlletins(false);
                                }, 500);
                            }  
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoadingButlletins(false);
                        })
                    }
                    else {
                        getPaginatedDocuments(documentWebFilters);
                    }
                }, 200)
            })
            .catch((error) => {
                console.log(error);
            })

        }
    }, [cookiesReady])

    useEffect(() => {
        let scrollPosition = 0;
        const cookieValue = sessionStorage.getItem('documentsFilters') || '';
        const cookieScroll = sessionStorage.getItem('positon') || '0';
        const cookieActiveKey = sessionStorage.getItem('activeKey') || '';
        const cookieActiveKeyOrganismes = sessionStorage.getItem('activeKeyOrganismes') || '';
        const cookieActiveKeyTemes = sessionStorage.getItem('activeKeyTemes') || '';

        // const cookieValue = document.cookie.split(';').find(cookie => cookie.trim().startsWith('documentsFilters='))?.split('=')[1] || '';
            if(cookieValue != '') {
                sessionStorage.setItem('documentsFilters', '')
                sessionStorage.setItem('positon', '')
                sessionStorage.setItem('activeKey', '')
                sessionStorage.setItem('activeKeyOrganismes', '')
                sessionStorage.setItem('activeKeyTemes', '')

                document.cookie = `documentsFilters=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                try {
                    let documentWebFiltersAux = JSON.parse(JSON.stringify(documentWebFilters));
                    let cookieData = JSON.parse(cookieValue)
                    if(!isURIEncoded(cookieData.textSearch)) documentWebFiltersAux.textSearch = cookieData.textSearch
                    else documentWebFiltersAux.textSearch = decodeURIComponent(cookieData.textSearch).replaceAll('+',' ') 
                    documentWebFiltersAux.temaFilter = cookieData.temaFilter
                    documentWebFiltersAux.dateFilter = cookieData.dateFilter
                    documentWebFiltersAux.organismeFilter = cookieData.organismeFilter
                    documentWebFiltersAux.butlletiFilter = cookieData.butlletiFilter
                    documentWebFiltersAux.anyFilter = cookieData.anyFilter
                    documentWebFiltersAux.size = cookieData.size
                    documentWebFiltersAux.skip = cookieData.skip
                    documentWebFiltersAux.orderBy = cookieData.orderBy
                    scrollPosition = parseInt(cookieScroll)
                    if(cookieData.orderBy == orderByRelevance) setOrder(0)
                    if(cookieData.orderBy == orderByDesc) setOrder(1)
                    if(cookieData.orderBy == orderByAsc) setOrder(2)

                    setdocumentWebFilters(documentWebFiltersAux)
                    setTimeout(() => setdocumentWebFilters(documentWebFiltersAux), 500)
                    setTextValue( isURIEncoded(cookieData.textSearch) ? decodeURIComponent(cookieData.textSearch).replaceAll('+',' ') : (cookieData.textSearch).replaceAll('+',' '))
                    cookieData.dateFilter.map((anyItem: any) => {
                        if(anyItem.filterType == 'le')
                        {
                            setEndDateValue(moment(anyItem.filterValue).format('DD/MM/YYYY'))
                        }
                        else if(anyItem.filterType == 'ge')
                        {
                            setInitDateValue(moment(anyItem.filterValue).format('DD/MM/YYYY'))
                        }
                    })
                    setActiveKey(cookieActiveKey)
                    setActiveKeyOrganismes(cookieActiveKeyOrganismes)         
                    setActiveKeyTemes(cookieActiveKeyTemes)
                    setCookiesReady(true)

                    setTimeout(()=> {
                        window.scrollTo({
                            top: scrollPosition,
                            behavior: 'smooth',
                        });
                    }, 1500)
                }
                catch (error: any)
                {
                    setCookiesReady(true)
                    console.log('Error cookies')
                    console.log(error)
                }
                
            }
            else
            {
                setCookiesReady(true)
            }
      }, []);
    
    return (
        <div className={styles.container}>
            <div className="top-to-btm">
                {" "}
                {showTopBtn && (
                    <BsArrowUpCircle
                        className="icon-position icon-style"
                        onClick={goToTop}
                    />
                )}{" "}
            </div>
            <div className='margin_top'></div>
            <div className='container col-12'>
                <h1>Documents</h1>
                <div className="row">
                    <div className='col-xl-4'>
                        <div className={styles.container_filtres}>

                        <div className={styles.container_btn_filtres}>
                                <BtnPrimary
                                text= {'Filtres'}
                                iconLeft= {<BiFilterAlt/>}
                                />
                            </div>

                            <Form id={"filterForm"} onSubmit={handleSubmit} role="form">
                            <div className={styles.content_filtres_header}>
                                <div className={styles.p2}>
                                    {
                                        backUrl == true ?
                                        <BtnSecondary
                                            disabled={ window.history.length > 1 ? false :  true}
                                            onClick={() => navigate(-1)}
                                            // onClick={()=>{window.open('/Butlletins','_self')}}
                                            text='Tornar'
                                            iconLeft={<BsArrowLeft />}
                                        />
                                        :
                                        <div className={styles.p2}>Filtres</div>                                      
                                    }
                                </div>
                                <div className={styles.content_filtres_header_btn}>
                                    <BtnAsLink
                                        name="cleaner"
                                        text='Netejar'
                                        type='submit'
                                    />
                                    <div className='mx-3'>
                                        <BtnSecondary
                                            name="saver"
                                            type='submit'
                                            text='Desa'
                                        />
                                    </div>
                                    <BtnPrimary
                                        name="submitter"
                                        type="submit"
                                        text='Cerca '
                                        iconRight={<BsArrowRight/>}
                                    />
                                </div>
                            </div>
                                
                                <Modal show={modalSave} onHide={() => {setModalSave(false)}}>
                                    <Form id={"saveForm"}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Desar cerca</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                                <Form.Label>Títol <span style={{color: 'red'}}>*</span></Form.Label>
                                                <Form.Control 
                                                    name="title"
                                                    className={styles.form_control} 
                                                    type="text" 
                                                    placeholder="Insereix"
                                                    required /> 
                                                <p></p>
                                                <Form.Label>Descripció</Form.Label>
                                                <Form.Control 
                                                    name="description"
                                                    className={styles.form_control} 
                                                    as="textarea" 
                                                    rows={3}
                                                    placeholder="Insereix" /> 
                                                <p></p>
                                                <Form.Check 
                                                    label={"Vols rebre alertes d'aquesta cerca?"}
                                                    name="alerts"
                                                />                                            
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <BtnPrimary text="Desar cerca " type="submit" iconRight={<BsArrowRight />} onClick={saveFavorite} />
                                        </Modal.Footer>
                                    </Form>
                                </Modal>

                                <Modal show={modal} onHide={() => {setModal(false)}}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Desar cerca</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AlertWarning message="Has d'accedir a la plataforma per poder desar la cerca als teus favorits." />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <BtnSecondary text="Sortir " iconRight={<BsArrowRight />} onClick={() => {setModal(false)}} />
                                    </Modal.Footer>
                                </Modal>
                            
                                <div className={styles.content_filtres_form}>
                                <FiltresDocuments
                                        handleOnChangeTextSearchType={handleOnChangeTextSearchType}
                                        searchMode={searchMode}
                                        onEnter={onInputChange}
                                        text={textValue} changeText={changeTextValue}
                                        buttleti={butlletiValue} changeButlleti={changeButlletiValue}
                                        initDate={initDateValue} changeInitDate={changeInitDateValue}
                                        endDate={endDateValue} changeEndDate={changeEndDateValue} />
                                </div>

                                <div id="accordionFilters">
                                    <Accordion id="filtresAccordion" activeKey={activeKey} onSelect={handleSelect} flush /* onClickCapture={(event: any)=>{if(event.target.textContent != activeKey) {setActiveKey(event.target.textContent)} else {setActiveKey("0")}}} activeKey={activeKey} */ className={styles.accordionStyle}>
                                        <Accordion.Item eventKey="Organismes">
                                            <Accordion.Header>
                                                <div className={styles.body_medium}>Organismes</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                {
                                                    loadingFilters == true ?
                                                    <div className="app-spinner">
                                                        <img width={50} src={loadingLogo} alt="loading..." />
                                                    </div> 
                                                    :
                                                    searchFilters[0] != null ? 
                                                    <AccordionFiltres2Nivel  
                                                        activeKey={activeKeyOrganismes}
                                                        handleSelect={handleSelectKeyOrganismes}
                                                        onChange={onFormSubmit} 
                                                        labels={searchFilters[0].secondLevel} 
                                                        primarytitle={searchFilters[0].title}/>
                                                    : null
                                                }
                                                </div>
                                            </Accordion.Body>                        
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="Temes">
                                            <Accordion.Header>
                                                <div className={styles.body_medium}>Temes</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                {
                                                    loadingFilters == true ?
                                                    <div className="app-spinner">
                                                        <img width={50} src={loadingLogo} alt="loading..." />
                                                    </div> 
                                                    :
                                                    searchFilters[1] != null ?
                                                    <AccordionFiltres2Nivel 
                                                        onChange={onFormSubmit} 
                                                        labels={searchFilters[1].secondLevel} 
                                                        primarytitle={searchFilters[1].title}
                                                        activeKey={activeKeyTemes}
                                                        handleSelect={handleSelectKeyTemes}
                                                        />
                                                    : null
                                                }
                                                </div>
                                            </Accordion.Body>                        
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className='col-xl-8'>
                    <div className={styles.container_main}>
                             <div className={styles.content_header}>
                                <div className={styles.p1}>Resultats</div>
                                <div>
                                {!(documentWebFilters.anyFilter.length == 0 && documentWebFilters.temaFilter.length == 0 && documentWebFilters.textSearch == "" && documentWebFilters.dateFilter.length == 0 
                                && documentWebFilters.organismeFilter.length == 0 && documentWebFilters.butlletiFilter == "") && loadingButlletins == false?
                                <Row>
                                    <Col>
                                        <Form>
                                            <Form.Select className={styles.form_control} onChange={changeOrder} value={order}>
                                                <option value={0}>Per rellevància</option>
                                                <option value={1}>Per data (més nou a més antic)</option>
                                                <option value={2}>Per data (més antic a més nou)</option>
                                            </Form.Select> 
                                        </Form>
                                    </Col>
                                </Row> : <span></span>}
                                </div>
                            </div> 
                            {
                                loadingButlletins == false && loadingFilters == false ?

                                documentWebFilters.anyFilter.length == 0 && documentWebFilters.temaFilter.length == 0 && documentWebFilters.textSearch == "" && documentWebFilters.dateFilter.length == 0 
                                && documentWebFilters.organismeFilter.length == 0 && documentWebFilters.butlletiFilter == ""
                                ?
                                <div className={styles.content_badge}>
                                    <div className={styles.overline_regular}>No s'han aplicat filtres.</div>
                                </div>
                                :
                                <div className={styles.content_badge}>
                                    <div className={styles.overline_regular}>Filtres aplicats</div>
                                    <div className={styles.content_badge_main}>
                                    {
                                        documentWebFilters.textSearch != "" ?
                                        <div className='me-1 mt-1'>
                                            <Badge
                                                id={'textSearch_' + documentWebFilters.textSearch}
                                                label= {"Que contingui: " + (isURIEncoded(documentWebFilters.textSearch) == false ? documentWebFilters.textSearch.replaceAll('+', ' ') : decodeURIComponent(documentWebFilters.textSearch.replaceAll('+', ' '))) }
                                                deleteItem={deleteItem}
                                            />
                                        </div>
                                        : null 
                                    }
                                    {
                                        documentWebFilters.dateFilter.length != 0 ? 
                                        documentWebFilters.dateFilter.map((filter: any) => {
                                            return (
                                                <div className='me-1 mt-1'>
                                                    <Badge
                                                        id={'date_' + filter.filterType + '_' + filter.filterValue}
                                                        label= {(filter.filterType == 'ge' ? "Des de: " : "Fins a: " ) + moment(filter.filterValue).format('DD/MM/YYYY')}
                                                        deleteItem={deleteItem}
                                                    />
                                                </div>
                                            )
                                            
                                        }) 
                                        : null 
                                    }
                                    {
                                        documentWebFilters.butlletiFilter != "" ?
                                        <div className='me-1 mt-1'>
                                            <Badge
                                                id={'butlleti_' + documentWebFilters.butlletiFilter}
                                                label= {"Número butlletí: " + documentWebFilters.butlletiFilter}
                                                deleteItem={deleteItem}
                                            />
                                        </div>
                                        : null 
                                    }
                                    {
                                        documentWebFilters.organismeFilter.length != 0 ?
                                        documentWebFilters.organismeFilter.map((filter: any) => {
                                            let labelAux = document.getElementsByName('Organismes_' + filter.filterValue)[0];
                                            if(labelAux != undefined)
                                            {
                                                let label = labelAux.id.split('_')[1];
                                                return(
                                                    <div className='me-1 mt-1'>
                                                        <Badge
                                                            id={'Organismes_' + filter.filterValue}
                                                            label= {"Organisme: " + label}
                                                            deleteItem={deleteItem}
                                                        />
                                                    </div>
                                                )
                                            }                                            
                                        })                                            
                                        : null 
                                    }
                                    {
                                        documentWebFilters.temaFilter.length != 0 ?
                                        documentWebFilters.temaFilter.map((filter: any) => {
                                            let labelAux = document.getElementsByName('Temes_' + filter.filterValue)[0];
                                            if(labelAux != undefined)
                                            {
                                                let label = labelAux.id.split('_')[1];
                                                return(
                                                    <div className='me-1 mt-1'>
                                                        <Badge
                                                            id={'Temes_' + filter.filterValue}
                                                            label= {"Tema: " + label}
                                                            deleteItem={deleteItem}
                                                        />
                                                    </div>
                                                )
                                            }
                                        })                                            
                                        : null 
                                    }
                                    {
                                        documentWebFilters.anyFilter != null ?
                                        documentWebFilters.anyFilter.map((filter: any) => {
                                            return (
                                                <div className='me-1 mt-1'>
                                                    <Badge
                                                        id={ 'Any_' + moment(filter).format('YYYY_MM')}
                                                        label= {moment(filter).format('MMMM [de] YYYY')}
                                                        deleteItem={deleteItem}
                                                    />
                                                </div>
                                            )
                                        })                                            
                                        : null
                                    }
                                    </div>
                                </div>

                                : null
                            }

                        {
                            loadingButlletins == true || loadingFilters == true ?
                            <div className={styles.container_main}>
                                <div className="app-spinner">
                                    <img width={150} src={loadingLogo} alt="loading..." />
                                </div>
                            </div>
                            :
                            
                                    documents != null && documents.length == 0 ?
                                    <div className={styles.content_mensaje}>
                                        <MsgIlustrationText
                                            ilustration={SinResultats}
                                            text={'No hi ha resultats per mostrar. Canviï els filtres.'}
                                        />
                                    </div>
                                    :
                                    <InfiniteScroll
                                        dataLength={documents.length} //This is important field to render the next data
                                        next={fetchData}
                                        hasMore={hasMore}
                                        loader={<div className="app-spinner">
                                                    <img width={50} src={loadingLogo} alt="loading..." />
                                                </div> }
                                        >
                                        {   documents.map((doc: any)=> {
                                            return (
                                                <div className="mt-3" onClick={writeCookies}>
                                                    <CardDocument
                                                        highlights={doc.highlights}
                                                        counter={doc.number}
                                                        date={doc.date}
                                                        extra={doc.extra}
                                                        sumari={doc.summary}
                                                        tags={doc.tags}
                                                        path={'/Documents/Detall?doc=' + doc.docName}
                                                        docName={doc.docName}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </InfiniteScroll>
                                    
                                }
                                {/* {
                                    loadingButlletins == false && itemCount > itemsPerPage ?
                                    <div className={styles.content_Pagination}>
                                        <Paginations active={pagination} pageItems={Math.ceil(itemCount/itemsPerPage)} onClick={changePagination}></Paginations>
                                    </div> : null
                                } */}
                        </div> 

                    </div>

                </div>    
            </div>
        </div>                                      
    )
}

export default Documents