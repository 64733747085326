import MevesFavoritsProps from "./MevesFavoritsProps"
import styles from './MevesFavorits.module.sass'
import FavoritsIC from './../../assets/icons/Favorits.svg'
import ButtonIcon from "../layout/buttons/button_icon/ButtonIcon"
import { BiPencil } from 'react-icons/bi'
import LineResultsFavorits from "./line_result_favorits/LineResultsFavorits"
import MsgIlustrationText from "../layout/mensajes/msg_ilustration_text/MsgIlustrationText"
import SinResultats from './../../assets/ilustrations/sin_alerts.svg'

function MevesFavorits(props:MevesFavoritsProps) {
    return (
        <div>
            <div className={styles.header}>
                <div className={styles.title}>
                    <img src={FavoritsIC} className={styles.icon} />
                    <div className={styles.p2}>Els meus doc. favorits</div>
                </div>
                <div>
                    <ButtonIcon
                        icon={<BiPencil/>}
                        onClick={()=>{window.open('/GestionarFavorits','_self')}}
                    />
                </div>   
            </div>

            <div className={styles.body}>
            {
                props.documents.length > 0 ?
                props.documents.map((item: any) => {
                    return(
                        <LineResultsFavorits
                            description={item.titol != null && item.titol != "" ? item.titol : item.sumari}
                            onClick={()=> {window.open('/Documents/Detall?doc=' + item.nomDocument, '_self')}}
                        />
                    )                        
                })
                : 
                <MsgIlustrationText
                    ilustration={SinResultats}
                    text={'No hi ha cap document preferit.'}
                />
            }
            </div>            
        </div>
        
    )
}

export default MevesFavorits