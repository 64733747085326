import CardContactProps from "./CardContactProps"
import styles from './CardContact.module.sass'



function CardContact(props:CardContactProps) {
    return(
            <div className={styles.card}>
                <img src={props.icon} className={styles.icon} />
                <div className={styles.caption_regular}>{props.label}</div>      
            </div>
    )
}

export default CardContact