import ContacteProps from "./ContacteProps"
import styles from './Contacte.module.sass'
import '../../styles/global.sass'
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import CardContact from "../../components/cards/card_contact/CardContact"

import Contacto from './../../assets/icons/Contacto.svg'
import Email from './../../assets/icons/email.svg'
import Localizacio from './../../assets/icons/localizacion.svg'
import { Link } from "react-router-dom"


function Contacte () {
    var apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY != null ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : "";

    return (
        <div className={styles.container}>
            <div className='margin_top'></div>
            <div className='container'>
                <h1>Contacta'ns</h1>
                <div className={styles.intro}>
                <div className={styles.caption_regular}>
                <div>Abans d'enviar-nos una pregunta, consulteu les nostres <Link to={'/PreguntesFrequents'}>{" preguntes freqüents"}</Link></div>
                </div>
                </div>
                <div className="row">
                    <div className='col-xl-6'>
                        <div className={styles.body_medium}>Atenció al públic</div>
                        <div className={styles.content_cards_contact}>
                            <div>
                                <CardContact
                                    icon={Email}
                                    label={<Link onClick={() => { window.open('mailto:bopa@bopa.ad', '_blank'); } } to={""}>bopa@bopa.ad</Link>}
                                />
                            </div>
                            <div>
                                <CardContact
                                    icon={Contacto}
                                    label={'+376 729 410'}
                                />
                            </div>
                            <div>
                                <CardContact
                                    icon={Localizacio}
                                    label={"C/ Dr. Vilanova, 15, pl.-5 nou edifici del Consell General, AD500 Andorra la Vella. Principat d'Andorra"} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row col-12 mt-5">
                <LoadScript googleMapsApiKey={apiKey} >
                    <GoogleMap mapContainerStyle={{ width: "100%", height: "100%", minHeight:"270px"}} center={{ lat: 42.50675290113227, lng: 1.5213669992731377 }} zoom={17} >
                        <Marker position={{ lat: 42.50655000257081, lng: 1.5211911081266318 }} />
                    </GoogleMap>
                </LoadScript>
                </div>    
            </div>
        </div>                            
    )
}

export default Contacte