import AccordionFiltres2NivelProps from './AccordionFiltres2NivelProps';
import './AccordionFiltres2Nivel.sass';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import BtnLink from '../../../layout/buttons/link/BtnLink';
import { useState } from 'react';

function AccordionFiltres2Nivel(props:AccordionFiltres2NivelProps) {
    const [allChecked, setAllChecked] = useState([] as any[]);

    const showAll = (name: any) => {
        let accordion = document.getElementById(props.primarytitle != undefined ? props.primarytitle : "") as any;
        accordion.childNodes.forEach((item: any)=> {
            if(item.id === name)
            {
                let elements = item.lastElementChild.children[0].children[0].childNodes;
                    elements.forEach((obj: any) => {
                        obj.childNodes[0].checked = true;
                    })
                props.onChange()
            }
        })
        getState(name)
    }

    const getState = (name: any) => {
        let state: number = 0;
        let accordion = document.getElementById(props.primarytitle != undefined ? props.primarytitle : "") as any;
        accordion.childNodes.forEach((item: any)=> {
            if(item.id === name)
            {
                let elements = item.lastElementChild.children[0].children[0].childNodes;
                    elements.forEach((obj: any) => {
                        if(obj.childNodes[0].checked == false) state = 1;
                    })
            }
        })

        if(state === 0) setAllChecked((oldArray: any) => [...oldArray, name]);
        else if (allChecked.indexOf(name) != -1) {
            allChecked.splice(allChecked.indexOf(name), 1);
        }
    }

    const showNone = (name: any) => {
        let accordion = document.getElementById(props.primarytitle != undefined ? props.primarytitle : "") as any;
        accordion.childNodes.forEach((item: any)=> {
            if(item.id === name)
            {
                let elements = item.lastElementChild.children[0].children[0].childNodes;
                    elements.forEach((obj: any) => {
                        obj.childNodes[0].checked = false;
                    })
                props.onChange()
            }
        })
        getState(name)
    }

    return(
            <div id="accordionFilters2Level">
                <Accordion id={props.primarytitle} activeKey={props.activeKey} onSelect={props.handleSelect}>
                    {
                        props.labels.map((item, count)=> {
                            return(
                                <Accordion.Item id={count.toString()} eventKey={count.toString()}>
                                    {props.activeKey == count.toString() ?
                                        <Accordion.Header>
                                            <div className={"AccordionTitleLevel2"}>
                                                <div>{item.secondarytitle.includes('.') ? item.secondarytitle.split('.')[1].trim() : item.secondarytitle}</div>
                                                {
                                                    allChecked.indexOf(count.toString()) == -1 ?
                                                    <BtnLink
                                                        onClick={(event: any) => {
                                                            event.stopPropagation();
                                                            showAll(count.toString())
                                                        }}
                                                        text={"Veure-ho tot"}
                                                    />
                                                    : 
                                                    <BtnLink
                                                        onClick={(event: any) => {
                                                            event.stopPropagation();
                                                            showNone(count.toString())
                                                        }}
                                                        text={"Treure-ho tot"}
                                                    />
                                                }                                                
                                            </div>                                        
                                        </Accordion.Header>
                                    : 
                                        <Accordion.Header>
                                            <div>{item.secondarytitle.includes('.') ? item.secondarytitle.split('.')[1].trim() : item.secondarytitle}</div>                                     
                                        </Accordion.Header>
                                    }
                                    
                                    <Accordion.Body>
                                        <div>
                                        <div style={{color:"#16A085", fontSize:"12px", cursor:"pointer", display:"none"}} onClick={props.handleSelectAll}>Mostra-ho tot</div>
                                            {item.labels != null && item.labels.length > 0 ? 
                                                item.labels.map((label: any)=> {
                                                    if (props.primarytitle == "Anys")
                                                    {
                                                        return (
                                                            <Form.Check
                                                                onChange={props.onChange}
                                                                id={'Any_' + (item.secondarytitle.includes('(') ? item.secondarytitle.split('(')[0].trim() : item.secondarytitle) + '_' + moment().month(label.split(' ')[0]).format("MM")}
                                                                name={'Any_' + (item.secondarytitle.includes('(') ? item.secondarytitle.split('(')[0].trim() : item.secondarytitle) + '_' + moment().month(label.split(' ')[0]).format("MM")}
                                                                type={'checkbox'}
                                                                label={label}
                                                            />
                                                        )
                                                    }
                                                    else
                                                    {
                                                        return (
                                                            <Form.Check
                                                                onChange={(event: any) => {
                                                                    props.onChange(event)
                                                                    setTimeout(() => {getState(count.toString())}, 50)                                                                    
                                                                }}
                                                                id={props.primarytitle + '_' + label.name}
                                                                name={props.primarytitle + '_' + label.guid}
                                                                type={'checkbox'}
                                                                label={label.name}
                                                            />
                                                        )
                                                    }                                 
                                                })
                                            : null}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }
                </Accordion>
            </div>        
    )
}

export default AccordionFiltres2Nivel