import styles from './PublicaBopa.module.sass'
import '../../styles/global.sass'

import BtnLink from "../../components/layout/buttons/link/BtnLink"


function PublicaBopa() {
    return(
        <div className={styles.container}>
            <div className='margin_top'></div>
            <div className='container'>
                <h1>Publica al BOPA</h1>
                <div className={styles.content}>
                    <div className='col-lg-6 col-sm-12'>
                        <div className={styles.intro}>
                            <div className={styles.caption_regular}>
                            Espai destinat només als organismes que publiquen documents al BOPA.
                            <br></br>
                            Per a qualsevol informació complementària poseu-vos en contacte amb el BOPA al telèfon +376 729 410 o bé envieu un correu electrònic a atencio.public.bopa@govern.ad.
                            <p></p>
                            <div className={styles.preguntes}><BtnLink onClick={()=> {window.open(process.env.REACT_APP_PUBLICA_BOPA_URL, '_self')}} text={"Accedir a l'espai de publicació del BOPA"}/></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <div className={styles.ilustration}></div>
                    </div>
                
                </div>
            </div>
        </div>                
        








        
    )
}

export default PublicaBopa