import IframeResizer from 'iframe-resizer-react'
import React, { useEffect, useState } from 'react';

function Pdf() {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension(){
      return window.innerHeight
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    
    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])


    return (
      <IframeResizer 
        src={process.env.REACT_APP_BLOB_STORAGE_URI_PDF + window.location.pathname}
        style={{ width: '100%', minWidth: '100%', height: screenSize}} 
      />
    );
  }

export default Pdf