import PerfilProps from "./PerfilProps"
import styles from './Perfil.module.sass'
import '../../styles/global.sass'
import Output from "../../components/forms/output/Output"
import BtnLink from "../../components/layout/buttons/link/BtnLink"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import AlertWarning from "../../components/Alerts/AlertWarning/AlertWarning"
import { BsArrowRight } from "react-icons/bs"
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary"
import axios from "axios"


function Perfil(props: PerfilProps) {

    const [modal, setModal] = useState(false);
    const [userName, setName] = useState(null as any);
    const [mail, setMail] = useState(null as any);

    const deleteUser = async () => {
        setModal(false);
        await props.apiService.DeletePublicUser()
        .then(() => {
            window.open(process.env.REACT_APP_LOGOUT_REDIRECT, '_self')
        })
    }
    
    useEffect(() => { 
        const cookieUserName = document.cookie.split(';').find(cookie => cookie.trim().startsWith('clientUserName='))?.split('=')[1] || '';
        const cookieUserMail = document.cookie.split(';').find(cookie => cookie.trim().startsWith('clientUserMail='))?.split('=')[1] || '';
        const cookieValue = document.cookie.split(';').find(cookie => cookie.trim().startsWith('clientToken='))?.split('=')[1] || '';
        if(cookieValue != null && cookieValue != "")
        {
            setName(cookieUserName);
            setMail(cookieUserMail);
        }
        else
        {
            setName(null)
        }
        // axios.get('/.auth/me').then((response: any) => {
        //   setName(response.data[0].user_claims.find((x: any) => x.typ == 'name').val);
        //   if (response.data[0].user_claims.find((x: any) => x.typ == 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress').val != null) setMail(response.data[0].user_claims.find((x: any) => x.typ == 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress').val);
        //   else setMail(response.data[0].user_claims.find((x: any) => x.typ == 'preferred_username').val);
        // })
        // .catch((error) => {
        //   setName(null)
        //   console.log(error);
        // });
    }, []);

    return (
        <div className={styles.container}>
            <div className='margin_top'></div>
            <div className='container'>
                <h1>El meu perfil</h1>
                
                <div className='col-lg-8 col-sm-12'>
                    <div className={styles.section}>
                        <Output 
                            label='Nom'
                            valor={userName}
                        />
                        <div className="mt-4"></div>
                        <Output 
                            label='E-mail'
                            valor={mail}
                        />
                    </div>

                    <div className="mt-4"></div>
                    <div className={styles.body_regular}>
                    Si suprimeixes el teu compte, perdràs totes les dades desades. 
                    <br></br>
                    Aquesta acció és irreversible.
                    </div>

                    <div className="mt-3"></div>
                    <BtnLink
                        text='Suprimeix compte'
                        onClick={() => setModal(true)}
                    />
                    
                </div>
                <Modal show={modal} onHide={() => {setModal(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Desar com a favorit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AlertWarning message="Aquesta acció és irreversible. Esteu segurs que voleu suprimir el vostre compte?" />
                    </Modal.Body>
                    <Modal.Footer>
                        <BtnPrimary text="Suprimeix ara " iconRight={<BsArrowRight />} onClick={ deleteUser } />
                    </Modal.Footer>
                </Modal>
            </div>
        </div>                       
        








        
    )
}

export default Perfil