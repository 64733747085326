import FlagExtraProps from './FlagExtraProps'
import styles from './FlagExtra.module.sass'


function FlagExtra(props:FlagExtraProps) {
    return (
        <div className={styles.lable_medium}>EXTRA</div>
    )
}

export default FlagExtra