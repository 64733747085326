import BtnLinkProps from "./BtnLinkProps"
import styles from './BtnLink.module.sass'
import Button from 'react-bootstrap/Button'
import { Link } from "react-router-dom"



function BtnLink(props:BtnLinkProps) {
    return(
            <Link to={props.path} className={styles.btn_link2} onClick={props.onClick}>
                <Button className={styles.btn_link} type={props.type}>
                    <span>{props.iconLeft}</span>
                    {props.text}
                    <span>{props.iconRight}</span>
                </Button>
            </Link>        
    )
}

export default BtnLink