import AccordionFiltres2NivelProps from '../accordion_filtres_2_nivel/AccordionFiltres2NivelProps';
import '../accordion_filtres_2_nivel/AccordionFiltres2Nivel.sass';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';

function AccordionFiltres2Alertes(props:AccordionFiltres2NivelProps) {
    return (
        <>
            <div id="accordionFilters2Level">
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div>{props.secondarytitle}</div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                {props.labels != null && props.labels.length > 0 ? 
                                    props.labels.map((label: any)=> {
                                            return (
                                                <Form.Check 
                                                    defaultChecked={props.selectedItems != null && props.selectedItems.findIndex(x => x.idCategoria == label.guid) != -1 ? true : false}
                                                    id={props.primarytitle + '_' + label.name}
                                                    name={props.primarytitle + '_' + label.guid}
                                                    type={'checkbox'}
                                                    label={label.name}
                                                />
                                            )                                       
                                    })
                                : null}
                                        
                            </div>
                        
                        </Accordion.Body>
                        
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
        
    )
}

export default AccordionFiltres2Alertes