import IniciProps from "./IniciProps"
import styles from './Inici.module.sass'
import '../../styles/global.sass'
import '../../styles/variables.sass'
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary"
import BtnLink from "../../components/layout/buttons/link/BtnLink"
import CardIniciBopa from "../../components/cards/card_inici_bopa/CardIniciBopa"
import CardHelp from "../../components/cards/card_help/CardHelp"
import ManualUsuari from './../../assets/icons/ManualUsuari.svg'
import Contacto from './../../assets/icons/Contacto.svg'
import Opinion from './../../assets/icons/Opinion.svg'
import MevesAlertes from "../../components/meves_alertes/MevesAlertes"
import MevesCerques from "../../components/meves_cerques/MevesCerques"
import MevesFavorits from "../../components/meves_favorits/MevesFavorits"
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import './calendari/Calendari.sass'
import { Dropdown, Form, Modal, Spinner } from "react-bootstrap"
import { useEffect, useState } from "react"
import { BsArrowRight } from "react-icons/bs"
import moment from "moment"
import loadingLogo from '../../assets/loading/loading.gif'
import { toast } from "sonner"
import axios from "axios"
import AccordionButlletin from "../../components/accordion/accordion_butlletin/AccordionButlletin"
import AlertDanger from "../../components/Alerts/AlertDanger/AlertDanger"
import Radio from "antd/es/radio";
import ConfigProvider from "antd/es/config-provider";

function Inici (props:IniciProps) {

    const deleteCookie = (nombre: any) => {
        const cookiePairs = document.cookie.split(';');
        cookiePairs.forEach(pair => {
          const [name, value] = pair.split('=');
          if (name.trim() === nombre) {
            // Aquí puedes hacer algo con la coincidencia, como borrarla
            document.cookie = `${nombre}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            sessionStorage.setItem('documentsFilters', '')
            sessionStorage.setItem('lawsFilters', '')
            sessionStorage.setItem('positon', '')
            sessionStorage.setItem('activeKey', '')
            sessionStorage.setItem('activeKeyOrganismes', '')
            sessionStorage.setItem('activeKeyTemes', '')
            sessionStorage.setItem('butlletiFilters', '')
            sessionStorage.setItem('year', '')
            sessionStorage.setItem('positon', '')
            sessionStorage.setItem('highlights', '')
          }
        });
      };

    const writeCookies = (token: any) => {
        props.apiService.CheckPublicUser(token)
        .then((response: any)=> {
            if(response.isValid == true && response.email != "")
            {
                if(response.name == null || response.name == "") {
                    setName(response.email)
                    document.cookie = "clientUserName=" + response.email;
                }
                else {
                    setName(response.name)
                    document.cookie = "clientUserName=" + response.name;
                }
                document.cookie = "clientUserMail=" + response.email;
                document.cookie = "clientToken=" + token;
                window.open('/','_self');
            }
            else {
                setErrorMessage(true)
                window.open('/','_self');
            }        
        })
        .catch((error: any)=> {
            setErrorMessage(true);
            window.open('/','_self');
        })        
    }

    useEffect(()=> {
        if(window.location.pathname == "/login")
        {
            axios.get('/.auth/me')
            .then((response: any) => {
                if(response.data[0].id_token != null)
                {
                    writeCookies(response.data[0].id_token);
                }
                else {
                    setErrorMessage(true);
                    window.open('/','_self');
                }                
            })
            .catch((error) => {
                setErrorMessage(true);
                window.open('/','_self');
            });
            
        }
        else if (window.location.pathname == "/logout")
        {
            document.cookie = `clientToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            document.cookie = `clientUserMail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            document.cookie = `clientUserName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            // document.cookie = `clientProvider=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            window.open('/','_self');
        }
    }, [])

    useEffect(() => { 
        const cookieValue = document.cookie.split(';').find(cookie => cookie.trim().startsWith('clientToken='))?.split('=')[1] || '';
        if(cookieValue != null && cookieValue != "")
        {
            // console.log(cookieValue)
            setName("");
            getAlerts()
            getSearches()
            getLikeDocuments()
        }
        else
        {
            setName(null)
        }
        // axios.get('/.auth/me')
        // .then((response: any) => {
        //     setName("");
        //     getAlerts()
        //     getSearches()
        //     getLikeDocuments()
        // })
        // .catch((error) => {
        //   setName(null)
        //   console.log(error);
        // });
    }, []);

    const [ userName, setName] = useState(null as any);
    // const [opinioModal,setOpinioModal] = useState(false);
    const [bopaDays, setBopaDays] = useState([] as any[]);
    const [searchText, setSearchText] = useState("");
    const [lastButlletins, setLastButlletins] = useState([] as any[]);
    const [alerts, setAlerts] = useState([] as any[]);
    const [alertsReady, setAlertsReady] = useState(false);
    const [searches, setSearches] = useState([] as any[]);
    const [searchesReady, setSearchesReady] = useState(false);
    const [favDocuments, setFavDocuments] = useState([] as any[]);
    const [favDocumentsReady, setFavDocumentsReady] = useState(false);
    const [month, setMonth] = useState(parseInt(moment().format('MM')) - 1);
    const [year, setYear] = useState(parseInt(moment().format('YYYY')));
    const [yearOptions, setYearOptions] = useState([] as any[]);
    // const [loadingButton, setLoadingButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);


    // const sendContactans = (event: any) => {
    //     event.preventDefault();
    //     setLoadingButton(true)
    //     var serialize = require('form-serialize');
    //     var form = document.querySelector('#opinionForm');
    //     var obj = serialize(form);
    //     let variables = obj.split('&');

    //     let contactForm = {
    //         name: "",
    //         email: "",
    //         message: "",
    //     }
    //     variables.map((item: any)=> {
    //         let itemType = item.split('=')[0];
    //         let itemValue =  item.split('=')[1];
    //         if(itemType == "name") contactForm.name = itemValue
    //         else if(itemType == "mail") contactForm.email = itemValue
    //         else if(itemType == "message") contactForm.message = itemValue
    //     })
        
        // props.apiService.SaveOpinionForm(contactForm)
        // .then(() => {
        //     setOpinioModal(false)
        //     toast.success("La teva opinió s'ha enviat correctament al BOPA")
        //     setLoadingButton(false)
        // })
        // .catch((error: any) => {
        //     console.log(error)
        //     toast.error("Error al enviar el formulari")
        //     setLoadingButton(false)
        // })
    // }

    const onInputChange = (event: any) => {
        if(event.code == "Enter"){
            event.preventDefault();
            window.open('/Documents?search=' + searchText,'_self')
          }
    }

    const getSearches = async () => {
        await props.apiService.GetSearches()
        .then((result: any)=> {
            setSearches(result)
            setSearchesReady(true)
        })
        .catch((error)=> {
            console.log(error)
            setSearchesReady(true)
        })
    }

    const getAlerts = () => {        
        props.apiService.GetFiltersAlertes()
        .then(async (result: any) => {
            let auxItems: any[] = [];
            result.organismes.forEach((organismePare: any) => {
                organismePare.organismes.forEach((organisme: any) => {
                    auxItems.push({
                        itemPare: organismePare.organismePare,
                        itemName: organisme.name,
                        itemGuid: organisme.guid,
                        itemType: 'itemOrganismes'
                    })
                })
            });  

            result.temes.forEach((temaPare: any) => {
                temaPare.temes.forEach((tema: any) => {
                    auxItems.push({
                        itemPare: temaPare.temaPare,
                        itemName: tema.name,
                        itemGuid: tema.guid,
                        itemType: 'itemTemes'
                    })
                })
            });  

            await props.apiService.GetAlerts()
            .then((response: any) => {
                let alertsAux: any[] = [];
                let i = 0;
                response.map((element: any) => {
                    let index = auxItems.findIndex(x => x.itemGuid == element.idCategoria)
                    if ( index != -1 )
                    {
                        alertsAux.push(auxItems[index])
                    }
                    i++;                       
                });

                if(response.length == i) {
                    setAlerts(alertsAux);
                    setAlertsReady(true)
                }             
            })
        })
        .catch((error: any) => {
            console.log(error);
        })
    }

    const getLikeDocuments = async () => {
        await props.apiService.GetLikeDocuments()
        .then((result: any)=> {
            setFavDocuments(result)
            setFavDocumentsReady(true)
        })
        .catch((error)=> {
            console.log(error)
            setFavDocumentsReady(true)
        })
    }

    useEffect(() => {
        for (let i = 1989; i <= parseInt(moment().format('YYYY')); i++ ) {
            yearOptions.push(i)
        }

        props.apiService.GetMonthButlletins(moment().add(1, 'days').format('YYYY-MM-DD'))
        .then((result: any) => {
            setBopaDays(result);
            var result2 = result.sort(function(a: any, b: any){
                return new Date(a.dataPublicacio).getTime() - 
                    new Date(b.dataPublicacio).getTime()
            }).reverse();
            let lastBopaAux = [];
            let j = 0;
            let date = moment();
            for (let i = true; i == true || moment(result2[j].dataPublicacio).diff(date, 'minutes') > 0; j++)
            {
                lastBopaAux.push(result2[j]);
                if(result2[j].isExtra == false) {
                    i = result2[j].isExtra;
                    date = moment(result2[j].dataPublicacio);
                }
            }
            setLastButlletins(lastBopaAux)
        })
        .catch((error: any) => {
            console.log(error)
        })
    }, [])

    useEffect(()=> {
        onChangeMonth();
    },[month, year])

    const onChangeMonth = () => {
        props.apiService.GetMonthButlletins(moment(new Date(year, month, 1)).format('YYYY-MM-DD'))
        .then((result: any) => {
            setBopaDays(result);
        })
        .catch((error: any) => {
            console.log(error)
        })
    }

    return(
        <div>
            <Modal dialogClassName="my-modal" show={errorMessage} onHide={() => {setErrorMessage(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AlertDanger message='Error al intentar inciar sessió'></AlertDanger>
                </Modal.Body>
            </Modal>
            <div className='margin_top'></div>
            <div className={styles.container_banner}>
                <div className={styles.content_banner}>
                    <div className={styles.title}>Butlletí Oficial del Principat d'Andorra</div>
                </div>
            </div>            

            <div className='container'>
                <div className='row'>
                    <div className='col-xl-3 col-sm-12 order-xl-first order-last'>
                        <div className={styles.container_secondary}>
                            <div className={styles.p2}>BOPA per data</div>
                                    <Form className={styles.formcalendar}>
                                        <Form.Select className={styles.form_calendar_item} onChange={(event: any)=> {setMonth(event.target.value)}} value={month}>
                                            <option value={0}>Gener</option>
                                            <option value={1}>Febrer</option>
                                            <option value={2}>Març</option>
                                            <option value={3}>Abril</option>
                                            <option value={4}>Maig</option>
                                            <option value={5}>Juny</option>
                                            <option value={6}>Juliol</option>
                                            <option value={7}>Agost</option>
                                            <option value={8}>Setembre</option>
                                            <option value={9}>Octubre</option>
                                            <option value={10}>Novembre</option>
                                            <option value={11}>Desembre</option>
                                        </Form.Select>
                                        <Form.Select className={styles.form_calendar_item} onChange={(event: any)=> {setYear(event.target.value)}} value={year}>
                                            { 
                                                yearOptions.map((item) => {
                                                    return(
                                                        <option value={item}>{item}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Form>
                                    <Calendar 
                                        locale={'ca'}
                                        value={new Date(year, month, 1)}
                                        // onActiveStartDateChange={onChangeMonth}
                                        /*onClickDay={(event: any) => { window.open('Butlletins?date=' + moment(event).format('YYYY-MM-DD'), '_self') }}*/
                                        tileClassName={ ({ activeStartDate, date, view }) => view === 'month' && bopaDays.findIndex((obj: any) => {
                                            return moment(obj.dataPublicacio).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD') ;
                                          }) != -1 ? "react-calendar__tile__BOPADay" : null }


                                        tileContent={ ({ activeStartDate, date, view }) => view === 'month' && bopaDays.findIndex((obj: any) => {
                                            return moment(obj.dataPublicacio).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD') ;
                                          }) != -1 ? 
                                          <div className={"tooltiptext"}>
                                          {bopaDays.map(obj => {
                                                if(moment(obj.dataPublicacio).format('YYYY-MM-DD')  == moment(date).format('YYYY-MM-DD') )
                                                {
                                                    return (
                                                        <li className="tooltip__li" onClick={(event:any)=>{window.open('Documents?num='+obj.num+'&yearItem='+moment(obj.dataPublicacio).format('YYYY_MM'),'_self')}}>
                                                            {obj.numBOPA}{obj.isExtra == true ? ' (EXTRA)': null}
                                                        </li> 
                                                    )
                                                }
                                            })}
                                          </div>
                                          : null }
                                    /> 
                            <div className={styles.container_ajuda}>
                                <div className={styles.p2}>Ajuda</div>
                                
                                <div className='mt-3'>
                                    <CardHelp
                                    icon={ManualUsuari}
                                    label="Manual d'usuari"
                                    path="/ManualUsuari"
                                    />
                                </div>
                                <div className='mt-3'>
                                    <CardHelp
                                    icon={Contacto}
                                    label="Contacta'ns"
                                    path="/Contacte"
                                    />
                                </div>
                            </div>
                            {/* <div className={styles.container_comentaris}>
                                <div className={styles.p2}>Comentaris i suggeriments</div>
                                <div className='mt-3'>
                                    <CardHelp
                                        icon={Opinion}
                                        label="Dóna'ns la teva opinió"
                                        onClick={() => { setOpinioModal(true) }}
                                    />
                                </div>
                            </div> */}
                        </div>
                        {/* <Modal show={opinioModal} onHide={() => {setOpinioModal(false)}}>
                            <Form id="opinionForm" onSubmit={sendContactans}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Dóna'ns la teva opinió</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                        <Form.Label>Nom i cognoms <span className={styles.required}>*</span></Form.Label>
                                        <Form.Control className={styles.form_control} name="name" type="text" placeholder="Insereix" required/>
                                        <p></p>
                                        <Form.Label>E-mail <span className={styles.required}>*</span></Form.Label>
                                        <Form.Control 
                                            className={styles.form_control} 
                                            name="mail" 
                                            type="email" 
                                            placeholder="email@email.ad" 
                                            required/>
                                        <p></p>
                                        <Form.Label>Descripció <span className={styles.required}>*</span></Form.Label>
                                        <Form.Control 
                                            required
                                            name="message"
                                            as="textarea" 
                                            rows={5} />
                                        <p></p>
                                </Modal.Body>
                                <Modal.Footer>
                                {
                                    loadingButton == true ? 
                                    <BtnPrimary
                                        disabled={true}
                                        type="submit"
                                        text={<div>Enviant ...<span className={styles.loading}> <Spinner
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"/> </span></div>}
                                    /> : <BtnPrimary text="Enviar ara " iconRight={<BsArrowRight />} type="submit" />}
                                </Modal.Footer>
                            </Form>
                        </Modal> */}
                    </div>
                    <div className='container-fluid col-xl-9 col-sm-12 order-xl-first order-first'>
                            {
                                props.isMedia == true ? 
                                <div className={styles.containerSearch}>
                                    <div className='col-xl-8 col-md-9 order-last'>
                                        <div className={styles.container_main}>
                                            <div className={styles.p2}>Butlletins</div>
                                            <div className={styles.container_cards_bopa}>
                                                { lastButlletins.length > 0 ? lastButlletins.map((items: any) => {
                                                    return (
                                                    <div className={styles.content_cards_bopa}>
                                                        <AccordionButlletin
                                                            docName={items.numBOPA.split(' ')[1] + '_' + moment(items.dataPublicacio).format('YYYY-MM-DD')}
                                                            extra= {items.isExtra}
                                                            counter= {items.numBOPA.split(' ')[1]}
                                                            title= {items.dataPublicacio}
                                                        />
                                                    </div>
                                                    )
                                                }) 
                                                : 
                                                <div className="app-spinner">
                                                        <img width={100} src={loadingLogo} alt="loading..." />
                                                </div>                                 
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-3 order-first">
                                        <div className={styles.container_main_cerca}>
                                            <div className={styles.p2}>Cerca al BOPA</div>
                                            <p></p>
                                            <Form.Control 
                                            onKeyDown={onInputChange}
                                            value={searchText} 
                                            onChange={(event: any)=>{setSearchText(event.target.value)}} 
                                            className={styles.form_control} 
                                            type="text" 
                                            placeholder={"Que contingui"} /> 
                                            <p></p>
                                            
                                            <div className={styles.content_buttons}>
                                            <BtnLink
                                                    text='Cerca exacta'
                                                    onClick={()=>{window.open('/Documents?searchMode=2&search=' + searchText,'_self')}}
                                                />

                                                <div className={styles.item_btn_primary}>
                                                    <BtnPrimary
                                                        text='Cerca ' 
                                                        onClick={()=>{window.open('/Documents?search=' + searchText,'_self')}}
                                                        iconRight={<BsArrowRight />}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={styles.containerSearch}>
                                    <div className='col-xl-8 col-md-9 order-first' >
                                        <div className={styles.container_main}>
                                            <div className={styles.p2}>Butlletins</div>
                                            <div className={styles.container_cards_bopa}>
                                                { lastButlletins.length > 0 ? lastButlletins.map((items: any) => {
                                                    return (
                                                    <div className={styles.content_cards_bopa}>
                                                        <AccordionButlletin
                                                            docName={items.numBOPA.split(' ')[1] + '_' + moment(items.dataPublicacio).format('YYYY-MM-DD')}
                                                            extra= {items.isExtra}
                                                            counter= {items.numBOPA.split(' ')[1]}
                                                            title= {items.dataPublicacio}
                                                        />
                                                    </div>
                                                    )
                                                }) 
                                                : 
                                                <div className="app-spinner">
                                                        <img width={100} src={loadingLogo} alt="loading..." />
                                                </div>                                 
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xl-4 col-md-3 order-last'>
                                        <div className={styles.container_main_cerca}>
                                            <div className={styles.p2}>Cerca al BOPA</div>
                                            <p></p>
                                            <Form.Control 
                                            onKeyDown={onInputChange}
                                            value={searchText} 
                                            onChange={(event: any)=>{setSearchText(event.target.value)}} 
                                            className={styles.form_control} 
                                            type="text" 
                                            placeholder={"Que contingui"} /> 
                                            <p></p>
                                            <div className={styles.content_buttons}>
                                                <BtnLink
                                                    text='Cerca exacta'
                                                    onClick={()=>{window.open('/Documents?searchMode=2&search=' + searchText,'_self')}}
                                                />

                                                <div className={styles.item_btn_primary}>
                                                    <BtnPrimary
                                                        text='Cerca ' 
                                                        onClick={()=>{window.open('/Documents?search=' + searchText,'_self')}}
                                                        iconRight={<BsArrowRight />}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            
                            {
                                userName != null ? 
                                <div className={styles.container_personal_items}>
                                    <div className={styles.content_personal_alerts}>
                                        { alertsReady == true ? <MevesAlertes alertes={alerts}></MevesAlertes> : null }
                                    </div>
                                    <div className={styles.content_personal_cerques}>
                                        { searchesReady == true ? <MevesCerques searches={searches}></MevesCerques> : null }
                                    </div>
                                    <div className={styles.content_personal_favorits}>
                                        { favDocumentsReady == true ? <MevesFavorits documents={favDocuments}></MevesFavorits> : null }
                                    </div>
                                </div>
                                : null
                            }
                    </div>
                    
                </div>
            </div>
        </div>
        
    )
}

export default Inici