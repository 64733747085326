import ButtonIconStandardProps from "./ButtonIconStandardProps"
import styles from './ButtonIconStandard.module.sass'
import Button from 'react-bootstrap/Button'

function ButtonIconStandard(props:ButtonIconStandardProps) {
    return(
        <Button className={styles.btn_icon} onClick={props.onClick}>
            {props.icon} 
        </Button>
    )
}

export default ButtonIconStandard