import ButtonIconProps from "./ButtonIconProps"
import styles from './ButtonIcon.module.sass'
import Button from 'react-bootstrap/Button'



function ButtonIcon(props:ButtonIconProps) {
    return (
        <Button className={styles.btn_icon} onClick={props.onClick}>
            {props.icon} 
        </Button>
    )
}

export default ButtonIcon