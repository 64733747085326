import styles from './Header.module.sass'
import logotipo from './../../../assets/icons/logotipo.svg'
import BtnSecondary from "../buttons/secondary/BtnSecondary"
import { Link, NavLink } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import './Header.sass'
import { BiUser, BiMenu, BiUserCircle, BiBell, BiSearch, BiHeart, BiLogOut} from "react-icons/bi";
import { RxCross1 } from "react-icons/rx";
import { useEffect, useState } from "react"
import axios from "axios"
// import { GoogleLogin, googleLogout } from '@react-oauth/google';
// import MicrosoftLogin from "react-microsoft-login";
import HeaderProps from './HeaderProps'
// import { useGoogleLogin } from '@react-oauth/google';
import GoogleImage from '../../../assets/icons/google_login.png'
import MicrosoftImage from '../../../assets/icons/microsoft_login.png'
import CertificatImage from '../../../assets/icons/certificat_digital.png'
import { Button, Modal } from 'react-bootstrap'
import BtnLogin from '../buttons/loginButton/BtnLogin'
import BtnPrimary from '../buttons/primary/BtnPrimary'
import AlertDanger from '../../Alerts/AlertDanger/AlertDanger'
import { FiExternalLink } from 'react-icons/fi'

function Header(props: HeaderProps) {

    // const [msalInstance, onMsalInstanceChange] = useState();
    // const [provider, setProvider] = useState("");
    const [loginPopup, setLoginPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    // const login = useGoogleLogin({
    //     onSuccess: async tokenResponse => {
    //         console.log(tokenResponse);
    //         // fetching userinfo can be done on the client or the server
    //         const userInfo = await axios
    //           .get('https://www.googleapis.com/oauth2/v3/userinfo', {
    //             headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
    //           })
    //           .then(res => res.data);
      
    //         console.log(userInfo);
    //       },
    //       // flow: 'implicit', // implicit is the default
    //     });
      
    // const logoutHandler = () => {
    //     // let msalInstance2: any = msalInstance
    //     // msalInstance2.logout();
    //   };

    // const handleAuthenticationMicrosoft = (error: any, authData: any, msalInstance: any) => {
    //     if(error!=null && error.errorCode == "popup_window_error")
    //     {
    //         alert("Per a poder utilitzar l'autenticació de Microsoft, cal que permeteu finestres emergents del BOPA")
    //     } else {
    //         if(error==null)
    //         {
    //             onMsalInstanceChange(msalInstance)
    //             //writeCookiesMs(authData.accessToken, "Microsoft", authData);
    //             if(authData.accessToken.startsWith('ey'))
    //             {
    //                 writeCookies(authData.accessToken, "Microsoft");
    //             } else {
    //                 writeCookies(authData.idToken, "Microsoft");

    //             }
    //             setProvider("Microsoft");
    //         } 
    //     }
        
    //   };

    const [ userName, setName] = useState(null as any);
    useEffect(() => {
        const cookieUserName = document.cookie.split(';').find(cookie => cookie.trim().startsWith('clientUserName='))?.split('=')[1] || '';
        // const cookieProvider = document.cookie.split(';').find(cookie => cookie.trim().startsWith('clientProvider='))?.split('=')[1] || '';
        const cookieValue = document.cookie.split(';').find(cookie => cookie.trim().startsWith('clientToken='))?.split('=')[1] || '';
        if(cookieValue != null && cookieValue != "")
        {
            // setProvider(cookieProvider)
            setName(cookieUserName);
        }
        else
        {
            setName(null)
        }
        // axios.get('/.auth/me').then((response: any) => {
        //   setName(response.data[0].user_claims.find((x: any) => x.typ == 'name').val);
        // })
        // .catch((error) => {
        //   setName(null)
        //   console.log(error);
        // });
    }, []);

    // const handleAuthenticationGoogle= (token: any) => {
    //     setProvider("Google");
    //     writeCookies(token, "Google");
    //   };

    // const writeCookiesMs = (token: any, clientProvider: any, authData: any) => {

    //         if(authData.account.username != "")
    //         {
    //             setName(authData.account.name)
    //             document.cookie = "clientUserMail=" + authData.account.username;
    //             document.cookie = "clientUserName=" + authData.account.name;
    //             document.cookie = "clientToken=" + token;
    //             document.cookie = "clientProvider=" + clientProvider;
    //             window.location.reload();
    //         }
    //         else {
    //             setErrorMessage(true)
    //         }               
    // }

    const writeCookies = (token: any, clientProvider: any) => {
        props.apiService.CheckPublicUser(token)
        .then((response: any)=> {
            if(response.isValid == true && response.email != "")
            {
                if(response.name == null || response.name == "") {
                    setName(response.email)
                    document.cookie = "clientUserName=" + response.email;
                }
                else {
                    setName(response.name)
                    document.cookie = "clientUserName=" + response.name;
                }
                document.cookie = "clientUserMail=" + response.email;
                // document.cookie = "clientUserName=" + response.name;
                document.cookie = "clientToken=" + token;
                document.cookie = "clientProvider=" + clientProvider;
                window.location.reload();
            }
            else {
                setErrorMessage(true)
            }        
        })
        .catch((error: any)=> {
            console.log(error)
            setErrorMessage(true)
        })        
    }

    const deleteCookie = (nombre: any) => {
        const cookiePairs = document.cookie.split(';');
        cookiePairs.forEach(pair => {
          const [name, value] = pair.split('=');
          if (name.trim() === nombre) {
            // Aquí puedes hacer algo con la coincidencia, como borrarla
            document.cookie = `${nombre}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          }
        });
      };

    const refreshPage = (url: any) => {
        if(window.location.pathname.startsWith(url))
        {
            deleteCookie("butlletiFilters")
            deleteCookie("documentsFilters")
            deleteCookie("lawsFilters")
            window.open(url, "_self")
        }
    }

    return (
        <div className={styles.navbar}>
            <Modal dialogClassName="my-modal" show={loginPopup} onHide={() => {setLoginPopup(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Accés usuaris</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BtnLogin disabled={true}
                        text={<div className='iconTextLogin'><div className='login_icon'>{<img src={CertificatImage} height={18}/>}</div><div className='text_login'>Inicia la sessió amb el Certificat Digital</div></div>}
                    />
                    <p></p>
                    {/* <GoogleLogin
                        width={'400'}
                        locale='ca'
                        onSuccess={credentialResponse => {
                            handleAuthenticationGoogle(credentialResponse.credential)
                        }}
                        onError={() => {
                             console.log('Login Failed');
                        }}
                    />  */}
                    <BtnLogin onClick={() => {window.open(process.env.REACT_APP_GOOGLE_REDIRECT, "_self")}} iconLeft={<img src={GoogleImage} height={20}/>} text={" Google"}/>
                    <p></p>
                    {/* <BtnLogin
                        text={<MicrosoftLogin
                            redirectUri={process.env.REACT_APP_REDIRECT_URI}
                            graphScopes={['user.read']}
                            clientId={process.env.REACT_APP_CLIENTID_MICROSOFT != null ? process.env.REACT_APP_CLIENTID_MICROSOFT : ""}
                            authCallback={handleAuthenticationMicrosoft}>
                        <div className='iconTextLogin'><div className='login_icon'>{<img src={MicrosoftImage} height={18}/>}</div><div className='text_login'>Inicia la sessió amb Microsoft</div></div>
                        </MicrosoftLogin>}
                    /> */}
                    <BtnLogin onClick={() => {window.open(process.env.REACT_APP_MICROSOFT_REDIRECT, "_self")}} iconLeft={<img src={MicrosoftImage} height={20}/>} text={" Microsoft"}/>
                </Modal.Body>
            </Modal>

            <Modal dialogClassName="my-modal" show={errorMessage} onHide={() => {setErrorMessage(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AlertDanger message='Error al intentar inciar sessió'></AlertDanger>
                </Modal.Body>
            </Modal>
            
            <div id="logotipo">
                <a href="/"><img src={logotipo} className={styles.logotipo}/></a>
            </div>
            <div>
                <ul className={styles.list}>
                            <li>
                                <NavLink to="/" className={({ isActive }) => (isActive ? styles.itemActive : styles.item)}>Inici</NavLink>
                            </li>
                            <li>
                                <NavLink onClick={()=> {refreshPage("/Butlletins")}} to="/Butlletins" className={({ isActive }) => (isActive ? styles.itemActive : styles.item)}>Butlletins</NavLink>
                            </li>
                            <li>
                                <NavLink onClick={()=> {refreshPage("/Documents")}} to="/Documents" className={({ isActive }) => (isActive ? styles.itemActive : styles.item)}>Documents</NavLink>
                            </li>
                            <li>
                                <NavLink onClick={()=> {refreshPage("/Legislacio")}} to="/Legislacio" className={({ isActive }) => (isActive ? styles.itemActive : styles.item)}>Legislació</NavLink>
                            </li>
                            <li>
                                <NavLink onClick={()=> {window.open(props.portaljuridicuri, "_blank")}} to={window.location.pathname} className={styles.item}>Portal jurídic <div className={styles.linkIcon}><FiExternalLink /></div></NavLink> 
                            </li>
                    <div className={styles.itemBtn}>
                        { userName != null ?
                            <li className={styles.loginItem}>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                    <i> {<BiUserCircle size="20"/>}</i> {userName}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="/MeuPerfil">{<BiUserCircle size="20"/>} El meu perfil</Dropdown.Item>
                                        <Dropdown.Item href="/GestionarAlerts">{<BiBell size="20"/>} Les meves alertes</Dropdown.Item>
                                        <Dropdown.Item href="/GestionarCerques">{<BiSearch size="20"/>} Les meves cerques</Dropdown.Item>
                                        <Dropdown.Item href="/GestionarFavorits">{<BiHeart size="20"/>} Els meus documents favorits</Dropdown.Item>
                                        <Dropdown.Item href={process.env.REACT_APP_LOGOUT_REDIRECT}>{<BiLogOut size="20"/>} Tanca sessió</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                          :
                            <li className={styles.loginItem}>
                                <BtnPrimary onClick={()=>{setLoginPopup(true)}} iconLeft={<BiUser size="20"/>} text={'Accés usuaris'}></BtnPrimary>
                            </li>
                        }
                        <div className={styles.itemBtn}>
                        <li className={styles.itemBtnMenu}>
                            <BtnSecondary
                                // text='Menu'
                                iconLeft={ props.menuState == true ? <RxCross1 /> : <BiMenu/>}
                                onClick={props.showMenu}
                            />
                        </li>
                        { userName != null ?
                                <li className={styles.loginItemMobile}>
                                    
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic">
                                        <i> {<BiUserCircle size="20"/>}</i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="/MeuPerfil">{<BiUserCircle size="20"/>} El meu perfil</Dropdown.Item>
                                            <Dropdown.Item href="/GestionarAlerts">{<BiBell size="20"/>} Les meves alertes</Dropdown.Item>
                                            <Dropdown.Item href="/GestionarCerques">{<BiSearch size="20"/>} Les meves cerques</Dropdown.Item>
                                            <Dropdown.Item href="/GestionarFavorits">{<BiHeart size="20"/>} Els meus documents favorits</Dropdown.Item>
                                            {/* <Dropdown.Item onClick={()=> {
                                                if(provider == "Google") {
                                                    googleLogout();
                                                }
                                                if (provider == "Microsoft") {
                                                    logoutHandler();
                                                }
                                                document.cookie = `clientToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                                                document.cookie = `clientProvider=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                                                window.location.reload();
                                            }}>{<BiLogOut size="20"/>} Tanca sessió</Dropdown.Item> */}
                                            <Dropdown.Item href={process.env.REACT_APP_LOGOUT_REDIRECT}>{<BiLogOut size="20"/>} Tanca sessió</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            :
                                <li className={styles.loginItemMobile}>
                                    <BtnPrimary onClick={()=>{setLoginPopup(true)}} iconLeft={<BiUser size="20"/>}></BtnPrimary>
                                </li>
                            }
                        </div>
                    </div>
                </ul>
            </div>
        </div>
    )
}

export default Header