import LineResultsAlertsProps from "./LineResultsAlertsProps"
import styles from './LineResultsAlerts.module.sass'
import { BiChevronRight } from 'react-icons/bi'


function LineResultsAlerts(props:LineResultsAlertsProps) {
    return (
        <div className={styles.container} onClick={props.onClick}>
            <div className={styles.content}>
                <div className={styles.counter}>{props.counter}</div>
                <div className={styles.text}>{props.text}</div>
            </div>
            <div className={styles.arrow}><BiChevronRight/></div>
            
        </div>            
    )
}

export default LineResultsAlerts