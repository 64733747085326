import BadgeProps from './BadgeProps'
import styles from './Badge.module.sass'
import xIcon from "../../../assets/icons/x-lg.png"

function Badge(props:BadgeProps) {
    return (
            <div className={styles.container} id={props.id} onClick={() => {props.deleteItem(props.id)}}>
                <div className={styles.containerContent}>{props.label}</div>
                <img className={styles.deleteButton}  src={xIcon} />
            </div>        
    )
}

export default Badge