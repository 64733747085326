import CardSumariProps from "./CardSumariProps"
import styles from './CardSumari.module.sass'
import { Link } from "react-router-dom"

function CardSumari(props: CardSumariProps) {
    const blobUri = process.env.REACT_APP_BLOB_STORAGE_URI

    const openSumari = (event: any) => {
        event.preventDefault();
        window.open(blobUri + '/sumaris/' + props.path +'.pdf','_blank')
    }
    
    return (
        <div onClick={openSumari}>
            <Link to={""} className={styles.btn_link} >
                <div className={styles.card} onClick={props.onClick}>
                    <img src={props.icon} className={styles.icon} />
                    <div className={styles.caption_regular}>{props.label}</div>      
                </div>
            </Link>
        </div>
    )
}

export default CardSumari