import FiltresDocumentsProps from "./FiltresDocumentsProps"
import styles from './../Forms.module.sass'
import Form from 'react-bootstrap/Form'
import { Col, Row } from "react-bootstrap"
import DatePicker, { registerLocale } from "react-datepicker";
import "../../../styles/react-datepicker.sass";
import ca from "date-fns/locale/ca"; // the locale you want
import Radio from "antd/es/radio";
import ConfigProvider from "antd/es/config-provider";
registerLocale("ca", ca); // register it with the name you want

function FiltresDocuments(props:FiltresDocumentsProps) {
    return (
            <>
                <Form.Control 
                    onKeyDown={props.onEnter}
                    value={props.text}
                    onChange={props.changeText}
                    name="textSearch"
                    className={styles.form_control} 
                    type="text" 
                    placeholder="Que contingui..." />
                        <ConfigProvider
                            theme={{
                                components: {
                                Radio: {
                                    colorPrimary: "#16A085",
                                    colorPrimaryActive: "#3A7C69",
                                    colorPrimaryHover: "#3A7C69"
                                },
                                },
                            }}
                        >
                        <div className={styles.radios}>
                        <Radio.Group onChange={props.handleOnChangeTextSearchType} value={props.searchMode}>
                            <Radio value={1}>Cercar paraules en text</Radio>
                            <Radio value={2}>Cercar frase exacta</Radio>
                        </Radio.Group>
                        </div>
                    </ConfigProvider>
                <Row>
                    <Col>
                        <DatePicker
                            id="date"
                            name="initDate"
                            className={styles.form_control_date} 
                            placeholderText="De"
                            minDate={new Date("01/01/1989")}
                            maxDate={new Date()}
                            dateFormat={"dd/MM/yyyy"}
                            locale="ca"
                            dropdownMode="select"
                            showMonthDropdown
                            showYearDropdown
                            selected={props.initDate != "" ? new Date(props.initDate.split('/')[2], props.initDate.split('/')[1] - 1, props.initDate.split('/')[0]) : null}
                            onChange={(date: any) => {props.changeInitDate(date)}}
                        />
                    </Col>
                    <Col>
                        <DatePicker
                            id="date"
                            name="endDate"
                            placeholderText="Fins a"
                            className={ styles.form_control_date }
                            minDate={new Date("01/01/1989")}
                            maxDate={new Date()}
                            dateFormat={"dd/MM/yyyy"}
                            locale="ca"
                            dropdownMode="select"
                            showMonthDropdown
                            showYearDropdown
                            selected={props.endDate != "" ? new Date(props.endDate.split('/')[2], props.endDate.split('/')[1] - 1, props.endDate.split('/')[0]) : null} 
                            onChange={(date: any) => {props.changeEndDate(date)}} 
                        /> 
                    </Col>
                </Row>
                <br></br>
                {/* <Row>
                    <Col>
                        <Form.Control 
                            onKeyDown={props.onEnter}
                            value={props.buttleti}
                            onChange={props.changeButlleti}
                            name="butlletiNumber"
                            className={styles.form_control} 
                            type="text" 
                            placeholder="Número butlletí" /> 
                    </Col>
                    <Col></Col>
                </Row> */}
            </>
    )
}

export default FiltresDocuments