import styles from './NotFoundDocument.module.sass'
import '../../styles/global.sass'
import NoExiste from '../../assets/ilustrations/NoExiste.png'
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary"
import { BsArrowRight } from "react-icons/bs"
import { useNavigate } from 'react-router-dom'

function NotFoundDocument() {
    let navigate = useNavigate();

    return (
        <div className='container'>
            <div className={styles.title }>El document no existeix</div>
            <div className={styles.subTitle }>Malauradament, el document que busca no existeix.</div>
            <div className={styles.button }>
                <BtnPrimary 
                    text={"Tornar "} 
                    iconRight={<BsArrowRight/>} 
                    disabled={ window.history.length > 1 ? false :  true}
                    onClick={() => navigate(-1)}
                    // onClick={()=>{window.open('/' + (window.location.pathname.split('/')[1] != 'bopa' ? window.location.pathname.split('/')[1] : '' ), '_self')}}
                />
            </div>
            <div className={styles.image}><img src={NoExiste} /></div>
        </div>      
    )
}

export default NotFoundDocument