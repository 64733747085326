import ButlletinsProps from "./ButlletinsProps"
import styles from './Butlletins.module.sass'
import '../../styles/global.sass'
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary"
import FiltresButlletins from "../../components/forms/filtres_butlletins/FiltresButlletins"
import Badge from "../../components/layout/badge/Badge"
import AccordionButlletin from "../../components/accordion/accordion_butlletin/AccordionButlletin"
import MsgIlustrationText from "../../components/layout/mensajes/msg_ilustration_text/MsgIlustrationText"
import SinResultats from './../../assets/ilustrations/sin_resultados.svg'
import { BsArrowRight, BsArrowUpCircle } from "react-icons/bs"
import { BiFilterAlt } from "react-icons/bi"
import { useEffect, useState } from "react"
import { Accordion, Form } from "react-bootstrap"
import loadingLogo from '../../assets/loading/loading.gif'
import moment from "moment"
import BtnAsLink from "../../components/layout/buttons/BtnAsLink/BtnAsLink"
import AccordionFiltres2Years from "../../components/accordion/accordion_filtres/accordion_filtres_2_years/AccordionFiltres2Years"
import InfiniteScroll from 'react-infinite-scroll-component';

function Butlletins(props: ButlletinsProps) {
    const queryParameters = new URLSearchParams(window.location.search)
    const yearItem = queryParameters.get("yearItem");
    const num = queryParameters.get("num");
    const date = queryParameters.get("date");

    const itemsPerPage = 25;
    const initFilter = {
        dateFilter: [] as any,
        butlletiFilter: null as any,
        anyFilter: [] as any,
        orderBy: "desc"
    }

    const [firstIteration, setFirstIteration] = useState(true);
    const [pageItems, setPageItems] = useState(1);
    const [pageItems2, setPageItems2] = useState(itemsPerPage);
    const [butlletiValue, setButlletiValue] = useState(num != null ? num : "");
    const [initDateValue, setInitDateValue] = useState(date!= null ? moment(date) : yearItem != null ?  moment(yearItem + "0101", "YYYYMMDD") : '');
    const [endDateValue, setEndDateValue] = useState(date!= null ? moment(date) : yearItem != null ?  moment(yearItem + "0101", "YYYYMMDD").endOf('year') : '');
    const [searchFilters, setSearchFilters] = useState([] as any[]);
    const [loadingFilters, setLoadingFilters] = useState(true);
    const [loadingButlletins, setLoadingButlletins] = useState(true);
    const [documentWebFilters, setdocumentWebFilters] = useState(initFilter);
    const [year, setYear] = useState(0);
    const [activeKey, setActiveKey] = useState("");
    const [documents, setDocuments] = useState([] as any[]);
    const [documentsDisplay, setDocumentsDisplay] = useState([] as any[]);
    const [showTopBtn, setShowTopBtn] = useState(false);
    const [skipToken, setSkipToken] = useState(null as any);
    const [bodyFilters, setBodyFilters] = useState(null as any);

    const writeCookies = () => {
        sessionStorage.setItem('butlletiFilters', JSON.stringify(documentWebFilters))
        sessionStorage.setItem('year', year.toString())
        sessionStorage.setItem('positon', window.pageYOffset.toString())
    }

    const onInputChange = (event: any) => {
        if(event.code == "Enter"){
            event.preventDefault();
            onFormSubmit();  
          }
    }

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleSelect = (eventKey: any) => {
        setActiveKey(eventKey);
    }

    window.addEventListener("resize", () => {
        if (window.matchMedia("(min-width: 1280px)").matches) {
            setActiveKey("Anys");
        } else {
            setActiveKey("");
        }
    });
    
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });

        if (window.matchMedia("(min-width: 1280px)").matches) {
            setActiveKey("Anys");
        } else {
            setActiveKey("");
        }       
    }, []);

    const changeButlletiValue = (event: any) => {
        setButlletiValue(event.target.value)
    }

    const changeYearValue = (event: any) => {
        setYear(event.target.value)
    }

    const changeYearValue2 = (event: any) => {
        let value = 0;
        if(event.target.textContent.includes(" "))
        {
            value = parseInt(event.target.textContent.split(" ")[0])
        } else {
            value = parseInt(event.target.textContent)
        }
        setYear(value)
    }

    useEffect(()=> {
        onFormSubmit()
    },[year])

    const changeInitDateValue = (event: any) => {
        setInitDateValue(event)
        onFormSubmit()
    }

    const changeEndDateValue = (event: any) => {
        setEndDateValue(event)
        onFormSubmit()
    }

    const deleteItem = (event: any) => {
        setLoadingButlletins(true);

        if(event.split('_')[0] == 'butlleti')
        {
            setButlletiValue('');
            documentWebFilters.butlletiFilter = '';
        }
        else 
        {
            let elements = document.getElementsByName(decodeURI(event));
            elements.forEach((obj: any) => {
                obj.checked = false;
            })

            if(event.split('_')[0] == 'Any')
            {
                documentWebFilters.anyFilter = documentWebFilters.anyFilter.filter((obj: any) => {
                    return(obj != `${event.split('_')[1]}-${event.split('_')[2]}`)
                });
            }             
        }

        var body = {
            anys: documentWebFilters.anyFilter,
            sizePage: itemsPerPage,
            skipToken: null,
            datesList: documentWebFilters.dateFilter,
            numButlleti: documentWebFilters.butlletiFilter
        }
        setBodyFilters(body);

        setdocumentWebFilters(documentWebFilters);
        applyFilters(body);
    }

    const clearFilters = (event: any) => {
        event.target.reset();
        let docAux = documents.map((doc: any) => {
            doc.display = true
            return(doc)
        });
        sessionStorage.setItem('butlletiFilters', '')
        sessionStorage.setItem('year', '')
        sessionStorage.setItem('positon', '')
        // document.cookie = `butlletiFilters=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        setYear(0);
        setDocuments(docAux);
        setButlletiValue("");
        setdocumentWebFilters(initFilter);
    }

    const handleSubmit  = (event: any) => {
        event.preventDefault();
        setLoadingButlletins(true);
        if(event.nativeEvent.submitter.name  == "cleaner")
        {
            clearFilters(event);
        } else if (event.nativeEvent.submitter.name  == "submitter") {
            onFormSubmit();
        }
    }

    useEffect(()=> {
        if(bodyFilters != null) {
            let auxBody = bodyFilters;
            auxBody.skipToken = skipToken; 
            setBodyFilters(auxBody);
        }        
    }, [skipToken]);

    const onFormSubmit = () => {
        var serialize = require('form-serialize');
        var form = document.querySelector('#filterFormButlletins');
        var obj = serialize(form);
        let variables = obj.split('&');
        let documentWebFiltersAux = initFilter;
        var body = {
            anys: [] as any[],
            sizePage: itemsPerPage,
            skipToken: null as any,
            datesList: [] as any[],
            numButlleti: null as any
        }

        variables.map((item: any) => {
            let key = item.split('=')[0];
            let value = item.split('=')[1];
            if(key == 'butlletiNumber') {
                documentWebFiltersAux.butlletiFilter = value
                body.numButlleti = value
            }
            else if(key == 'anyNumber' && value != 0) {
                body.anys.push(value);
            }
            else if(value == 'on' && key.split('_')[0] == "Any") {
                documentWebFiltersAux.anyFilter.push(`${key.split('_')[1]}-${key.split('_')[2]}`);
                body.datesList.push(`${key.split('_')[1]}-${key.split('_')[2]}-01`);
            }
        })

        setBodyFilters(body);
        applyFilters(body);
        setdocumentWebFilters(documentWebFiltersAux);
    }

    const loadMoreDocs = () => {
        bodyFilters.skipToken = skipToken;
        setActiveKey(bodyFilters);
        props.apiService.GetNewPaginatedNewsletter(bodyFilters)
        .then((result: any) => {
            let docsAux: any[] = [];
            result.bopaList.map((doc: any) => {
                docsAux.push({
                    number: doc.numBOPA,
                    date: moment(doc.dataPublicacio).format('DD/MM/YYYY'),
                    extra: doc.isExtra != null ? doc.isExtra : false,
                    docName: doc.numBOPA,
                    display: true,
                    any: moment(doc.dataPublicacio).format('YYYY'),
                })
            })
            if(docsAux.length == result.bopaList.length) {
                setDocuments(oldArray => [...oldArray, ...docsAux]);
                setSkipToken(result.skipToken)
            }
        })
        .catch((error: any) => {
            console.log(error);
        })
    }

    const applyFilters = (body: any) => {
        setLoadingButlletins(true);
        props.apiService.GetNewPaginatedNewsletter(body)
        .then((result: any) => {
            let docsAux: any[] = [];
            result.bopaList.map((doc: any) => {
                docsAux.push({
                    number: doc.numBOPA,
                    date: moment(doc.dataPublicacio).format('DD/MM/YYYY'),
                    extra: doc.isExtra != null ? doc.isExtra : false,
                    docName: doc.numBOPA,
                    display: true,
                    any: moment(doc.dataPublicacio).format('YYYY'),
                })
            })
            setDocuments(docsAux);
            setSkipToken(result.skipToken);
            setTimeout(()=> {
                setLoadingButlletins(false);
            }, 200);
        })
        .catch((error: any) => {
            console.log(error);
            setLoadingButlletins(false);
        })
    }

    useEffect(() => {
        setDocumentsDisplay(documents.filter((doc: any) => doc.display == true ));
        setPageItems(Math.ceil(documents.filter((doc: any) => doc.display == true ).length/itemsPerPage));
        if(firstIteration == true)
        {
            if(documents.length > 0) {
                setFirstIteration(false)
                setLoadingButlletins(false)
            }
        } else {
            setLoadingButlletins(false)
        }
    }, [documents])

    useEffect(() => {
            let yearInit = 0;
            let butlletiInit = "";
            let anysInit: any[] = [];
            let scrollPosition = 0;

            const cookieValue = sessionStorage.getItem('butlletiFilters') || '';
            const cookieYear = sessionStorage.getItem('year') || '0';
            const cookiePosition = sessionStorage.getItem('positon') || '0';

            sessionStorage.setItem('butlletiFilters', '')
            sessionStorage.setItem('year', '')
            sessionStorage.setItem('positon', '')

            if(cookieValue != '') {
                let cookieData = JSON.parse(cookieValue)

                yearInit = parseInt(cookieYear);
                butlletiInit = cookieData.butlletiFilter;
                anysInit = cookieData.anyFilter;

                setYear(parseInt(cookieYear))
                scrollPosition = parseInt(cookiePosition);
                if(cookieData.butlletiFilter != null && cookieData.butlletiFilter != "") setButlletiValue(cookieData.butlletiFilter)

                let documentWebFiltersAux = JSON.parse(JSON.stringify(documentWebFilters));
                documentWebFiltersAux.anyFilter = cookieData.anyFilter;
                setTimeout(() => setdocumentWebFilters(documentWebFiltersAux), 100)
            }

            if(num != null && num != "") {
                butlletiInit = num
                setButlletiValue(num)
            }

            props.apiService.GetFiltersNewsletter()
            .then(async (result) => {
                let auxAnys: any[] = [];
                let auxFilters: any[] = [];

                result.anys.forEach((any: any) => {
                    let auxMonths: any[] = [];
                    any.mesos.forEach((mes: any) => {
                        auxMonths.push(mes.mesName + ' ' + mes.title)
                        if(auxMonths.length == any.mesos.length)
                        {
                            auxAnys.push({
                                secondarytitle: any.anyName,
                                labels: auxMonths
                            })
                        }
                })

                if(auxAnys.length == result.anys.length)
                {
                    auxFilters.push({
                        title: "Anys",
                        secondLevel: auxAnys.sort(function(a, b) {
                            if(a.secondarytitle.toLowerCase() < b.secondarytitle.toLowerCase()) return 1;
                            if(a.secondarytitle.toLowerCase() > b.secondarytitle.toLowerCase()) return -1;
                            return 0;
                        })
                    })
                }
                
                setSearchFilters(auxFilters);
                setLoadingFilters(false);
            });
            })
            .catch((error: any) => {
                console.log(error);
                setLoadingFilters(false);
            })

            var body = {
                sizePage: itemsPerPage,
                datesList: [],
                skipToken: null,
                anys: [],
                numButlleti: null
            }

            setBodyFilters(body);
            props.apiService.GetNewPaginatedNewsletter(body)
                .then(async (result) => {
                    let docsAux: any[] = [];
                    result.bopaList.map((doc: any) => {
                        docsAux.push({
                            number: doc.numBOPA,
                            date: moment(doc.dataPublicacio).format('DD/MM/YYYY'),
                            extra: doc.isExtra != null ? doc.isExtra : false,
                            docName: doc.numBOPA,
                            display: true,
                            any: moment(doc.dataPublicacio).format('YYYY'),
                        })
                    })

                    setDocuments(docsAux)
                    setSkipToken(result.skipToken)
                    setTimeout(()=> {
                        window.scrollTo({
                            top: scrollPosition,
                            behavior: 'smooth',
                        });
                    }, 500)
                    
                })
                .catch((error: any) => {
                    console.log(error);
                    setLoadingButlletins(false);
                    setLoadingFilters(false);
                })
      }, []);

    return (
        <div className={styles.container}>
            <div className="top-to-btm">
                {" "}
                {showTopBtn && (
                    <BsArrowUpCircle
                        className="icon-position icon-style"
                        onClick={goToTop}
                    />
                )}{" "}
            </div>
            <div className='margin_top'></div>
            <div className='container col-12'>
                <h1>Butlletins</h1>
                <div className="row">
                    <div className='col-xl-4'>
                        <div className={styles.container_filtres}>

                        <div className={styles.container_btn_filtres}>
                                <BtnPrimary
                                    text= {'Filtres'}
                                    iconLeft= {<BiFilterAlt/>}
                                />
                            </div>

                            <Form id={"filterFormButlletins"} onSubmit={handleSubmit} role="form">
                                <div className={styles.content_filtres_header}>
                                </div> 
                                <div className={styles.content_filtres_header}>
                                    <div className={styles.p2}>Filtres</div>
                                    <div className={styles.content_filtres_header_btn}>
                                        <BtnAsLink
                                            name="cleaner"
                                            text='Netejar'
                                            type='submit'
                                        />
                                        <div className='mx-3'></div>
                                        <BtnPrimary
                                            name="submitter"
                                            type="submit"
                                            text='Cerca '
                                            iconRight={<BsArrowRight/>}
                                        />
                                    </div>
                                </div>
                                <div className={styles.content_filtres_form}>
                                    <FiltresButlletins
                                        onEnter={onInputChange}
                                        year={year} 
                                        changeYear={changeYearValue}
                                        buttleti={butlletiValue} changeButlleti={changeButlletiValue}
                                        // initDate={initDateValue} changeInitDate={changeInitDateValue}
                                        // endDate={endDateValue} changeEndDate={changeEndDateValue} 
                                        />
                                </div>

                                <div id="accordionFilters">
                                    <Accordion id="filtresAccordion" activeKey={activeKey} onSelect={handleSelect} className={styles.accordionStyle}>
                                        <Accordion.Item eventKey="Anys" >
                                            <Accordion.Header>
                                                <div className={styles.body_medium}>Anys</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                {
                                                    loadingFilters == true ?
                                                    <div className="app-spinner">
                                                        <img width={50} src={loadingLogo} alt="loading..." />
                                                    </div> 
                                                    :
                                                    <AccordionFiltres2Years selectedItems={documentWebFilters.anyFilter} year={year} onClickTitle={changeYearValue2} onChange={onFormSubmit} labels={searchFilters[0].secondLevel} primarytitle={searchFilters[0].title}/>
                                                }
                                                </div>
                                            </Accordion.Body>                        
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </Form>


                        </div>
                    </div>

                    <div className='col-xl-8'>

                    <div className={styles.container_main}>
                            {
                                loadingButlletins == false ? 
                                    documentWebFilters.anyFilter.length == 0 && documentWebFilters.dateFilter.length == 0 && butlletiValue == ""
                                    ?
                                    <div className={styles.content_badge}>
                                        <div className={styles.overline_regular}>No s'han aplicat filtres.</div>
                                    </div>
                                    :
                                    <div className={styles.content_badge}>
                                        <div className={styles.overline_regular}>Filtres aplicats</div>
                                        <div className={styles.content_badge_main}>
                                        {
                                            butlletiValue != "" ?
                                            <div className='me-1 mt-1'>
                                                <Badge
                                                    id={'butlleti_' + butlletiValue}
                                                    label= {"Número butlletí: " + butlletiValue}
                                                    deleteItem={deleteItem}
                                                />
                                            </div>
                                            : null
                                        }
                                        {
                                            documentWebFilters.dateFilter.length != 0 ?
                                            documentWebFilters.dateFilter.map((filter: any) => {
                                                return (
                                                    <div className='me-1 mt-1'>
                                                        <Badge
                                                            id={'date_' + filter.filterType + '_' + filter.filterValue}
                                                            label= {(filter.filterType == 'ge' ? "Des de: " : "Fins a: " ) + (filter.filterType == 'ge' ? moment(filter.filterValue).format('DD/MM/YYYY') : moment(filter.filterValue).add(-1,'day').format('DD/MM/YYYY') )}
                                                            deleteItem={deleteItem}
                                                        />
                                                    </div>
                                                )

                                            })
                                            : null
                                        }
                                        {
                                            documentWebFilters.anyFilter.length > 0 ?
                                            documentWebFilters.anyFilter.map((filter: any) => {
                                                return (
                                                    <div className='me-1 mt-1'>
                                                        <Badge
                                                            id={ 'Any_' + moment(filter).format('YYYY_MM')}
                                                            label= {moment(filter).format('MMMM [de] YYYY')}
                                                            deleteItem={deleteItem}
                                                        />
                                                    </div>
                                                )
                                            })                                            
                                            : 
                                            year != 0 ?
                                                <div className='me-1 mt-1'>
                                                    <Badge
                                                        id={year}
                                                        label= {'Any: ' + year}
                                                        deleteItem={()=>{setYear(0)}}
                                                    />
                                                </div>
                                            : null
                                        }
                                        </div>
                                    </div>
                                : null
                            }

                            { loadingButlletins == true ?

                                    <div className={styles.container_main}>
                                        <div className="app-spinner">
                                            <img width={150} src={loadingLogo} alt="loading..." />
                                        </div>
                                    </div>
                                    :
                                    documentsDisplay != null && documentsDisplay.length == 0 ?
                                    <div className={styles.content_mensaje}>
                                        <MsgIlustrationText
                                            ilustration={SinResultats}
                                            text={'No hi ha resultats per mostrar. Canviï els filtres.'}
                                        />
                                    </div>
                                    :
                                    <InfiniteScroll
                                        dataLength={ pageItems }
                                        next={()=> { loadMoreDocs() }}
                                        hasMore={skipToken != null ? true : false}
                                        loader={<div className="app-spinner">
                                                    <img width={50} src={loadingLogo} alt="loading..." />
                                                </div> }
                                        >
                                        {documentsDisplay.map((doc: any)=> {
                                            return (
                                                <div className={styles.content_bopas} onClick={writeCookies}>
                                                    <AccordionButlletin
                                                        apiService={props.apiService}
                                                        filters={documentWebFilters}
                                                        extra= {doc.extra}
                                                        counter={doc.number}
                                                        docName={doc.number + '_' +moment(doc.date.split('/')[2] + doc.date.split('/')[1] + doc.date.split('/')[0]).format('YYYY-MM-DD')}
                                                        title= {moment(doc.date.split('/')[2] + doc.date.split('/')[1] + doc.date.split('/')[0])}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </InfiniteScroll>
                                    
                                }
                            {/* {
                                loadingButlletins == false && documentsDisplay.length > itemsPerPage ?
                                <div className={styles.content_Pagination}>
                                    <Paginations active={pagination} pageItems={pageItems} onClick={changePagination}></Paginations>
                                </div> : null
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Butlletins