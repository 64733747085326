import BtnAsLinkProps from "./BtnAsLinkProps"
import styles from './BtnAsLink.module.sass'
import Button from 'react-bootstrap/Button'
import { Link } from "react-router-dom"



function BtnAsLink(props:BtnAsLinkProps) {
    return (

        <Button name={props.name} className={styles.btn_link} type={props.type}>
            <span>{props.iconLeft}</span>
            {props.text}
            <span>{props.iconRight}</span>
        </Button>
    )
}

export default BtnAsLink