import OutputProps from "./OutputProps"
import styles from './Output.module.sass'



function Output(props:OutputProps) {
    return(
        <>
            <div className={styles.container}>
                <div className={styles.overline_regular}>{props.label}</div>
                <div className={styles.body_regular}>{props.valor}</div>
            </div>
            
        </>
        
    )
}

export default Output