import FooterProps from "./FooterProps"
import styles from './Footer.module.sass'
import { NavLink } from 'react-router-dom'

function Footer() {

    return(
        <div className={styles.container}>
            <div className={styles.options_menu}>
                
            <div>
                <ul className={styles.list}>
                    <li className={styles.item}>
                        <NavLink to="/Contacte">Contacta'ns</NavLink>
                    </li>
                    <li className={styles.item}>
                        <NavLink to="/PublicaBopa">Publica al BOPA</NavLink>
                    </li>
                    <li className={styles.item}>
                        <NavLink to="/PreguntesFrequents">Preguntes freqüents</NavLink>
                    </li>
                    <li className={styles.item}>
                        <NavLink to="/ManualUsuari">Manual d'usuari</NavLink>
                    </li>
                </ul>
            </div>

                <div className={styles.options_menu_right}>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                            <NavLink to="/AvisLegal">Avís legal</NavLink>
                        </li>
                        <li className={styles.item}>
                            <NavLink to="/Accessibilitat">Accessibilitat</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.copywrite}>
                <span>Copyright &copy; {(new Date().getFullYear())} <b>Govern d'Andorra</b></span>
            </div>
        </div>
        
    )
}

export default Footer