import React from 'react';
import { NavLink } from 'react-router-dom';
import { FiExternalLink } from "react-icons/fi";

//SASS//
import styles from "./NavBar.module.sass"

const sidebarNavItems = [
    {
      path: "/",
      display: "Inici",
    },
    {
      path: "/Butlletins",
      display: "Butlletins",
    },
    {
      path: "/Documents",
      display: "Documents",
    },
    {
      path: "/Legislacio",
      display: "Legislació",
    },
    {
      path: "https",
      display: "Portal jurídic"
    }
  ] 

function NavBar(props: any) {
    return(
    <div id={styles.containerSideBarMenu}>
      <nav className={styles.navbar__menu}>
        <ul className={styles.navbar__list}>
        {
          sidebarNavItems.map((item, i) => (
            (item.path.includes("https") ? 
            <li className={styles.nav}
              onClick={props.openMenu}>
                <NavLink 
                to={window.location.pathname}
                onClick={() => {window.open(props.portaljuridicuri, "_blank")}}
                className={styles.navbar__liBox}
                >
                  <div className={styles.item__elements}>
                      <div>{item.display}</div> <FiExternalLink />
                  </div>
                </NavLink>
            </li>
            : 
            <li className={styles.nav}
              onClick={props.openMenu}>
                <NavLink 
                to={item.path} 
                className={({ isActive }) => (isActive ? styles.navbar__liBoxActive : styles.navbar__liBox)}
                >
                  <div className={styles.item__elements}>
                      <div>{item.display}</div>
                  </div>
                </NavLink>
            </li>
            )
            
          ))
          }
        </ul>
      </nav>
    </div>        
    )
}

export default NavBar