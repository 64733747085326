import LineResultsCerquesProps from "./LineResultsCerquesProps"
import styles from './LineResultsCerques.module.sass'
import { BiChevronRight } from 'react-icons/bi'


function LineResultsCerques(props:LineResultsCerquesProps) {
    return(
            <div className={styles.container} onClick={props.onClick}>
                <div className={styles.content}>
                    <div className={styles.title}>{props.title}</div>
                    <div className={styles.description}>{props.description}</div>
                </div>
                <div className={styles.arrow}><BiChevronRight/></div>
            </div>            
    )
}

export default LineResultsCerques