import BtnLoginProps from "./BtnLoginProps"
import styles from './BtnLogin.module.sass'
import Button from 'react-bootstrap/Button'

function BtnLogin(props:BtnLoginProps) {
    return (
            <Button disabled={props.disabled == true ? true : false} variant="primary" className={styles.btn_login} onClick={props.onClick}>
                <span>{props.iconLeft}</span>
                {props.text}
                <span>{props.iconRight}</span>
            </Button>
    )
}

export default BtnLogin