import FiltresButlletinsProps from "./FiltresButlletinsProps"
import styles from './../Forms.module.sass'
import Form from 'react-bootstrap/Form'
import { Col, Container, Row } from "react-bootstrap"
import { DatePicker } from "antd"
import 'dayjs/locale/ca';
import locale from 'antd/es/date-picker/locale/ca_ES';
import moment from "moment"
import { useEffect, useState } from "react"

function FiltresButlletins(props:FiltresButlletinsProps) {
    const [yearOptions, setYearOptions] = useState([] as any);

    useEffect(()=>{
        for (let i = parseInt(moment().format('YYYY')); i >= 1989; i-- ) {
            yearOptions.push(i)
        }
    },[])

    return (
        <>
            {/* <Row>
                <Col>
                    <DatePicker
                        onKeyDown={props.onEnter}
                        id="date"
                        value={props.initDate}
                        onChange={props.changeInitDate}
                        name="initDate"
                        className={styles.form_control} 
                        placeholder="De"
                        format={"DD/MM/YYYY"}
                        locale={locale}
                        allowClear={true}
                        disabledDate={(current) => {
                            return moment("19890101", "YYYYMMDD")  >= current ||
                                 moment()  < current;
                            }}
                        showToday={true}
                    />
                </Col>
                <Col>
                    <DatePicker
                        onKeyDown={props.onEnter}
                        locale={locale}
                        allowClear={true} 
                        id="date"
                        format={"DD/MM/YYYY"}
                        value={props.endDate}
                        onChange={props.changeEndDate}
                        name="endDate"
                        className={ styles.form_control }
                        placeholder="Fins a" 
                        disabledDate={(current) => {
                            return moment("19890101", "YYYYMMDD")  >= current ||
                                 moment()  < current;
                            }}
                        showToday={true}
                        /> 
                </Col>
            </Row>
            <br></br> */}
            <Row>
                <Col>
                    <Form.Control 
                        onKeyDown={props.onEnter}
                        value={props.buttleti}
                        onChange={props.changeButlleti}
                        name="butlletiNumber"
                        className={styles.form_control} 
                        type="text" 
                        placeholder="Número butlletí" 
                        />
                </Col>
                <Col>
                    <Form.Select name="anyNumber" className={styles.form_calendar_item} onChange={props.changeYear} value={props.year}>
                        <option value={0}>Seleccionar any</option>
                        { 
                            yearOptions.map((item: any) => {
                                return(
                                    <option value={item}>{item}</option>
                                )
                            })
                        }
                    </Form.Select>
                </Col>
            </Row>
            <p></p>
        </>
        
    )
}

export default FiltresButlletins