import LegislacioProps from "./LegislacioProps"
import styles from './Legislacio.module.sass'
import '../../styles/global.sass'
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary"
import BtnSecondary from "../../components/layout/buttons/secondary/BtnSecondary"
import Badge from "../../components/layout/badge/Badge"
import MsgIlustrationText from "../../components/layout/mensajes/msg_ilustration_text/MsgIlustrationText"
import SinResultats from './../../assets/ilustrations/sin_resultados.svg'
import CardDocument from "../../components/cards/card_document/CardDocument"
import { BsArrowRight, BsArrowUpCircle, BsGoogle, BsMicrosoft } from "react-icons/bs"
import { BiFilterAlt } from "react-icons/bi"
import { useEffect, useState } from "react"
import { Accordion, Col, Form, Modal, Row } from "react-bootstrap"
import loadingLogo from '../../assets/loading/loading.gif'
import moment from "moment"
import BtnAsLink from "../../components/layout/buttons/BtnAsLink/BtnAsLink"
import AlertWarning from "../../components/Alerts/AlertWarning/AlertWarning"
import { toast } from 'sonner'
import AccordionFiltres2Nivel from "../../components/accordion/accordion_filtres/accordion_filtres_2_nivel/AccordionFiltres2Nivel"
import FiltresDocuments from "../../components/forms/filtres_documents/FiltresDocuments"
import InfiniteScroll from 'react-infinite-scroll-component';

function Legislacio(props: LegislacioProps) {

    var orderByDesc = "DataPublicacioButlleti desc, DataArticle desc,OrganismeOrder,OrganismeChildOrder"
    var orderByAsc = "DataPublicacioButlleti asc, DataArticle asc,OrganismeOrder,OrganismeChildOrder"
    var orderByRelevance = "search.score() desc, DataPublicacioButlleti desc, DataArticle desc,OrganismeOrder,OrganismeChildOrder"
    var orderByProtocol = "OrganismeOrder, OrganismeChildOrder, DataArticle desc"

    const queryParameters = new URLSearchParams(window.location.search)
    const search = queryParameters.get("search");
    const itemTemes = queryParameters.get("itemTemes");
    const initDate = queryParameters.get("initDate");
    const endDate = queryParameters.get("endDate");


    const itemsPerPage = 100;
    const initFilter = {
        textSearch: "",
        temaFilter: [] as any,
        dateFilter: [] as any,
        size: itemsPerPage,
        skip: 0,
        orderBy: orderByDesc,
        searchMode: 1
    };

    const [cookiesReady, setCookiesReady] = useState(false);
    const [hasMore, setHasMore] = useState(true)
    const [textValue, setTextValue] = useState(search);
    const [initDateValue, setInitDateValue] = useState(initDate != null ? initDate :'');
    const [endDateValue, setEndDateValue] = useState(endDate != null ? endDate :'');
    const [searchFilters, setSearchFilters] = useState([] as any[]);
    const [pagination, setPagination] = useState(1);
    const [loadingFilters, setLoadingFilters] = useState(true);
    const [loadingButlletins, setLoadingButlletins] = useState(true);
    const [order, setOrder] = useState(0);
    const [documents, setDocuments] = useState([] as any[]);
    const [itemCount, setItemCount] = useState(0);
    const [documentWebFilters, setdocumentWebFilters] = useState(initFilter);
    const [lawsThemes, setLawsThemes] = useState([] as any[]);
    const [modal, setModal] = useState(false);
    const [modalSave, setModalSave] = useState(false);
    const [ userName, setName] = useState(null as any);
    const [activeKey, setActiveKey] = useState("");
    const [activeKeyTemes, setActiveKeyTemes] = useState("");
    const [showTopBtn, setShowTopBtn] = useState(false);
    const [firstIteration, setFirstIteration] = useState(true);
    const [searchMode, setSearchMode] = useState(1);

    const writeCookies = () => {
        sessionStorage.setItem('lawsFilters', JSON.stringify(documentWebFilters))
        sessionStorage.setItem('positon', window.pageYOffset.toString())
        sessionStorage.setItem('activeKey', activeKey)
        sessionStorage.setItem('activeKeyTemes', activeKeyTemes)

        // document.cookie = "lawsFilters=" + JSON.stringify(documentWebFilters).replace(/.$/, '') +  ', "positon":'  + window.pageYOffset 
        // +  ', "activeKey":"'  + activeKey + '", "activeKeyTemes":"'  + activeKeyTemes + '"}';
    }

    const handleSelect = (eventKey: any) => {
        setActiveKey(eventKey);
    }

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleSelectKeyTemes = (eventKey: any) => {
        setActiveKeyTemes(eventKey);
    }

    const saveFavoriteModal = (event: any) => {
        event.preventDefault();
        if(userName != null) setModalSave(true)
        else setModal(true);
    }

    useEffect(() => { 
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });

        const cookieValue = document.cookie.split(';').find(cookie => cookie.trim().startsWith('clientToken='))?.split('=')[1] || '';
        if(cookieValue != null && cookieValue != "")
        {
            setName("");
        }
        else
        {
            setName(null)
        }
        // axios.get('/.auth/me').then((response: any) => {
        //   setName(response.data[0].user_claims.find((x: any) => x.typ == 'name').val);
        // })
        // .catch((error) => {
        //   setName(null)
        //   console.log(error);
        // });
    }, []);

    const handleOnChangeTextSearchType = (event: any) => {
        let docsAux = documentWebFilters
        docsAux.searchMode = event.target.value;
        setdocumentWebFilters(docsAux)
        setSearchMode(event.target.value);
    }

    const saveFavorite = async (event: any) => {
        event.preventDefault();
        var serialize = require('form-serialize');
        var form = document.querySelector('#saveForm');
        var obj1 = serialize(form);
        if(obj1.includes('title'))
        {
            let variables1 = obj1.split('&');
            let title = "";
            let description = "";
            let active = false;
            variables1.map((item: any)=> {
                let itemName = item.split('=')[0]
                let itemValue = item.split('=')[1]
                if(itemName == 'title') title = itemValue
                else if(itemName == 'description') description = itemValue
                else if(itemName == 'alerts' && itemValue == 'on') active = true
            })

            let url = '';
            var form2 = document.querySelector('#filterForm');
            var obj2 = serialize(form2);
            let variables2 = obj2.split('&');
            variables2.map((item: any) => {
                let itemName = item.split('=')[0]
                let itemValue = item.split('=')[1]
                if(itemName == 'initDate' || itemName == 'endDate')
                {
                    url += '&' + itemName + '=' + itemValue
                }
                else if(itemName == 'textSearch')
                {
                    url += '&search=' + itemValue
                }
                else
                {
                    if(itemValue == 'on')
                    {
                        let itemName2 = itemName.split('_')[0]
                        if(itemName2 == 'Temes')
                        {
                            let itemUriName = '&item' + itemName2 + '='
                            if(url.includes(itemUriName))
                            {
                                url = url.split(itemUriName)[0] + itemUriName + itemName.split('_')[1] + ',' + url.split(itemUriName)[1]
                            }
                            else
                            {
                                url += itemUriName + itemName.split('_')[1] 
                            } 
                        }
                    }
                }
            })
            url = '/Legislacio?' + url.slice(1);

            let search = {
                titol: title.replaceAll("'","''"),
                descripcio: description.replaceAll("'","''"),
                activa: active,
                consulta: url.replaceAll("'","''")
            }
            
            await props.apiService.SaveSearch(search)
            .then(() => {
                setModalSave(false)
                toast.success('Cerca desada correctament')
            })
            .catch(() => {
                setModalSave(false)
                toast.error('Error al desar la cerca.')
            })
        }      
    }

    const changeTextValue = (event: any) => {
        setTextValue(event.target.value)
    }

    useEffect(()=> {
        let event: any = null;
        onFormSubmit(event)
    }, [initDate])

    useEffect(()=> {
        let event: any = null;
        onFormSubmit(event)
    }, [endDate])


    const changeInitDateValue = (event: any) => {
        if(event != null) setInitDateValue(moment(event).format('DD/MM/YYYY'))
        else setInitDateValue("")
        
        setTimeout(() => {
            onFormSubmit(event)
        }, 500);     
    }

    const changeEndDateValue = (event: any) => {
        if(event != null) setEndDateValue(moment(event).format('DD/MM/YYYY'))
        else setEndDateValue("")

        setTimeout(() => {
            onFormSubmit(event)
        }, 500);     
    }

    // const changeInitDateValue = (event: any) => {
    //     setInitDateValue(moment(event).format('DD/MM/YYYY'))
    //     setTimeout(() => {
    //         onFormSubmit(event)
    //     }, 500);
    // }

    // const changeEndDateValue = (event: any) => {
    //     setEndDateValue(moment(event).format('DD/MM/YYYY'))
    //     setTimeout(() => {
    //         onFormSubmit(event)
    //     }, 500);
    // }

    const deleteItem = (event: any) => {
        setLoadingButlletins(true)
        if(event.split('_')[0] == 'textSearch')
        {
            setTextValue('');
            documentWebFilters.textSearch = ''
        }
        else if(event.split('_')[0] == 'date')
        {
            if(event.split('_')[1] == 'ge')
            {
                setInitDateValue('');
                documentWebFilters.dateFilter = documentWebFilters.dateFilter.filter((obj: any) => {
                    return(obj.filterType != 'ge')
                });
            }
            else if(event.split('_')[1] == 'le')
            {
                setEndDateValue('');
                documentWebFilters.dateFilter = documentWebFilters.dateFilter.filter((obj: any) => {
                    return(obj.filterType != 'le')
                });
            }
        }
        else 
        {
            let elements = document.getElementsByName(decodeURI(event));
            elements.forEach((obj: any) => {
                obj.checked = false;
            })

            if(event.split('_')[0] == 'Temes')
            {
                documentWebFilters.temaFilter = documentWebFilters.temaFilter.filter((obj: any) => {
                    return(obj.filterValue != event.split('_')[1])
                });
            }          
        }
        setdocumentWebFilters(documentWebFilters);
        getPaginatedLaws(documentWebFilters, lawsThemes);
    }

    const clearFilters = (event: any) => {
        event.target.reset();
        sessionStorage.setItem('lawsFilters', JSON.stringify(initFilter))
        sessionStorage.setItem('positon', '0')
        sessionStorage.setItem('activeKey', '')
        sessionStorage.setItem('activeKeyTemes', '')
        // document.cookie = `lawsFilters=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        setHasMore(true)
        setActiveKey("");
        setActiveKeyTemes("");
        setTextValue("");
        setInitDateValue("");
        setEndDateValue("");
        setSearchMode(1);
        setdocumentWebFilters(initFilter);
        getPaginatedLaws(initFilter, lawsThemes);
    }

    const onInputChange = (event: any) => {
        if(event.code == "Enter") {
            event.preventDefault();
            onFormSubmit(event);  
          }
    }

    const changeOrder = (event: any) => {
        setLoadingButlletins(true);
        setOrder(event.target.value);
        let documentWebFiltersAux = documentWebFilters;
        if(event.target.value == 0) documentWebFiltersAux.orderBy = orderByRelevance
        if(event.target.value == 1) documentWebFiltersAux.orderBy = orderByDesc
        if(event.target.value == 2) documentWebFiltersAux.orderBy = orderByAsc
        setdocumentWebFilters(documentWebFiltersAux);
        getPaginatedLaws(documentWebFilters, lawsThemes, event.target.value);
    }

    const handleSubmit  = (event: any) => {
        event.preventDefault();
        if(event.nativeEvent.submitter.name  == "cleaner")
        {
            clearFilters(event);
        } else if (event.nativeEvent.submitter.name  == "submitter") {
            onFormSubmit(event);
        }
        else if (event.nativeEvent.submitter.name  == "saver") {
            saveFavoriteModal(event);
        }
    }


    const fetchData = () => {
        if(firstIteration == false) {
            documentWebFilters.skip = documentWebFilters.skip + itemsPerPage;
            let docsFilters = JSON.parse(JSON.stringify(documentWebFilters));
            if(docsFilters.temaFilter.length == 0)
            {
                lawsThemes.map((theme: any) => {
                    theme.map((themeItem: any) => {
                        docsFilters.temaFilter.push({filterType: 'eq', filterValue: themeItem.guid})
                    })                
                })
            }

            props.apiService.GetPaginatedDocuments(docsFilters)
            .then(async (result) => {
                console.log(result)
                if(result.paginatedDocuments.length == 0)
                {
                    setLoadingButlletins(false);
                    setHasMore(false)
                }
                else {
                    let i = 0;
                    let docsAux: any[] = [];
                    setItemCount(result.totalCount);
                    result.paginatedDocuments.map((doc: any) => {
                        let tags = [];
                        // Llógica de tags
                        let numOrganisme = parseInt(doc.document.organismePare.split('.')[0])
                        if(numOrganisme != 18 && numOrganisme!= 17) {
                            if (doc.document.organismePare != null) tags.push(doc.document.organismePare.includes('.') ? doc.document.organismePare.split('.')[1].trim() : doc.document.organismePare)
                            if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
                            if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
                        } else {
                            if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
                            if (doc.document.temaPare != null) tags.push(doc.document.temaPare.includes('.') ? doc.document.temaPare.split('.')[1].trim() : doc.document.temaPare)
                            if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
                        }
                        tags = Array.from(new Set(tags));
                        
                        let summary = '-'
                        if(doc.document.sumari != null)
                        {
                            summary = decodeURIComponent(doc.document.sumari)
                            summary = summary.replaceAll('+', ' ')
                        }

                        let dataPublicacio = moment(doc.document.dataPublicacioButlleti).format('DD/MM/YYYY')
                        // Revisar si el BOPA es anterior al 08/06/2023
                        if(moment(doc.document.dataPublicacioButlleti).diff('2023-06-08T00:00:00.000Z', 'minutes') < 0)
                        {
                            dataPublicacio = moment(doc.document.dataPublicacioButlleti).add(1,'day').format('DD/MM/YYYY')
                        }    
                        if(!documents.some((docAux) => docAux.docName == doc.document.nomDocument )) { 
                            docsAux.push({
                                number: parseInt(doc.document.numButlleti),
                                date: dataPublicacio,
                                extra: doc.document.isExtra != null ? doc.document.isExtra : false,
                                summary: summary,
                                tags: tags,
                                docName: doc.document.nomDocument != null ? doc.document.nomDocument : ''
                            })
                        }
                        i++;
                    })
                    
                    if(i == result.paginatedDocuments.length)
                    {
                        setDocuments(oldArray => [...oldArray, ...docsAux]);
                        setTimeout(() => {
                            setLoadingButlletins(false);
                        }, 1000);
                    }  
                }
            })
            .catch((error: any) => {
                console.log(error);
                setLoadingButlletins(false);
            })       
        }
    }

    const getPaginatedLaws = async (documentWebFiltersParameter: any, lawThemes: any, orderAux?: any) => {

        let docsFilters = JSON.parse(JSON.stringify(documentWebFiltersParameter));
        if(docsFilters.skip > documents.length) 
        {
            docsFilters.size = docsFilters.skip
            docsFilters.skip = 0           
        }
        if(docsFilters.temaFilter.length == 0)
        {
            lawThemes.map((theme: any) => {
                theme.map((themeItem: any) => {
                    docsFilters.temaFilter.push({filterType: 'eq', filterValue: themeItem.guid})
                })                
            })
        }

        if(orderAux == null) {
            if(order == 0) docsFilters.orderBy = orderByRelevance
            if(order == 1) docsFilters.orderBy = orderByDesc
            if(order == 2) docsFilters.orderBy = orderByAsc
        }

        props.apiService.GetPaginatedDocuments(docsFilters)
        .then(async (result) => {
            let i = 0;
            let docsAux: any[] = [];
            setItemCount(result.totalCount);
            result.paginatedDocuments.map((doc: any) => {
                let tags = [];
                // Llógica de tags
                let numOrganisme = parseInt(doc.document.organismePare.split('.')[0])
                if(numOrganisme != 18 && numOrganisme!= 17) {
                    if (doc.document.organismePare != null) tags.push(doc.document.organismePare.includes('.') ? doc.document.organismePare.split('.')[1].trim() : doc.document.organismePare)
                    if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
                    if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
                } else {
                    if (doc.document.organisme != null) tags.push(doc.document.organisme.includes('.') ? doc.document.organisme.split('.')[1].trim() : doc.document.organisme)
                    if (doc.document.temaPare != null) tags.push(doc.document.temaPare.includes('.') ? doc.document.temaPare.split('.')[1].trim() : doc.document.temaPare)
                    if (doc.document.tema != null) tags.push(doc.document.tema.includes('.') ? doc.document.tema.split('.')[1].trim() : doc.document.tema)
                }
                tags = Array.from(new Set(tags));
                
                let summary = '-'
                if(doc.document.sumari != null)
                {
                    summary = decodeURIComponent(doc.document.sumari)
                    summary = summary.replaceAll('+', ' ')
                }

                let dataPublicacio = moment(doc.document.dataPublicacioButlleti).format('DD/MM/YYYY')
                // Revisar si el BOPA es anterior al 08/06/2023
                if(moment(doc.document.dataPublicacioButlleti).diff('2023-06-08T00:00:00.000Z', 'minutes') < 0)
                {
                    dataPublicacio = moment(doc.document.dataPublicacioButlleti).add(1,'day').format('DD/MM/YYYY')
                }   

                docsAux.push({
                    number: parseInt(doc.document.numButlleti),
                    date: dataPublicacio,
                    extra: doc.document.isExtra != null ? doc.document.isExtra : false,
                    summary: summary,
                    tags: tags,
                    docName: doc.document.nomDocument != null ? doc.document.nomDocument : ''
                })
                
                i++;
            })
            
            if(i == result.paginatedDocuments.length)
            {
                setDocuments(docsAux);
                setTimeout(() => {
                    setLoadingButlletins(false);
                    setFirstIteration(false)
                }, 500);
            }  
        })
        .catch((error: any) => {
            console.log(error);
            setLoadingButlletins(false);
        })
    }

const onFormSubmit = (event: any) => {
        setLoadingButlletins(true)
        setHasMore(true)
        var serialize = require('form-serialize');
        var form = document.querySelector('#filterForm');
        var obj = serialize(form);
        let variables = obj.split('&');
        let documentWebFiltersAux = initFilter;

        variables.map((item: any) => {
            let key = item.split('=')[0];
            let value = item.split('=')[1];
            if(key == 'textSearch') documentWebFiltersAux.textSearch = textValue || ''
            else if(key == 'initDate') {
                value = moment(value.replaceAll('%2F','/'), "DD/MM/YYYY").format('YYYY-MM-DD')
                let datevalue = documentWebFiltersAux.dateFilter.find((obj: any) => {
                    return obj.filterType === 'ge';
                  });
                  if(datevalue == undefined) documentWebFiltersAux.dateFilter.push({filterType: 'ge', filterValue: value})
                  else if (moment(value).isBefore(datevalue.filterValue)) value.filterValue = value;
            }
            else if(key == 'endDate') {
                value = moment(value.replaceAll('%2F','/'), "DD/MM/YYYY").add(1,'day').format('YYYY-MM-DD')
                let datevalue = documentWebFiltersAux.dateFilter.find((obj: any) => {
                    return obj.filterType === 'le';
                  });
                if(datevalue == undefined) documentWebFiltersAux.dateFilter.push({filterType: 'le', filterValue: value})
                else if (moment(value).isAfter(datevalue.filterValue)) value.filterValue = value;
            }
            else if(value == 'on' && key.split('_')[0] =="Temes") {
                documentWebFiltersAux.temaFilter.push({
                    filterType: 'eq', 
                    filterValue: (key.split('_')[1]),
                    filterName: document.getElementsByName(key)[0].id
                })
            }
        })
        documentWebFiltersAux.searchMode = searchMode;
        setdocumentWebFilters(documentWebFiltersAux);
        getPaginatedLaws(documentWebFiltersAux, lawsThemes);
    }

    useEffect(() => {
        let scrollPosition = 0;

        const cookieValue = sessionStorage.getItem('lawsFilters') || ''
        const cookieScroll = sessionStorage.getItem('positon') || '0';
        const cookieActiveKey = sessionStorage.getItem('activeKey') || '';
        const cookieActiveKeyTemes = sessionStorage.getItem('activeKeyTemes') || '';

        // const cookieValue = document.cookie.split(';').find(cookie => cookie.trim().startsWith('lawsFilters='))?.split('=')[1] || '';
        // document.cookie = `lawsFilters=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        sessionStorage.setItem('lawsFilters', JSON.stringify(initFilter))
        sessionStorage.setItem('positon', '0')
        sessionStorage.setItem('activeKey', '')
        sessionStorage.setItem('activeKeyTemes', '')

            if(cookieValue != '') {
                let documentWebFiltersAux = JSON.parse(JSON.stringify(documentWebFilters));
                let cookieData = JSON.parse(cookieValue)
                documentWebFiltersAux.textSearch = cookieData.textSearch
                documentWebFiltersAux.temaFilter = cookieData.temaFilter
                documentWebFiltersAux.dateFilter = cookieData.dateFilter
                documentWebFiltersAux.size = cookieData.size
                documentWebFiltersAux.skip = cookieData.skip
                scrollPosition = parseInt(cookieScroll)
                // scrollPosition = cookieData.positon;
                setdocumentWebFilters(documentWebFiltersAux)
                setTimeout(() => setdocumentWebFilters(documentWebFiltersAux), 500)
                setTextValue(decodeURIComponent(cookieData.textSearch).replaceAll('+',' '))
                cookieData.dateFilter.map((anyItem: any) => {
                    if(anyItem.filterType == 'le')
                    {
                        setEndDateValue(moment(anyItem.filterValue).format('DD/MM/YYYY'))
                    }
                    else if(anyItem.filterType == 'ge')
                    {
                        setInitDateValue(moment(anyItem.filterValue).format('DD/MM/YYYY'))
                    }
                })
                setActiveKey(cookieActiveKey)
                setActiveKeyTemes(cookieActiveKeyTemes)         

                setCookiesReady(true)
                setTimeout(()=> {
                    window.scrollTo({
                        top: scrollPosition,
                        behavior: 'smooth',
                    });
                }, 3000)
            }
            else
            {
                setCookiesReady(true)
            }
      }, []);

    useEffect(() => {
        if(cookiesReady == true)
        {
            props.apiService.GetFiltersLaws()
            .then((result) => {
                let auxFilters: any[] = [];
                let auxTemes: any[] = [];   
                let auxLabels: any[] = [];      

                result.temes.forEach((tema: any) => {
                    auxTemes.push({
                        secondarytitle: tema.temaPare,
                        labels: tema.temes
                    })
                    auxLabels.push(tema.temes)

                    if(auxTemes.length == result.temes.length)
                    {
                        auxFilters.push({
                            title: "Temes",
                            secondLevel: auxTemes.sort(function(a, b) {
                                if(a.secondarytitle.toLowerCase() < b.secondarytitle.toLowerCase()) return -1;
                                if(a.secondarytitle.toLowerCase() > b.secondarytitle.toLowerCase()) return 1;
                                return 0;
                            })
                        })
                    }
                });       

                setLawsThemes(auxLabels);
                setSearchFilters(auxFilters);
                setLoadingFilters(false);
                setTimeout(()=> {
                    if(search != null && search != "") documentWebFilters.textSearch = search.replaceAll(' ', '+')
                    if(initDate != null && initDate != "") documentWebFilters.dateFilter.push({filterType: 'ge', filterValue: initDate})
                    if(endDate != null && endDate != "") documentWebFilters.dateFilter.push({filterType: 'le', filterValue: endDate})
                    if(itemTemes != null && itemTemes != "")
                    {
                        let items = itemTemes.split(',');
                        items.map((item: any) => {
                            let elements = document.getElementsByName('Temes_' + item);
                                elements.forEach((obj: any) => {
                                    obj.checked = true;
                                })

                            documentWebFilters.temaFilter.push({
                                filterType: 'eq', 
                                filterValue: item
                            })
                        })
                    }
                    if(documentWebFilters.temaFilter.length > 0)
                    {
                        documentWebFilters.temaFilter.map((item: any) => {
                            let elements = document.getElementsByName('Temes_' + item.filterValue);
                                elements.forEach((obj: any) => {
                                    obj.checked = true;
                                })
                        })
                    }
                    if(auxLabels.length > 0) getPaginatedLaws(documentWebFilters, auxLabels);
                }, 200)
            })
            .catch((error) => {
                console.log(error);
            })
        }
      }, [cookiesReady]);


    const changePagination = (item: number) => {
        setLoadingButlletins(true);
        let documentWebFiltersAux = documentWebFilters;
        documentWebFiltersAux.skip = (item - 1)*itemsPerPage;
        setdocumentWebFilters(documentWebFiltersAux);
        getPaginatedLaws(documentWebFiltersAux, lawsThemes);
        setPagination(item);
    }
    
    return (
        <div className={styles.container}>
            <div className="top-to-btm">
                {" "}
                {showTopBtn && (
                    <BsArrowUpCircle
                        className="icon-position icon-style"
                        onClick={goToTop}
                    />
                )}{" "}
            </div>
            <div className='margin_top'></div>
            <div className='container col-12'>
                <h1>Legislació</h1>
                <div className="row">
                    <div className='col-xl-4'>
                        <div className={styles.container_filtres}>

                        <div className={styles.container_btn_filtres}>
                                <BtnPrimary
                                text= {'Filtres'}
                                iconLeft= {<BiFilterAlt/>}
                                />
                            </div>

                            <Modal show={modalSave} onHide={() => {setModalSave(false)}}>
                                    <Form id={"saveForm"}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Desar cerca</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                                <Form.Label>Títol <span style={{color: 'red'}}>*</span></Form.Label>
                                                <Form.Control 
                                                    name="title"
                                                    className={styles.form_control} 
                                                    type="text" 
                                                    placeholder="Insereix"
                                                    required /> 
                                                <p></p>
                                                <Form.Label>Descripció</Form.Label>
                                                <Form.Control 
                                                    name="description"
                                                    className={styles.form_control} 
                                                    as="textarea" 
                                                    rows={3}
                                                    placeholder="Insereix" /> 
                                                <p></p>
                                                <Form.Check 
                                                    label={"Vols rebre alertes d'aquesta cerca?"}
                                                    name="alerts"
                                                />                                            
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <BtnPrimary text="Desar cerca " type="submit" iconRight={<BsArrowRight />} onClick={saveFavorite} />
                                        </Modal.Footer>
                                    </Form>
                                </Modal>

                                <Modal show={modal} onHide={() => {setModal(false)}}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Desar cerca</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AlertWarning message="Has d'accedir a la plataforma per poder desar la cerca als teus favorits." />
                                        {/* <BtnLogin onClick={() => {window.open(process.env.REACT_APP_GOOGLE_REDIRECT, "_self")}} iconLeft={<BsGoogle size="20"/>} text={" Google"}/>
                                        <p></p>
                                        <BtnLogin onClick={() => {window.open(process.env.REACT_APP_MICROSOFT_REDIRECT, "_self")}} iconLeft={<BsMicrosoft size="20"/>} text={" Microsoft"}/> */}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <BtnSecondary text="Sortir " iconRight={<BsArrowRight />} onClick={() => {setModal(false)}} />
                                    </Modal.Footer>
                                </Modal>

                            <Form id={"filterForm"} onSubmit={handleSubmit} role="form">
                                <div className={styles.content_filtres_header}>
                                    <div className={styles.p2}>Filtres</div>
                                    <div className={styles.content_filtres_header_btn}>
                                        <BtnAsLink
                                            name="cleaner"
                                            text='Netejar'
                                            type='submit'
                                        />
                                        <div className='mx-3'>
                                            <BtnSecondary
                                                name="saver"
                                                type='submit'
                                                text='Desa'
                                            />
                                        </div>
                                        <BtnPrimary
                                            name="submitter"
                                            type="submit"
                                            text='Cerca '
                                            iconRight={<BsArrowRight/>}
                                        />
                                    </div>
                                </div>

                                <Modal show={modal} onHide={() => {setModal(false)}}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Desar com a favorit</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AlertWarning message="Has d'accedir a la plataforma per poder desar la cerca als teus favorits." />
                                        {/* <BtnLogin onClick={() => {window.open(process.env.REACT_APP_GOOGLE_REDIRECT, "_self")}} iconLeft={<BsGoogle size="20"/>} text={" Google"}/>
                                        <p></p>
                                        <BtnLogin onClick={() => {window.open(process.env.REACT_APP_MICROSOFT_REDIRECT, "_self")}} iconLeft={<BsMicrosoft size="20"/>} text={" Microsoft"}/> */}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <BtnSecondary text="Sortir " iconRight={<BsArrowRight />} onClick={() => {setModal(false)}} />
                                    </Modal.Footer>
                                </Modal>
                            
                                <div className={styles.content_filtres_form}>
                                <FiltresDocuments 
                                    handleOnChangeTextSearchType={handleOnChangeTextSearchType}
                                    searchMode={searchMode}
                                    onEnter={onInputChange}
                                    text={textValue} changeText={changeTextValue}
                                    initDate={initDateValue} changeInitDate={changeInitDateValue}
                                    endDate={endDateValue} changeEndDate={changeEndDateValue} />
                                </div>
                                <div id="accordionFilters">
                                    <Accordion id="filtresAccordion" activeKey={activeKey} onSelect={handleSelect} flush /* onClickCapture={(event: any)=>{if(event.target.textContent != activeKey) {setActiveKey(event.target.textContent)} else {setActiveKey("0")}}} activeKey={activeKey} */ className={styles.accordionStyle}>
                                        <Accordion.Item eventKey="Temes">
                                            <Accordion.Header>
                                                <div className={styles.body_medium}>Temes</div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                {
                                                    loadingFilters == true ?
                                                    <div className="app-spinner">
                                                        <img width={50} src={loadingLogo} alt="loading..." />
                                                    </div> 
                                                    :
                                                    <AccordionFiltres2Nivel 
                                                        activeKey={activeKeyTemes}
                                                        handleSelect={handleSelectKeyTemes}
                                                        onChange={onFormSubmit} 
                                                        labels={searchFilters[0].secondLevel} 
                                                        primarytitle={searchFilters[0].title}/>
                                                }
                                                </div>
                                            </Accordion.Body>                        
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                {/* {
                                    loadingFilters == true ?
                                    <div className="app-spinner">
                                        <img width={100} src={loadingLogo} alt="loading..." />
                                    </div> 
                                    :
                                    searchFilters.map((item: any) => {
                                        return (
                                            <div className={styles.content_filtres_accordion}>
                                                <AccordionFiltres
                                                    onChange = { onFormSubmit }
                                                    primarytitle = { item.title }
                                                    secondLevel = { item.secondLevel }
                                                />
                                            </div>
                                        )
                                    })
                                } */}

                                {/* <div className={styles.content_filtres_footer}>
                                    <div className={styles.p2}></div>
                                    <div className={styles.content_filtres_footer_btn}>
                                        <BtnAsLink
                                            name="cleaner"
                                            text='Netejar'
                                            type='submit'
                                        />
                                        <div className='mx-3'>
                                            <BtnSecondary
                                                name="saver"
                                                type='submit'
                                                text='Desa'
                                            />
                                        </div>
                                        <BtnPrimary
                                            name="submitter"
                                            type="submit"
                                            text='Cerca '
                                            iconRight={<BsArrowRight/>}
                                        />
                                    </div>
                                </div> */}
                            </Form>
                            

                        </div>
                    </div>

                    <div className='col-xl-8'>

                    <div className={styles.container_main}>
                    <div className={styles.content_header}>
                                <div className={styles.p1}>Resultats</div>
                                <div>
                                <Row>
                                    <Col>
                                        <Form>
                                            <Form.Select className={styles.form_control} onChange={changeOrder} value={order}>
                                                <option value={0}>Per rellevància</option>
                                                <option value={1}>Per data (més nou a més antic)</option>
                                                <option value={2}>Per data (més antic a més nou)</option>
                                            </Form.Select> 
                                        </Form>
                                    </Col>
                                </Row>
                                </div>
                            </div> 

                            {
                                loadingButlletins == false && loadingFilters == false ?

                                documentWebFilters.temaFilter.length == 0 && documentWebFilters.textSearch == "" && documentWebFilters.dateFilter.length == 0 
                                ?
                                <div className={styles.content_badge}>
                                    <div className={styles.overline_regular}>No s'han aplicat filtres.</div>
                                </div>
                                :
                                <div className={styles.content_badge}>
                                    <div className={styles.overline_regular}>Filtres aplicats</div>
                                    <div className={styles.content_badge_main}>
                                    {
                                        documentWebFilters.textSearch != "" ?
                                        <div className='me-1 mt-1'>
                                            <Badge
                                                id={'textSearch_' + documentWebFilters.textSearch}
                                                label= {"Que contingui: " + decodeURIComponent(documentWebFilters.textSearch.replaceAll('+', ' '))}
                                                deleteItem={deleteItem}
                                            />
                                        </div>
                                        : null 
                                    }
                                    {
                                        documentWebFilters.dateFilter.length != 0 ? 
                                        documentWebFilters.dateFilter.map((filter: any) => {
                                            return (
                                                <div className='me-1 mt-1'>
                                                    <Badge
                                                        id={'date_' + filter.filterType + '_' + filter.filterValue}
                                                        label= {(filter.filterType == 'ge' ? "Des de: " : "Fins a: " ) + (filter.filterType == 'ge' ? moment(filter.filterValue).format('DD/MM/YYYY') : moment(filter.filterValue).add(-1,'day').format('DD/MM/YYYY') )}
                                                        deleteItem={deleteItem}
                                                    />
                                                </div>
                                            )
                                            
                                        }) 
                                        : null 
                                    }
                                    {
                                        documentWebFilters.temaFilter.length != 0 ?
                                        documentWebFilters.temaFilter.map((filter: any) => {
                                            let labelAux = document.getElementsByName('Temes_' + filter.filterValue)[0];
                                            if(labelAux != undefined)
                                            {
                                                let label = labelAux.id.split('_')[1];
                                                return (
                                                    <div className='me-1 mt-1'>
                                                        <Badge
                                                            id={'Temes_' + filter.filterValue}
                                                            label= {"Tema: " + label}
                                                            deleteItem={deleteItem}
                                                        />
                                                    </div>
                                                )
                                            }
                                        })                                         
                                        : null 
                                    }
                                    </div>
                                </div>
                                : null
                            }
                            

                        {
                            loadingButlletins == true ?
                            <div className={styles.container_main}>
                                <div className="app-spinner">
                                    <img width={150} src={loadingLogo} alt="loading..." />
                                </div>
                            </div>
                            :
                            
                                    documents != null && documents.length == 0 ?
                                    <div className={styles.content_mensaje}>
                                        <MsgIlustrationText
                                            ilustration={SinResultats}
                                            text={'No hi ha resultats per mostrar. Canviï els filtres.'}
                                        />
                                    </div>
                                    :
                                    <InfiniteScroll
                                        dataLength={documents.length} //This is important field to render the next data
                                        next={fetchData}
                                        hasMore={hasMore}
                                        loader={<div className="app-spinner">
                                                    <img width={50} src={loadingLogo} alt="loading..." />
                                                </div> }
                                        endMessage={
                                            <p style={{ textAlign: 'center' }}>
                                            </p>
                                        }
                                        >
                                        {   documents.map((doc: any)=> {
                                            return (
                                                <div className="mt-3" onClick={writeCookies}>
                                                    <CardDocument
                                                        counter={doc.number}
                                                        date={doc.date}
                                                        extra={doc.extra}
                                                        sumari={doc.summary}
                                                        tags={doc.tags}
                                                        path={'/Legislacio/Detall?doc=' + doc.docName}
                                                        docName={doc.docName}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </InfiniteScroll>
                                }
                                {/* {
                                    loadingButlletins == false && itemCount > itemsPerPage ?
                                    <div className={styles.content_Pagination}>
                                        <Paginations active={pagination} pageItems={Math.ceil(itemCount/itemsPerPage)} onClick={changePagination}></Paginations>
                                    </div> : null
                                } */}
                        </div> 

                    </div>

                </div>    
            </div>
        </div>                                      
    )
}

export default Legislacio