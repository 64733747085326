import GestionarCerquesProps from "./GestionarCerquesProps"
import styles from './GestionarCerques.module.sass'
import '../../styles/global.sass'
import ButtonIconStandard from "../../components/layout/buttons/button_icon_standard/ButtonIconStandard"
import { BiShow } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";
import ButtonIconDanger from "../../components/layout/buttons/button_icon_danger/ButtonIconDanger";
import TableMenu from "../../components/TableMenu/TableMenu";
import Form from 'react-bootstrap/Form';
import loadingLogo from '../../assets/loading/loading.gif'
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import AlertWarning from "../../components/Alerts/AlertWarning/AlertWarning";
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary";
import { toast } from 'sonner'

function GestionarCerques(props: GestionarCerquesProps) {

    const [searches, setSearches] = useState([] as any[]);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [itemDelete, setItemDelete] = useState(0);

    const GetSearches = async () => {
        await props.apiService.GetSearches()
        .then((result: any) => {
            setSearches(result)
            setLoading(false)
        })
        .catch((error)=> {
            console.log(error)
            setLoading(false)
        })
    }

    useEffect(()=> {
        GetSearches()
    },[])

    const updateSearch = async (event: any) => {
        let active = 1;
        if(event.target.checked == false) active = 0

        await props.apiService.UpdateSearch(event.target.name, active)
        .then(() => {
            if(active == 1) toast.success("S'han activat les alertes de la cerca")
            else toast.success("S'han desactivat les alertes de la cerca")
        })
        .catch((error)=> {
            toast.error("La cerca no s'ha pogut modificar")
            console.log(error)
        })
    }
    
    const deleteSearch = async () => {
        await props.apiService.DeleteSearch(itemDelete)
        .then(()=> {
            toast.success("La cerca s'ha suprimit correctament")
            setLoading(true)
            setModal(false)
            GetSearches()
        })
        .catch(()=> {
            toast.error("La cerca no s'ha pogut suprimir")
            setModal(false)
        })
    }

    const columns: any[] = [
        {
            title: 'Títol',
            dataIndex: 'titol',
            key:'titol',
            render: (titol: any) => {
                return (
                    <div>{decodeURI(titol.replaceAll('+',' '))}</div>
                )                
            }            
        },
        {
            title: 'Descripció',
            dataIndex: 'descripcio',
            key:'descripcio',
            render: (descripcio: any) => {
                return (
                    <div>{decodeURI(descripcio.replaceAll('+',' '))}</div>
                )                
            }               
        },
        {
            title: 'Alerta',
            dataIndex: 'activa',
            width: '150px',
            key:'activa',
            render: (activa: any, item: any) => {
                return (
                    <Form.Check
                        defaultChecked={activa}
                        type={'checkbox'}
                        name={item.idCerca}
                        onChange={updateSearch}
                    />
                )                
            }             
        },
        {
            title: '',
            dataIndex: 'idCerca',
            width: '10px',
            render: (idCerca: any) => {
                return (
                    <ButtonIconDanger
                        icon={<BiTrash size="18"/>}
                        onClick={()=>{
                            setItemDelete(idCerca);
                            setModal(true);
                        }}
                    />
                )                
            }
        },
        {
            title: '',
            dataIndex: 'consulta',
            width: '10px',
            render: (consulta: any) => {
                return (
                    <ButtonIconStandard
                        icon={<BiShow size="18"/>}
                        onClick={()=>{window.open(consulta, '_self')}}
                    />
                )                
            }
        }
    ];

    return (
        <div className={styles.container}>
            <div className='margin_top'></div>
            <div className='container'>
                <h1>Les meves cerques</h1>
                <div className={styles.caption_regular}>
                    Guarda els criteris de cerca perquè en un sol clic puguis tenir la informació que t'interessa, si vols rebre una alerta fes un clic al quadrat.
                </div>
                <p></p>
                { loading == false ? <TableMenu columns={columns} resourceData={searches} /> : 
                    <div className="app-spinner">
                        <img width={100} src={loadingLogo} alt="loading..." />
                    </div>  
                }
                <Modal show={modal} onHide={() => {setModal(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Suprimir cerca</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AlertWarning message="Estàs segur que vols suprimir aquesta alerta?" />
                    </Modal.Body>
                    <Modal.Footer>
                        <BtnPrimary text="Suprimeix ara " iconRight={<BsArrowRight />} onClick={deleteSearch} />
                    </Modal.Footer>
                </Modal>
            </div>
        </div>                        
    )
}

export default GestionarCerques