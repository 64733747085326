import React from 'react';
import InputProps from './TableMenuProps';
import { Table } from 'antd';
import "./TableMenu.sass";
class TableMenu extends React.Component<InputProps, {
    toggle: boolean;
}> {
    constructor(props: any) {
        super(props);
        this.state = {
          toggle: true,
        }
      }      

    render() {
        return (
            <div>
                <p></p>
                <div id="table-container" className="table-responsive" style={{width:'100%'}} >
                    <Table
                        showSorterTooltip={false}
                        bordered={false}
                        columns={this.props.columns} 
                        dataSource={this.props.resourceData}
                        pagination={{pageSize: this.props.pageSize, position: ['bottomCenter']}}
                    />
                </div>                
            </div>
        );
    }
}

export default TableMenu;