import CardDocumentProps from "./CardDocumentProps"
import styles from './CardDocument.module.sass'
import FlagExtra from "../../layout/flag_extra/FlagExtra"
import { BiChevronRight } from "react-icons/bi"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import parse from 'html-react-parser';
import moment from "moment"

function reemplazarValores(str: string, date: any) {
    if(parseInt(moment(date, "DD/MM/YYYY").format("YYYY")) < 1994)
    {
        // Verifica si el valor proporcionado es un string y tiene más de un dígito
        if (typeof str !== 'string' || str.length < 3) {
            return str; // Devuelve el valor sin cambios si no es un string o tiene menos de dos caracteres
        }
        // Extrae los dos primeros dígitos del string
        const dosPrimerosDigitos = str.slice(0, 2);
        
        // Comprueba si los dos primeros dígitos son '10', '20' o '30'
        if (dosPrimerosDigitos === '10') {
        // Reemplaza '10' por 'E' en el string completo
        return str.replace('10', 'E');
        } else if (dosPrimerosDigitos === '20') {
        // Reemplaza '20' por 'F' en el string completo
        return str.replace('20', 'F');
        } else if (dosPrimerosDigitos === '30') {
        // Reemplaza '30' por 'G' en el string completo
        return str.replace('30', 'G');
        }
    }

    // Si los dos primeros dígitos no coinciden con los casos anteriores, devuelve el string sin cambios
    return str;
  }

function CardDocument(props:CardDocumentProps) {
    const checkBadgeWidth = () => {
        const mainCard =  document.getElementById(props.docName) as any;

        let mainContainer = null as any;
        let subContainers = [] as any[];

        // Obtén el contenedor principal y los contenedores más pequeños
        mainCard.childNodes.forEach((item: any)=> {
            if(item.id === 'tagContainer')
            {
                mainContainer = item as any;
                mainContainer.childNodes.forEach((subItem: any)=> {
                    subContainers.push({subItem})
                })
            }
        })

        // Calcula el ancho del contenedor principal
        const mainContainerWidth = mainContainer.offsetWidth;

        let width = 0;
        // Itera sobre los contenedores más pequeños y verifica su ancho
        subContainers.forEach((subContainer: any) => {
        const subContainerWidth = subContainer.subItem.offsetWidth;
        width = width + subContainerWidth + 24;

        // Si el ancho del contenedor más pequeño es mayor al ancho del contenedor principal, ocúltalo
        if (width > mainContainerWidth) {
            subContainer.subItem.style.display = 'none';
        }
        });
    }

    const [isLinkVisited, setIsLinkVisited] = useState(false);

    useEffect(() => {
        
        const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)selectedItemsDocuments\s*=\s*([^;]*).*$)|^.*$/, "$1");
        const myArray = cookieValue ? JSON.parse(cookieValue) : [];
        const existeElemento = myArray.findIndex((item: any) => item == props.docName);        
        if (existeElemento != -1) {
            setIsLinkVisited(true);
        }
        checkBadgeWidth();
    },[])

    const handleLinkClick = () => {
        sessionStorage.setItem('highlights', JSON.stringify(props.highlights));
        setIsLinkVisited(true);
        const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)selectedItemsDocuments\s*=\s*([^;]*).*$)|^.*$/, "$1");
        const selectedItemsDocuments = cookieValue ? JSON.parse(cookieValue) : [];
        selectedItemsDocuments.push(props.docName);
        document.cookie = `selectedItemsDocuments=${JSON.stringify(selectedItemsDocuments)}`;
    };    

    return (
        <Link to={props.path} className={styles.container} onAuxClick={handleLinkClick} onClick={handleLinkClick}>
                <div className={styles.content_counter}>
                    <div className={styles.overline_medium}>BOPA</div>
                    <div className={styles.overline_medium}>{reemplazarValores(props.counter.toString(), props.date)}</div>
                    <div className={styles.overline_regular}>{props.date}</div>
                    {props.extra==true ? <FlagExtra/> : null}
                </div>
                <div id={props.docName} className={styles.content_sumari}>
                    <div className={isLinkVisited != true ? styles.sumari : styles.sumari_visited}>
                        {parse(props.sumari)}
                    </div>
                    <div id="tagContainer" className={styles.badge}>
                        {
                            props.tags != null && props.tags.length > 0 ?
                            props.tags.map((tag: any) => {
                                return (
                                    <div id="tagItem" className={styles.badge_container}>
                                        {tag}
                                    </div>      
                                )
                            })
                            : null
                        }
                    </div>
                </div>
                <div className={styles.content_arrow}><BiChevronRight/></div> 
        </Link>    
    )
}

export default CardDocument