import BtnSecondaryProps from "./BtnSecondaryProps"
import styles from './BtnSecondary.module.sass'
import Button from 'react-bootstrap/Button'


function BtnSecondary(props:BtnSecondaryProps) {
    return (
        <Button variant="outline-primary" name={props.name} type={props.type == "submit" ? "submit" : "button"} className={styles.btn_secondary} onClick={props.onClick} disabled={props.disabled == true ? true : false}>
            <span className={styles.iconLeft}>{props.iconLeft}</span>
            {props.text}
            <span className={styles.iconRight}>{props.iconRight}</span>
        </Button>
    )
}

export default BtnSecondary