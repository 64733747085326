import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/layout/header/Header';
import styles from './App.module.sass'
import Inici from './pages/inici/Inici';
import Butlletins from './pages/Butlletins/Butlletins';
import Documents from './pages/Documents/Documents';
import Legislacio from './pages/Legislacio/Legislacio';
import Footer from './components/layout/footer/Footer';
import Registre from './pages/registre/Registre';
import IniciSession from './pages/inici_session/IniciSession';
import Contacte from './pages/contacte/Contacte';
import PublicaBopa from './pages/publica_bopa/PublicaBopa';
import PreguntesFrequents from './pages/perguntes_frequents/PreguntesFrequents';
import Manual from './pages/manual/Manual';
import Legal from './pages/legal/Legal';
import Accessibilitat from './pages/accessibilitat/Accessibilitat';
import Perfil from './pages/perfil/Perfil';
import DetallDocument from './pages/detall_document/DetallDocument';
import GestionarAlerts from './pages/gestionar_alerts/GestionarAlerts';
import GestionarCerques from './pages/gestionar_cerques/GestionarCerques';
import GestionarFavorits from './pages/gestionar_favorits/GestionarFavorits';
import Pdf from './pages/pdf/pdf';
import './App.sass';
import ApiService from './utils/ApiService';
import AppSettingsService from './utils/AppSettingsService';
import NavBar from './components/layout/navbar/NavBar';
import { Toaster } from 'sonner'
import ReactGA from 'react-ga4';
import Manteniment from './pages/manteniment/Manteniment';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const manteniment = false;
  const location = useLocation();
  const [ menuState, setMenuState ] = useState(true);
  const [ isMedia, setIsMedia ] = useState(false);  
  const [ msalInstance, onMsalInstanceChange] = useState(null as any);

  let appSettings = new AppSettingsService();
  let apiService: ApiService = new ApiService(appSettings.GetWebApiBaseUri());

  var apiKey: string = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY != null ? process.env.REACT_APP_GOOGLE_ANALYTICS_KEY : "";
  var portaljuridicuri: string = process.env.REACT_APP_PORTAL_JURIDIC != null ? process.env.REACT_APP_PORTAL_JURIDIC : "";
  ReactGA.initialize(apiKey);

  const deleteCookie = (nombre: any) => {
    const cookiePairs = document.cookie.split(';');
    cookiePairs.forEach(pair => {
      const [name, value] = pair.split('=');
      if (name.trim() === nombre) {
        document.cookie = `${nombre}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        sessionStorage.setItem('documentsFilters', '')
        sessionStorage.setItem('lawsFilters', '')
        sessionStorage.setItem('positon', '')
        sessionStorage.setItem('activeKey', '')
        sessionStorage.setItem('activeKeyOrganismes', '')
        sessionStorage.setItem('activeKeyTemes', '')
        sessionStorage.setItem('butlletiFilters', '')
        sessionStorage.setItem('year', '')
        sessionStorage.setItem('positon', '')
        sessionStorage.setItem('highlights', '')
      }
    });
  };

  useEffect(() => {
    if(window.location.pathname.toLowerCase() != "/documents" && window.location.pathname.toLowerCase() != "/butlletins" ) deleteCookie("butlletiFilters")
    if(window.location.pathname.toLowerCase() != "/documents/detall" && window.location.pathname.toLowerCase() != "/documents") deleteCookie("documentsFilters")
    if(window.location.pathname.toLowerCase() != "/legislacio/detall" && window.location.pathname.toLowerCase() != "/legislacio") deleteCookie("lawsFilters")
  }, [location]);

  window.addEventListener("resize", () => {
    if (window.matchMedia("(min-width: 960px)").matches) {
      setIsMedia(false);
      setMenuState(true);
    } else {
      setIsMedia(true);
      setMenuState(false);
    }
});  

  useEffect(() => {
    if (window.matchMedia("(min-width: 960px)").matches) {
      setIsMedia(false);
      setMenuState(true);
    } else {
      setIsMedia(true);
      setMenuState(false);
    }
  }, []);

  const showMenu = () => {
    setMenuState(!menuState);
  }


    return (
      <div className="App">
        <Toaster />
          <div className={styles.navbar}>
            <Header 
            portaljuridicuri={portaljuridicuri}
            apiService={apiService}
            msalInstance={msalInstance} onMsalInstanceChange={onMsalInstanceChange} showMenu={showMenu} menuState={menuState} />
            { menuState ?
            <NavBar 
              portaljuridicuri={portaljuridicuri}
              openMenu = {() => {
                  if(isMedia == true) {
                    showMenu();
                  }
              }} />
              : null }
          </div>
  
          { manteniment ?
            <div className={styles.container}>
              <Routes>
                <Route path="/*" element={<Manteniment />}></Route>
                <Route path="/Contacte" element={<Contacte />}></Route>
                <Route path="/PublicaBopa" element={<PublicaBopa />}></Route>
                <Route path="/PreguntesFrequents" element={<PreguntesFrequents />}></Route>
                <Route path="/ManualUsuari" element={<Manual />}></Route>
                <Route path="/AvisLegal" element={<Legal />}></Route>
                <Route path="/Accessibilitat" element={<Accessibilitat />}></Route>    
              </Routes>
            </div>
              : 
            <div className={styles.container}>
              <Routes>
                <Route path="/" element={<Inici isMedia={isMedia} apiService={apiService} />}></Route>
                <Route path="/.auth/login/done" element={<Inici isMedia={isMedia} apiService={apiService} />}></Route>
                <Route path="/login" element={<Inici isMedia={isMedia} apiService={apiService} />}></Route>
                <Route path="/logout" element={<Inici isMedia={isMedia} apiService={apiService} />}></Route>
                <Route path="/Butlletins" element={<Butlletins apiService={apiService}/>}></Route>
                {/* <Route path="/Butlletins/Detall" element={<DetallDocument apiService={apiService}/>}></Route> */}
                <Route path="/Documents" element={<Documents apiService={apiService}/>}></Route>
                <Route path="/Documents/Detall" element={<DetallDocument apiService={apiService}/>}></Route>
                <Route path="/Legislacio" element={<Legislacio apiService={apiService}/>}></Route>
                <Route path="/Legislacio/Detall" element={<DetallDocument apiService={apiService}/>}></Route>
                <Route path="/Registre" element={<Registre />}></Route>
                <Route path="/IniciSessio" element={<IniciSession />}></Route>
                <Route path="/Contacte" element={<Contacte />}></Route>
                <Route path="/PublicaBopa" element={<PublicaBopa />}></Route>
                <Route path="/PreguntesFrequents" element={<PreguntesFrequents />}></Route>
                <Route path="/ManualUsuari" element={<Manual />}></Route>
                <Route path="/AvisLegal" element={<Legal />}></Route>
                <Route path="/Accessibilitat" element={<Accessibilitat />}></Route>
                <Route path="/MeuPerfil" element={<Perfil apiService={apiService}/>}></Route>
                <Route path="/GestionarAlerts" element={<GestionarAlerts apiService={apiService}/>} />
                <Route path="/GestionarCerques" element={<GestionarCerques  apiService={apiService}/>}/>
                <Route path="/GestionarFavorits" element={<GestionarFavorits  apiService={apiService}/>}/>
                <Route path="/Pdf" element={<Pdf/>}/>
                <Route path="/bopa/*" element={<DetallDocument apiService={apiService}/>}></Route>
                <Route path="/Pagines/*" element={<Inici isMedia={isMedia} apiService={apiService} />}></Route>
                <Route path="/Pagines/inici.aspx" element={<Inici isMedia={isMedia} apiService={apiService} />}></Route>              
              </Routes>
            </div>
          }
          
          
          <div className={styles.footer}>
            <Footer/>
          </div>
        
      </div>
    );     
}

export default App;
