import GestionarAlertsProps from "./GestionarAlertsProps"
import styles from './GestionarAlerts.module.sass'
import '../../styles/global.sass'
import AccordionFiltres2Nivel from "../../components/accordion/accordion_filtres/accordion_filtres_2_nivel/AccordionFiltres2Nivel"
import BtnSecondary from "../../components/layout/buttons/secondary/BtnSecondary"
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary"
import { useEffect, useState } from "react"
import './GestionarAlerts.sass'
import loadingLogo from '../../assets/loading/loading.gif'
import AccordionFiltres2Alertes from "../../components/accordion/accordion_filtres/accordion_filtres_2_alertes/AccordionFiltres2Alertes"
import { Form } from "react-bootstrap"
import { toast } from "sonner"
import { useNavigate } from "react-router-dom"

function GestionarAlerts(props: GestionarAlertsProps) {

    let navigate = useNavigate();

    const [loadingFilters, setLoadingFilters] = useState(true);
    const [temesFilters, setTemesFilters] = useState([] as any[]);
    const [organismesFilters, setOrganismesFilters] = useState([] as any[]);
    const [checkedItems, setCheckedItems] = useState([] as any[]);
    
    const desarAlertes = async (event: any) => {
        event.preventDefault();
        var serialize = require('form-serialize');
        var form = document.querySelector('#FormAlertes');
        var obj = serialize(form);
        let variables = obj.split('&');
        let alerts = {
            alertes: [] as any[]
        };
        variables.map((item: any) => {
            if(item.split('=')[1] == 'on')
            {
                alerts.alertes.push({
                    idCategoria: item.split('=')[0].split('_')[1],
                    activa: true
                })
            }
        })

        await props.apiService.SaveAlerts(alerts)
        .then(()=> {
            toast.success("Les teves alertes s'han desat correctament")
        })
        .catch(()=> {
            toast.error("Les teves alertes no s'han pogut desar")
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            await props.apiService.GetAlerts()
            .then((response: any) => {
                setCheckedItems(response);
                props.apiService.GetFiltersAlertes()
                .then((result: any) => {
                    let auxOrganismes: any[] = [];
                    let auxTemes: any[] = [];

                    result.organismes.forEach((organisme: any) => {
                        auxOrganismes.push({
                            secondarytitle: organisme.organismePare,
                            labels: organisme.organismes
                        })

                        if(auxOrganismes.length == result.organismes.length)
                        {
                            auxOrganismes.sort(function(a, b) {
                                if(a.secondarytitle.toLowerCase() < b.secondarytitle.toLowerCase()) return -1;
                                if(a.secondarytitle.toLowerCase() > b.secondarytitle.toLowerCase()) return 1;
                                return 0;
                            })
                        }
                    });            

                    result.temes.forEach((tema: any) => {
                        auxTemes.push({
                            secondarytitle: tema.temaPare,
                            labels: tema.temes
                        })

                        if(auxTemes.length == result.temes.length)
                        {
                            auxTemes.sort(function(a, b) {
                                    if(a.secondarytitle.toLowerCase() < b.secondarytitle.toLowerCase()) return -1;
                                    if(a.secondarytitle.toLowerCase() > b.secondarytitle.toLowerCase()) return 1;
                                    return 0;
                                })
                        }
                    });

                    setOrganismesFilters(auxOrganismes);
                    setTemesFilters(auxTemes);
                    setLoadingFilters(false);
                })
                .catch((error: any) => {
                    console.log(error);
                })
            })
        }

        fetchData()
        .catch(console.error);
    }, []);

    return(
        <div className={styles.container}>
            <div className='margin_top'></div>
            <div className='container'>
                <h1>Les meves alertes</h1>
                <div className="col-lg-8 col-sm-12">
                    <div className={styles.caption_regular}>
                        Enregistra't i rep el que t'interessa.
                        <br></br>
                        <p></p>
                        És un servei gratuït que t'ofereix la possibilitat de rebre, per correu electrònic, aquells documents que siguin del teu interès, el mateix dia que es publiquen.
                        <br></br>
                        <p></p>
                        Segueix els passos següents:
                        <br></br>
                        <p></p>
                        1. Selecciona l'Organisme i/o el Tema del qual vols rebre notificacions.
                        <br></br>
                        2. Marca el quadrat de l'apartat que t'interessa fent un clic.
                    </div>
                </div>

                <div className="mt-4"></div>

                <Form  id={'FormAlertes'}>
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className={styles.p1}>
                                Organismes
                            </div>
                            <p></p>
                            <div id="accordionFiltersAlertes">
                            {
                                loadingFilters == true ?
                                <div className="app-spinner">
                                    <img width={100} src={loadingLogo} alt="loading..." />
                                </div> 
                                :
                                organismesFilters.map((item: any) => {
                                    return (
                                        <div className={styles.item}>
                                            <AccordionFiltres2Alertes selectedItems={checkedItems} primarytitle={'organisme'} labels={item.labels} secondarytitle={item.secondarytitle}/>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-12">
                            <div className={styles.p1}>
                                Temes
                            </div>
                            <p></p>
                            <div id={"accordionFiltersAlertes"}>
                            {
                                loadingFilters == true ?
                                <div className="app-spinner">
                                    <img width={100} src={loadingLogo} alt="loading..." />
                                </div> 
                                :
                                temesFilters.map((item: any) => {
                                    return (
                                        <div className={styles.item}>
                                            <AccordionFiltres2Alertes selectedItems={checkedItems} primarytitle={'temes'} labels={item.labels} secondarytitle={item.secondarytitle}/>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    </div>
                </Form>

                <div className={styles.container_btns}>
                    <BtnSecondary
                     disabled={ window.history.length > 1 ? false :  true}
                     text='Tornar'
                     onClick={() => navigate(-1)}
                    //  onClick={()=>{window.open('/','_self')}}
                    />
                    <BtnPrimary
                    text='Desar'
                    onClick={desarAlertes}
                    />
                </div>
            </div>
        </div> 
        
    )
}

export default GestionarAlerts