import AccordionFiltres2YearsProps from './AccordionFiltres2YearsProps';
import './AccordionFiltres2Years.sass';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import moment from 'moment';

function AccordionFiltres2Years(props:AccordionFiltres2YearsProps) {
    return (
        <>
            <div id="accordionFilters2Level">
                <Accordion activeKey={props.year.toString()} >
                    {
                        props.labels.map((item, count)=> {
                            return (
                                <Accordion.Item eventKey={item.secondarytitle.includes(" ") ? item.secondarytitle.split(" ")[0] : item.secondarytitle}>
                                    <Accordion.Header onClick={props.onClickTitle}>
                                        <div>{item.secondarytitle.includes('.') ? item.secondarytitle.split('.')[1].trim() : item.secondarytitle}</div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div>
                                            {item.labels != null && item.labels.length > 0 ? 
                                                item.labels.map((label: any)=> {
                                                    if (props.primarytitle == "Anys")
                                                    {
                                                        return (
                                                            <Form.Check
                                                                onChange={props.onChange}
                                                                id={'Any_' + (item.secondarytitle.includes('(') ? item.secondarytitle.split('(')[0].trim() : item.secondarytitle) + '_' + moment().month(label.split(' ')[0]).format("MM")}
                                                                name={'Any_' + (item.secondarytitle.includes('(') ? item.secondarytitle.split('(')[0].trim() : item.secondarytitle) + '_' + moment().month(label.split(' ')[0]).format("MM")}
                                                                type={'checkbox'}
                                                                label={label}
                                                                defaultChecked={props.selectedItems?.findIndex((item1: any) => item1 === (item.secondarytitle.includes('(') ? item.secondarytitle.split('(')[0].trim() : item.secondarytitle) + '-' + moment().month(label.split(' ')[0]).format("MM")) != -1}
                                                            />
                                                        )
                                                    }
                                                    else
                                                    {
                                                        return (
                                                            <Form.Check
                                                                onChange={props.onChange}
                                                                id={props.primarytitle + '_' + label.name}
                                                                name={props.primarytitle + '_' + label.guid}
                                                                type={'checkbox'}
                                                                label={label.name}
                                                            />
                                                        )
                                                    }
                                                    // return (
                                                    //     <Form.Check
                                                    //         onChange={props.onChange}
                                                    //         id={props.primarytitle + '_' + label.name}
                                                    //         name={props.primarytitle + '_' + label.guid}
                                                    //         type={'checkbox'}
                                                    //         label={label.name}
                                                    //     />
                                                    // )                                    
                                                })
                                            : null}
                                                    
                                        </div>
                                    
                                    </Accordion.Body>
                                    
                                </Accordion.Item>
                            )
                        })
                    }
                </Accordion>
            </div>
        </>
        
    )
}

// function AccordionFiltres2Years(props:AccordionFiltres2YearsProps) {
//     return(
//         <>
//             <div id="accordionFilters2Level">
//                 <Accordion defaultActiveKey="1">
//                     <Accordion.Item eventKey="0">
//                         <Accordion.Header>
//                             <div>{props.secondarytitle.includes('.') ? props.secondarytitle.split('.')[1].trim() : props.secondarytitle}</div>
//                         </Accordion.Header>
//                         <Accordion.Body>
//                             <div>
//                                 {props.labels != null && props.labels.length > 0 ? 
//                                     props.labels.map((label: any)=> {
//                                         if (props.primarytitle == "Anys")
//                                         {
//                                             return (
//                                                 <Form.Check
//                                                     onChange={props.onChange}
//                                                     id={'Any_' + props.secondarytitle.split('(')[0].trim() + '_' + moment().month(label.split(' ')[0]).format("MM")}
//                                                     name={'Any_' + props.secondarytitle.split('(')[0].trim() + '_' + moment().month(label.split(' ')[0]).format("MM")}
//                                                     type={'checkbox'}
//                                                     label={label}
//                                                 />
//                                             )
//                                         }
//                                         else
//                                         {
//                                             return (
//                                                 <Form.Check
//                                                     onChange={props.onChange}
//                                                     id={props.primarytitle + '_' + label.name}
//                                                     name={props.primarytitle + '_' + label.guid}
//                                                     type={'checkbox'}
//                                                     label={label.name}
//                                                 />
//                                             )
//                                         }                                        
//                                     })
//                                 : null}
                                        
//                             </div>
                        
//                         </Accordion.Body>
                        
//                     </Accordion.Item>
//                 </Accordion>
//             </div>
//         </>
        
//     )
// }

export default AccordionFiltres2Years