import MevesAlertesProps from "./MevesAlertesProps"
import styles from './MevesAlertes.module.sass'
import AlertesIC from './../../assets/icons/Alertes.svg'
import ButtonIcon from "../layout/buttons/button_icon/ButtonIcon"
import { BiPencil } from 'react-icons/bi'
import LineResultsAlerts from "./line_result_alertes/LineResultsAlerts"
import { useEffect, useState } from "react"
import loadingLogo from './../../assets/loading/loading.gif'
import MsgIlustrationText from "../layout/mensajes/msg_ilustration_text/MsgIlustrationText"
import SinResultats from './../../assets/ilustrations/sin_alerts.svg'

function MevesAlertes(props: MevesAlertesProps) {

    const [alerts, setAlerts] = useState([] as any[]);
    const [loading, setLoading] = useState(true);

    useEffect (() => {
        let auxAlerts: any[] = [];
        props.alertes.map((alert: any) => {
            let index = auxAlerts.findIndex((x: any) => x.itemPare == alert.itemPare)
            if (index != -1)
            {
                auxAlerts[index].items.push(alert.idCategoria)
                auxAlerts[index].counter++;
                auxAlerts[index].url += ',' + alert.itemGuid
            } else {
                auxAlerts.push({
                    counter: 1,
                    items: [alert.idCategoria],
                    itemPare: alert.itemPare,
                    url: '/Documents?' + alert.itemType + '=' + alert.itemGuid
                })
            }
        })
        setAlerts(auxAlerts);
        setLoading(false);        
    },[])

    return (
        <>
            <div className={styles.header}>
                <div className={styles.title}>
                    <img src={AlertesIC} className={styles.icon} />
                    <div className={styles.p2}>Les meves alertes</div>
                </div>
                <div>
                    <ButtonIcon
                        icon={<BiPencil/>}
                        onClick={()=>{window.open('/GestionarAlerts','_self')}}
                    />
                </div>   
            </div>

            <div className={styles.body}>
                {
                    loading == false ? 
                    (
                        alerts.length > 0 ? 
                        (
                            alerts.map((alert)=> {
                                return(
                                    <LineResultsAlerts
                                        counter={alert.counter}
                                        text= {alert.itemPare}
                                        onClick={() => {window.open(alert.url, '_self')}}
                                    />
                                )
                            })
                        )
                        :
                        <MsgIlustrationText
                            ilustration={SinResultats}
                            text={'No hi ha cap alerta.'}
                        />
                    )
                    :
                    <div className="app-spinner">
                            <img width={100} src={loadingLogo} alt="loading..." />
                    </div>
                    
                }
            </div>            
        </>
        
    )
}

export default MevesAlertes