import LineResultsFavoritsProps from "./LineResultsFavoritsProps"
import styles from './LineResultsFavorits.module.sass'
import { BiChevronRight } from 'react-icons/bi'


function LineResultsFavorits(props:LineResultsFavoritsProps) {
    return (
        <div className={styles.container} onClick={props.onClick}>
            <div className={styles.content}>
                <div className={styles.description}>{decodeURI(props.description).replaceAll('+',' ').replaceAll('%2f', '/').replaceAll('%2c', ',')}</div>
                <BiChevronRight/>   
            </div>
        </div>           
    )
}

export default LineResultsFavorits