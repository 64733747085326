import ButtonIconDangerProps from "./ButtonIconDangerProps"
import styles from './ButtonIconDanger.module.sass'
import Button from 'react-bootstrap/Button'


function ButtonIconDanger(props:ButtonIconDangerProps) {
    return (
        <Button className={styles.btn_icon} onClick={props.onClick}>
            {props.icon} 
        </Button>
    )
}

export default ButtonIconDanger