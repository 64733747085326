import BtnPrimaryProps from "./BtnPrimaryProps"
import styles from './BtnPrimary.module.sass'
import Button from 'react-bootstrap/Button'

function BtnPrimary(props:BtnPrimaryProps) {
    return (
        <Button disabled={props.disabled} name={props.name} type={props.type == "submit" ? "submit" : "button"} variant="primary" className={styles.btn_primary} onClick={props.onClick}>
            <span>{props.iconLeft}</span>
            {props.text}
            <span>{props.iconRight}</span>
        </Button>
    )
}

export default BtnPrimary