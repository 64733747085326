import MevesCerquesProps from "./MevesCerquesProps"
import styles from './MevesCerques.module.sass'
import CerquesIC from './../../assets/icons/Cerca.svg'
import ButtonIcon from "../layout/buttons/button_icon/ButtonIcon"
import { BiPencil } from 'react-icons/bi'
import LineResultsCerques from "./line_meves_cerques/LineResultsCerques"
import { useEffect, useState } from "react"
import SinResultats from './../../assets/ilustrations/sin_alerts.svg'
import MsgIlustrationText from "../layout/mensajes/msg_ilustration_text/MsgIlustrationText"

function MevesCerques(props:MevesCerquesProps) {

    // const [searches, setSearches] = useState([] as any[]);
    // const [loading, setLoading] = useState(true);
    
    // useEffect(() => {
    //     // let auxAlerts: any[] = [];
    //     // props.searches.map((alert: any) => {
    //     //     let index = auxAlerts.findIndex((x: any) => x.itemPare == alert.itemPare)
    //     //     if ( index != -1 )
    //     //     {
    //     //         auxAlerts[index].items.push(alert.idCategoria)
    //     //         auxAlerts[index].counter++;
    //     //         auxAlerts[index].url += ',' + alert.itemGuid
    //     //     } else {
    //     //         auxAlerts.push({
    //     //             counter: 1,
    //     //             items: [alert.idCategoria],
    //     //             itemPare: alert.itemPare,
    //     //             url: '/Documents?item=' + alert.itemGuid
    //     //         })
    //     //     }
    //     // })
    //     // setSearches(auxAlerts);
    //     setLoading(false);        
    // },[])

    return(
        <>
            <div className={styles.header}>
                <div className={styles.title}>
                    <img src={CerquesIC} className={styles.icon} />
                    <div className={styles.p2}>Les meves cerques</div>
                </div>
                <div>
                    <ButtonIcon
                        icon={<BiPencil/>}
                        onClick={()=>{window.open('/GestionarCerques','_self')}}
                    />
                </div>   
            </div>

            <div className={styles.body}>
                {
                    props.searches.length > 0 ?
                    props.searches.map((item: any) => {
                        return(
                        <LineResultsCerques
                            title={decodeURI(item.titol.replaceAll('+',' '))}
                            description= {item.descripcio != null && item.descripcio != "" ? decodeURI(item.descripcio.replaceAll('+',' ')) : '-'}
                            onClick={()=>{window.open(item.consulta, '_self')}}
                        />
                        )                        
                    })
                    : 
                    <MsgIlustrationText
                        ilustration={SinResultats}
                        text={'No hi ha cap cerca.'}
                    />
                }
            </div>            
        </>
        
    )
}

export default MevesCerques