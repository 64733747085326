import React, { useEffect, useState } from 'react';
import axios, { AxiosInstance } from 'axios';

class ApiService {
    UnAuthenticatedApi: AxiosInstance;
    // constructor that requires a base URI.
    constructor(baseUri: string) {
        if (!baseUri) {
            throw new Error('the base uri was not provided');
        }

        this.UnAuthenticatedApi = axios.create({
            baseURL: baseUri,
            // will inject auth header on-demand later as needed.
            headers: { 
                'Content-Type': 'application/json',
            }
        });

        var environment: string = process.env.REACT_APP_ENVIRONMENT != null ? process.env.REACT_APP_ENVIRONMENT : "PROD";
    }

    GetToken() {
        return new Promise((resolve, reject) => { 
            const cookieValue = document.cookie.split(';').find(cookie => cookie.trim().startsWith('clientToken='))?.split('=')[1] || '';
            if(cookieValue != null && cookieValue != "")
            {
                resolve(cookieValue);
            }
            else
            {
                reject("")
            }
            // axios.get('/.auth/me').then((response: any) => {
            //     resolve(response.data[0].id_token);
            // })
            // .catch((error) => {
            //     reject(error);
            // });
        })
    }    


    // // DEV
    // GetFilters () {
    //     return this.UnAuthenticatedApi.get('/api/GetFilters?code=K1sFJVJyHgCM1mG5HxbZ_DLzKKqTTy48zLdB90uBaGAHAzFu_AHHIw==')
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    // GetFiltersLaws () {
    //     return this.UnAuthenticatedApi.get('/api/GetFiltersLaws?code=fDNOCsDzCdRuwXqdFk3cKi9md6LBImtupxCDNmfuTn0_AzFuT91EBw==')
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }
    
    // GetPaginatedDocuments (documentWebFilters: any) {
    //     if(documentWebFilters.textSearch != null && documentWebFilters.textSearch != "") {
    //         var text = documentWebFilters.textSearch
    //         if(isURIEncoded(text)) {
    //             documentWebFilters.textSearch = decodeURI(documentWebFilters.textSearch)
    //         }
    //          documentWebFilters.textSearch = documentWebFilters.textSearch.replaceAll("à","a").replaceAll("á","a").replaceAll("è","e").replaceAll("é","e").replaceAll("í","i").replaceAll("ì","i").replaceAll("ò","o").replaceAll("ó","o").replaceAll("ù","u").replaceAll("ú","u").replaceAll("%","percentage");
    //          documentWebFilters.textSearch = encodeURI(documentWebFilters.textSearch)
    //         //  console.log(documentWebFilters.textSearch)
    //     }
    //     return this.UnAuthenticatedApi.post('/api/GetPaginatedDocumentsSearchAllMode?code=-huXpXJ7DHG4vr4OXJkkD94t4ZN7SyLFHTdANtONzxltAzFu6DJYLw==',documentWebFilters)
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    // GetPaginatedLaws (documentWebFilters: any) {
    //     return this.UnAuthenticatedApi.post(i/GetPaginatedLaws?code=hPiBkrEgWFOPKcnAAhcvDcVi7Qns2_UbdOwy0F82ENgxAzFuqP8aZA==',
    //     documentWebFilters)'/ap
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    // GetPaginatedNewsletter () {
    //     return this.UnAuthenticatedApi.get('/api/GetPaginatedNewsletter?code=t53IZlXabXpuVAMuw8GN_K1ugQpIeuBS2CCEZkPap3ODAzFuL0PFJw==')
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    GetDocumentByFileName (fileName: string) {
        var Api = axios.create({
            baseURL: "https://bopaazurefunctions.azurewebsites.net",
            // will inject auth header on-demand later as needed.
            headers: { 
                'Content-Type': 'application/json',
            }
        });        
        return Api.get('/api/GetDocumentByFileName?name=' + fileName)
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }

    // // GetDocumentByFileName (fileName: string) {
    // //     return this.UnAuthenticatedApi.get('/api/GetDocumentByFileName?code=APSBhjoFISrhyK2VTeOE4pFqjyxJpSFhyI0rpNJCD6CiAzFuEfBRWQ==&name=' + fileName)
    // //     .then((response: any) => {
    // //         return response.data;
    // //     })
    // //     .catch((error: any) => {
    // //         throw Error('An error has occurred calling the api: ' + error);
    // //     });
    // // }

    // GetMonthButlletins (date: any) {
    //     return this.UnAuthenticatedApi.get('/api/GetMonthButlletins?code=YJmChxEEcoEdJuq_6j608psVCr8OTegjRIIMCGOtXThzAzFuKlHDyw==&date=' + date)
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    // GetFiltersAlertes() {
    //     return this.UnAuthenticatedApi.get('/api/GetFilters?code=K1sFJVJyHgCM1mG5HxbZ_DLzKKqTTy48zLdB90uBaGAHAzFu_AHHIw==&getYears=false')
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    // DeletePublicUser () {
    //     return new Promise((resolve, reject) => {
    //         this.GetToken()
    //         .then((response: any) => {
    //             this.UnAuthenticatedApi.get('/api/DeletePublicUser?code=CpDSRKL9v2vSfN4a7H9GWX6xPnJSkk95OkUvJFLZIZPDAzFuUxsdGQ==', {
    //                 headers: {
    //                     Authorization: `Bearer ${response}`
    //                 }
    //             })
    //             .then((response: any) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error: any) => {
    //                 reject('An error has occurred calling the api: ' + error);
    //             });
    //         })
    //     });
    // }

    // GetAlerts () {
    //     return new Promise((resolve, reject) => {
    //         this.GetToken()
    //         .then((response: any) => {
    //             this.UnAuthenticatedApi.get('/api/GetAlerts?code=OHvE3sFNy7ro98L34BNKNSI_YayKM1l4LChDJmZGe3EHAzFubg85IQ==', {
    //                 headers: {
    //                     Authorization: `Bearer ${response}`
    //                 }
    //             })
    //             .then((response: any) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error: any) => {
    //                 reject('An error has occurred calling the api: ' + error);
    //             });
    //         })
    //     });
    // }

    // SaveAlerts (alerts: any) {
    //     return new Promise((resolve, reject) => {
    //         this.GetToken()
    //         .then((response: any) => {
    //             this.UnAuthenticatedApi.post('/api/SaveAlerts?code=ei1pdkClfOuqUmC4JYCn4Jr3rryJsVjemLPAiVPC62RlAzFuPjVqlQ==', alerts, {
    //                 headers: {
    //                     Authorization: `Bearer ${response}`
    //                 }
    //             })
    //             .then((response: any) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error: any) => {
    //                 reject('An error has occurred calling the api: ' + error);
    //             });
    //         })
    //     });
    // }

    // SaveSearch (search: any) {
    //     return this.GetToken() 
    //         .then((response: any) => {
    //             this.UnAuthenticatedApi.post('/api/SaveSearch?code=77hpniPamXhMt5v_5H0EvCTkRyV82WYfkz953Td6FpVZAzFu7TyK-A==', search, {
    //                 headers: {
    //                     Authorization: `Bearer ${response}`
    //                 }
    //             })
    //             .then((response: any) => {
    //                 return response.data;
    //             })
    //             .catch((error: any) => {
    //                 throw Error('An error has occurred calling the api: ' + error);
    //             });
    //         }
    //     )}

    // GetSearches () {
    //     return new Promise((resolve, reject) => {
    //         this.GetToken()
    //         .then((response: any) => {
    //             this.UnAuthenticatedApi.get('/api/GetSearches?code=_pFQi-WrFgbfVPLGx3lywOaGw1YC3i898Bfod3hiTOorAzFuuS2dSg==', {
    //                 headers: {
    //                     Authorization: `Bearer ${response}`
    //                 }
    //             })
    //             .then((response: any) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error: any) => {
    //                 reject('An error has occurred calling the api: ' + error);
    //             });
    //         })
    //     });
    // }

    // UpdateSearch (idCerca: number, active: number) {
    //     return new Promise((resolve, reject) => {
    //         this.GetToken()
    //         .then((response: any) => {
    //             this.UnAuthenticatedApi.get('/api/UpdateSearch?code=O2h1gKxpWIPFCsvqcb7ZP-xHILcdk-mLqdrAYJ5P4KMYAzFuI3RScQ==&idSearch=' + idCerca + '&active=' + active, {
    //                 headers: {
    //                     Authorization: `Bearer ${response}`
    //                 }
    //             })
    //             .then((response: any) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error: any) => {
    //                 reject('An error has occurred calling the api: ' + error);
    //             });
    //         })
    //     });
    // }

    // DeleteSearch (idCerca: number) {
    //     return new Promise((resolve, reject) => {
    //         this.GetToken()
    //         .then((response: any) => {
    //             this.UnAuthenticatedApi.get('/api/DeleteSearch?code=5sBKB0sEejHpnAoEX5IqSYhPEsqrWDzN4BifXPLp4YcIAzFuTBrhuA==&idSearch=' + idCerca, {
    //                 headers: {
    //                     Authorization: `Bearer ${response}`
    //                 }
    //             })
    //             .then((response: any) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error: any) => {
    //                 reject('An error has occurred calling the api: ' + error);
    //             });
    //         })
    //     });
    // }

    // GetLikeDocument (nameDocument: string) {
    //     return new Promise((resolve, reject) => {
    //         this.GetToken()
    //         .then((response: any) => {
    //             this.UnAuthenticatedApi.get('/api/GetLikeDocument?code=MpNprV84zD6f4KpNPFtxa6WdkKWCrBzi-bKiBo07LUG4AzFuV4OHAQ==&nameDocument=' + nameDocument, {
    //                 headers: {
    //                     Authorization: `Bearer ${response}`
    //                 }
    //             })
    //             .then((response: any) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error: any) => {
    //                 reject('An error has occurred calling the api: ' + error);
    //             });
    //         })
    //     });
    // }

    // GetLikeDocuments() {
    //     return new Promise((resolve, reject) => {
    //         this.GetToken()
    //         .then((response: any) => {
    //             this.UnAuthenticatedApi.get('/api/GetLikeDocuments?code=F89ZWTSormmtBV5fQK9-1VSJQlKc5_o0P3wp5RtrSFnLAzFu9fbGpQ==', {
    //                 headers: {
    //                     Authorization: `Bearer ${response}`
    //                 }
    //             })
    //             .then((response: any) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error: any) => {
    //                 reject('An error has occurred calling the api: ' + error);
    //             });
    //         })
    //     });
    // }

    // DeleteLikeDocument (nameDocument: string) {
    //     return new Promise((resolve, reject) => {
    //         this.GetToken()
    //         .then((response: any) => {
    //             this.UnAuthenticatedApi.get('/api/DeleteLikeDocument?code=0SMoWx5f8jFojWpU67SLS1_8sR1CyaMYd9Qx4R1IH_YZAzFuA2jp-A==&nameDocument=' + nameDocument, {
    //                 headers: {
    //                     Authorization: `Bearer ${response}`
    //                 }
    //             })
    //             .then((response: any) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error: any) => {
    //                 reject('An error has occurred calling the api: ' + error);
    //             });
    //         })
    //     });
    // }

    // SaveLikeDocument (nameDocument: string, customNomDocument: any) {
    //     return new Promise((resolve, reject) => {
    //         this.GetToken()
    //         .then((response: any) => {
    //             this.UnAuthenticatedApi.get('/api/SaveLikeDocument?code=PKzb9xqV2xvpmVCTHPoJhGMj38nnGiffdjv9er8q__c_AzFud-4Gvg==&nameDocument=' + nameDocument + '&title=' + customNomDocument, {
    //                 headers: {
    //                     Authorization: `Bearer ${response}`
    //                 }
    //             })
    //             .then((response: any) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error: any) => {
    //                 reject('An error has occurred calling the api: ' + error);
    //             });
    //         })
    //     });
    // }
    
    // SaveOpinionForm (contactForm: any) {
    //     return this.UnAuthenticatedApi.post('/api/SaveOpinionForm?code=rjuFq4ymAtV5pzqj44dcc-38q_SKPZsd7HPJQ3UZTHjFAzFu9llHRA==',
    //     contactForm)
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    // SaveContactForm (contactForm: any) {
    //     return this.UnAuthenticatedApi.post('/api/SaveContactForm?code=65Rrn_SesygetrBWCvmnSa_HTe8tbCtC-s5S5jGhgRBpAzFubR5YIA==', contactForm)
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    // CheckPublicUser (token: any) {
    //     return this.UnAuthenticatedApi.get('/api/CheckPublicUser?code=q8jz2wVpHU2tFozRTJmo-3ZPE4GfsbOgl-VPM9R1IKjnAzFunKR9DQ==', {
    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     })
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    // GetFiltersByBOPA (year: any, num: any) {
    //     return this.UnAuthenticatedApi.get('/api/GetFiltersByBOPA?code=_viFntXyxVGvgbMQQz1YKCngGvazQ2hJXcXchtvl0oPaAzFuM5wDPQ==&numBOPA='+ num +'&year=' + year)
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    // GetDocumentsByBOPA (year: any, num: any) {
    //     return this.UnAuthenticatedApi.get('/api/GetDocumentsByBOPA?code=u_hzdMLML22qIDaCnjYhEn_i1o7D5kjpGH-kOcCft3d_AzFujma7IQ==&numBOPA='+ num +'&year=' + year)
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    // PROD
    GetFilters () {
        return this.UnAuthenticatedApi.get('/api/GetFilters?code=4gnJ1MYI8SOOyJJJ7NgEjNPSHZZhKSOHBEtVq5w6x232AzFut8NmQQ==')
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }

    GetFiltersLaws () {
        return this.UnAuthenticatedApi.get('/api/GetFiltersLaws?code=HupQuCjj1Q0HOc-MFUKZk1c1WqgbU21QcEEaAYbrqwoeAzFuuwJLdg==')
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }

    GetPaginatedDocuments (documentWebFilters: any) {
        if(documentWebFilters.textSearch != null && documentWebFilters.textSearch != "") {
            var text = documentWebFilters.textSearch
            if(isURIEncoded(text)) {
                documentWebFilters.textSearch = decodeURI(documentWebFilters.textSearch)
            }
            if(documentWebFilters.searchMode == 1) documentWebFilters.textSearch = documentWebFilters.textSearch.replaceAll("à","a").replaceAll("á","a").replaceAll("è","e").replaceAll("é","e").replaceAll("í","i").replaceAll("ì","i").replaceAll("ò","o").replaceAll("ó","o").replaceAll("ù","u").replaceAll("ú","u").replaceAll("%","percentage");
        }
        return this.UnAuthenticatedApi.post('/api/GetPaginatedDocuments2Indexes?code=PJcaar13MjFNATASEiyt7hSz4mzHfMFEKdPccu3qfloYAzFui5Ni6A==',
        // return this.UnAuthenticatedApi.post('/api/GetPaginatedDocumentsSearchAllMode?code=RqcJTZERqwHCAZD2CLQY1MPpNgHitq2l4UXZT9GAJX0GAzFuYnYtPQ==',
        documentWebFilters)
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }

    // GetPaginatedLaws (documentWebFilters: any) {
    //     return this.UnAuthenticatedApi.post('/api/GetPaginatedLaws?code=hPiBkrEgWFOPKcnAAhcvDcVi7Qns2_UbdOwy0F82ENgxAzFuqP8aZA==',
    //     documentWebFilters)
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    GetPaginatedNewsletter () {
        return this.UnAuthenticatedApi.get('/api/GetPaginatedNewsletter?code=532VhY43kbVlS2gIPw_kV140jA6-0byDOJUCHXEu6WO2AzFunF_KIA==')
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }

    // GetDocumentByFileName (fileName: string) {
    //     return this.UnAuthenticatedApi.get('/api/GetDocumentByFileName?name=' + fileName)
    //     .then((response: any) => {
    //         return response.data;
    //     })
    //     .catch((error: any) => {
    //         throw Error('An error has occurred calling the api: ' + error);
    //     });
    // }

    GetMonthButlletins (date: any) {
        return this.UnAuthenticatedApi.get('/api/GetMonthButlletins?code=oEQCEj04L-FHloPFFtfdXyOZozUafYV_uqL6sQdePkF0AzFuZTJP3w==&date=' + date)
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }

    GetFiltersAlertes() {
        return this.UnAuthenticatedApi.get('/api/GetFilters?code=4gnJ1MYI8SOOyJJJ7NgEjNPSHZZhKSOHBEtVq5w6x232AzFut8NmQQ==&getYears=false')
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }

    DeletePublicUser () {
        return new Promise((resolve, reject) => {
            this.GetToken()
            .then((response: any) => {
                this.UnAuthenticatedApi.get('/api/DeletePublicUser?code=BMIK-uIYD0QxPwKwbLO2AHFe8Ll347Ag0I2l4ZD_6aaTAzFueVUpdg==', {
                    headers: {
                        Authorization: `Bearer ${response}`
                    }
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject('An error has occurred calling the api: ' + error);
                });
            })
        });
    }

    GetAlerts () {
        return new Promise((resolve, reject) => {
            this.GetToken()
            .then((response: any) => {
                this.UnAuthenticatedApi.get('/api/GetAlerts?code=syulzkm69a-iBvC1S7Nk7Aikn-_MmGQPOuPDRgILb1kGAzFu4YnKrA==', {
                    headers: {
                        Authorization: `Bearer ${response}`
                    }
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject('An error has occurred calling the api: ' + error);
                });
            })
        });
    }

    SaveAlerts (alerts: any) {
        return new Promise((resolve, reject) => {
            this.GetToken()
            .then((response: any) => {
                this.UnAuthenticatedApi.post('/api/SaveAlerts?code=0eLYSHx7RBLGBlEUlLgr644FQWwsaMrcuoL5GdJJ19TwAzFu0yogPA==', alerts, {
                    headers: {
                        Authorization: `Bearer ${response}`
                    }
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject('An error has occurred calling the api: ' + error);
                });
            })
        });
    }

    SaveSearch (search: any) {
        return this.GetToken() 
            .then((response: any) => {
                this.UnAuthenticatedApi.post('/api/SaveSearch?code=7QMtVPofJ2kRbTuRbjV98VZikLf-kjx1vS5zsjq2MCInAzFuBrXiSw==', search, {
                    headers: {
                        Authorization: `Bearer ${response}`
                    }
                })
                .then((response: any) => {
                    return response.data;
                })
                .catch((error: any) => {
                    throw Error('An error has occurred calling the api: ' + error);
                });
            }
        )}

    GetSearches () {
        return new Promise((resolve, reject) => {
            this.GetToken()
            .then((response: any) => {
                this.UnAuthenticatedApi.get('/api/GetSearches?code=dqmTS25-FK3cuOfqH5mxHqBByIp35ZG7y5i1AjX8aunSAzFuhMzaBA==', {
                    headers: {
                        Authorization: `Bearer ${response}`
                    }
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject('An error has occurred calling the api: ' + error);
                });
            })
        });
    }

    UpdateSearch (idCerca: number, active: number) {
        return new Promise((resolve, reject) => {
            this.GetToken()
            .then((response: any) => {
                this.UnAuthenticatedApi.get('/api/UpdateSearch?code=VHm3PY2PnRt4LaCPCwarFKWbhgaLnd2MqT3UNk0B6RTNAzFuEqpIow==&idSearch=' + idCerca + '&active=' + active, {
                    headers: {
                        Authorization: `Bearer ${response}`
                    }
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject('An error has occurred calling the api: ' + error);
                });
            })
        });
    }

    DeleteSearch (idCerca: number) {
        return new Promise((resolve, reject) => {
            this.GetToken()
            .then((response: any) => {
                this.UnAuthenticatedApi.get('/api/DeleteSearch?code=7m1xvb7ASjN-x8zR9mfxMXLz5bhObsct_UDbA-I2OpVLAzFuZEYPuw==&idSearch=' + idCerca, {
                    headers: {
                        Authorization: `Bearer ${response}`
                    }
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject('An error has occurred calling the api: ' + error);
                });
            })
        });
    }

    GetLikeDocument (nameDocument: string) {
        return new Promise((resolve, reject) => {
            this.GetToken()
            .then((response: any) => {
                this.UnAuthenticatedApi.get('/api/GetLikeDocument?code=wkyeHDDhUmywxaZy7cPkeQzM1Wl8hesriagJjlZCEutmAzFuURKdug==&nameDocument=' + nameDocument, {
                    headers: {
                        Authorization: `Bearer ${response}`
                    }
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject('An error has occurred calling the api: ' + error);
                });
            })
        });
    }

    GetLikeDocuments() {
        return new Promise((resolve, reject) => {
            this.GetToken()
            .then((response: any) => {
                this.UnAuthenticatedApi.get('/api/GetLikeDocuments?code=jVT3cggs2t-mywWsJfHXzVYpxxhMr3q2zeHIKKUzzQuSAzFuVEX8Ug==', {
                    headers: {
                        Authorization: `Bearer ${response}`
                    }
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject('An error has occurred calling the api: ' + error);
                });
            })
        });
    }

    DeleteLikeDocument (nameDocument: string) {
        return new Promise((resolve, reject) => {
            this.GetToken()
            .then((response: any) => {
                this.UnAuthenticatedApi.get('/api/DeleteLikeDocument?code=yqX6x8oxi5UpnT_5KFy875odKjmqPij0ULhv45tRLlXnAzFuTCXZpQ==&nameDocument=' + nameDocument, {
                    headers: {
                        Authorization: `Bearer ${response}`
                    }
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject('An error has occurred calling the api: ' + error);
                });
            })
        });
    }

    SaveLikeDocument (nameDocument: string, customNomDocument: any) {
        return new Promise((resolve, reject) => {
            this.GetToken()
            .then((response: any) => {
                this.UnAuthenticatedApi.get('/api/SaveLikeDocument?code=fwmM5ZbrqP1Jh0039PxERTtI13psCk2aizcekDQzPmwAAzFu6w6Bow==&nameDocument=' + nameDocument + '&title=' + customNomDocument, {
                    headers: {
                        Authorization: `Bearer ${response}`
                    }
                })
                .then((response: any) => {
                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject('An error has occurred calling the api: ' + error);
                });
            })
        });
    }
    
    CheckPublicUser (token: any) {
        return this.UnAuthenticatedApi.get('/api/CheckPublicUser?code=4pRIqvgs4u-SHAckGmRp7HHuwa8THuWIugUJYDfOd_99AzFuf31zxQ==', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }

    //URLs de desenvolupament
    SaveOpinionForm (contactForm: any) {
        return this.UnAuthenticatedApi.post('/api/SaveOpinionForm?code=rjuFq4ymAtV5pzqj44dcc-38q_SKPZsd7HPJQ3UZTHjFAzFu9llHRA==',
        contactForm)
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }

    //URLs de desenvolupament
    SaveContactForm (contactForm: any) {
        return this.UnAuthenticatedApi.post('/api/SaveContactForm?code=65Rrn_SesygetrBWCvmnSa_HTe8tbCtC-s5S5jGhgRBpAzFubR5YIA==', contactForm)
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }

    GetFiltersByBOPA (year: any, num: any) {
        return this.UnAuthenticatedApi.get('/api/GetFiltersByBOPA?code=AGQRV4yChZCeJ9XKzmKbKJSwWTHP9ouL_XGTgLzcB6OmAzFu3R-YbA==&numBOPA='+ num +'&year=' + year)
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }


    GetDocumentsByBOPA (year: any, num: any) {
        return this.UnAuthenticatedApi.get('/api/GetDocumentsByBOPA?code=g0LIbgotqEe94pypk8MWNTWr3ldcgMQ70o0fSarhINWwAzFuCnk3Lg==&numBOPA='+ num +'&year=' + year)
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }

    GetFiltersNewsletter () {
        return this.UnAuthenticatedApi.get('/api/GetFiltersNewsletter')
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }

    GetNewPaginatedNewsletter (body: any) {
        return this.UnAuthenticatedApi.post('/api/GetNewPaginatedNewsletter', body)
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            throw Error('An error has occurred calling the api: ' + error);
        });
    }
}

const isURIEncoded = (str: any) => {
    try {
      decodeURIComponent(str);
      return true;
    } catch (error) {
      return false;
    }
  };

export default ApiService;
