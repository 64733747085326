import GestionarFavoritsProps from "./GestionarFavoritsProps"
import styles from './GestionarFavorits.module.sass'
import '../../styles/global.sass'
import ButtonIconDanger from "../../components/layout/buttons/button_icon_danger/ButtonIconDanger"
import { BiShow, BiTrash } from "react-icons/bi"
import ButtonIconStandard from "../../components/layout/buttons/button_icon_standard/ButtonIconStandard"
import { Form, Modal } from "react-bootstrap"
import TableMenu from "../../components/TableMenu/TableMenu"
import BtnSecondary from "../../components/layout/buttons/secondary/BtnSecondary"
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary"
import { useEffect, useState } from "react"
import { BsArrowRight } from "react-icons/bs"
import AlertWarning from "../../components/Alerts/AlertWarning/AlertWarning"
import { toast } from "sonner"
import loadingLogo from '../../assets/loading/loading.gif'

function GestionarFavorits(props: GestionarFavoritsProps) {

    const [likes, setLikes] = useState([] as any[]);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [itemDelete, setItemDelete] = useState("");
    
    const GetLikes = async () => {
        await props.apiService.GetLikeDocuments()
            .then((result: any)=> {
                setLikes(result)
                setLoading(false)
            })
            .catch((error)=> {
                console.log(error)
                console.log(error)
                setLoading(false)
            })
    }

    useEffect(()=> {
        GetLikes()
    },[])

    const deleteFavorite = async () => {
        await props.apiService.DeleteLikeDocument(itemDelete)
        .then(()=> {
            toast.success("El document favorit s'ha suprimit correctament")
            setLoading(true)
            setModal(false)
            GetLikes()
        })
        .catch(()=> {
            toast.error("El document favorit no s'ha pogut suprimir")
            setModal(false)
        })
    }

    const columns: any[] = [
        {
            title: 'Títol',
            dataIndex: 'titol',
            key:'titol',
            width: '20%',
            render: (titol: any) => {
                return (
                    <div>{titol != null && titol != "" ? decodeURI(titol).replaceAll('+',' ').replaceAll('%2f', '/').replaceAll('%2c', ',') : "-"}</div>
                )                
            }            
        },
        {
            title: 'Sumari',
            dataIndex: 'sumari',
            key:'sumari',
            render: (sumari: any) => {
                return (
                    <>{decodeURI(sumari).replaceAll('+',' ').replaceAll('%2f', '/').replaceAll('%2c', ',')}</>
                )                
            }               
        },
        {
            title: '',
            dataIndex: 'nomDocument',
            width: '10px',
            render: (nomDocument: any) => {
                return (
                    <ButtonIconDanger
                        icon={<BiTrash size="18"/>}
                        onClick={()=>{
                            setItemDelete(nomDocument);
                            setModal(true);
                        }}
                    />
                )                
            }
        },
        {
            title: '',
            dataIndex: 'nomDocument',
            width: '10px',
            render: (nomDocument: any) => {
                return (
                    <ButtonIconStandard
                        icon={<BiShow size="18"/>}
                        onClick={()=>{window.open('/Documents/Detall?doc=' + nomDocument, '_self')}}
                    />
                )                
            }
        }
    ];

    return (
        <div className={styles.container}>
            <div className='margin_top'></div>
            <div className='container'>
                <h1>Els meus documents favorits</h1>
                <div className={styles.caption_regular}>
                    Marca com a favorits els documents que més utilitzes fent un clic.
                </div>
                <p></p>
                { loading == false ? <TableMenu columns={columns} resourceData={likes} /> : 
                    <div className="app-spinner">
                        <img width={100} src={loadingLogo} alt="loading..." />
                    </div>  
                }
            </div>

            <Modal show={modal} onHide={() => {setModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Suprimir document favorit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AlertWarning message="Estàs segur que vols suprimir aquest document dels teus favorits?" />
                </Modal.Body>
                <Modal.Footer>
                    <BtnPrimary text="Suprimeix ara " iconRight={<BsArrowRight />} onClick={deleteFavorite} />
                </Modal.Footer>
            </Modal>
        </div>    
    )
}

export default GestionarFavorits