import DetallDocumentProps from "./DetallDocumentProps"
import { useNavigate } from "react-router-dom";
import styles from './DetallDocument.module.sass'
import '../../styles/global.sass'
import './DetallDocument.sass'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import ButtonIconPrimary from "../../components/layout/buttons/button_icon_primary/ButtonIconPrimary"
import CardHelp from "../../components/cards/card_help/CardHelp"
import Altres from './../../assets/icons/altres.svg'
import Doc from './../../assets/icons/docs.svg'
import BtnSecondary from "../../components/layout/buttons/secondary/BtnSecondary"
import { useEffect, useState } from "react"
import { Dropdown, Form, Modal } from "react-bootstrap"
import { BsArrowLeft, BsArrowRight, BsArrowUpCircle, BsGoogle, BsMicrosoft } from "react-icons/bs"
import AlertWarning from "../../components/Alerts/AlertWarning/AlertWarning"
import BtnLogin from "../../components/layout/buttons/loginButton/BtnLogin"
import NotFoundDocument from "../NotFoundDocument/NotFoundDocument"
import moment from 'moment'
import {decode} from 'html-entities';
import "./DetallDocument.sass"
import icEPUB from './../../assets/icons/EPUB.svg'
import icPDF from './../../assets/icons/PDF.svg'
import icEmail from './../../assets/icons/email_green.svg'
import icPrint from './../../assets/icons/print.svg'
import icXML from './../../assets/icons/xml.svg'
import icWORD from './../../assets/icons/WORD.svg'
import loadingLogo from '../../assets/loading/loading.gif'
import { BiHeart } from "react-icons/bi"
import { AiFillHeart } from "react-icons/ai"
import { toast } from 'sonner'
import 'moment/locale/ca'
import BadgeDoc from "../../components/layout/badgeDoc/BadgeDoc"
import ExpiredDocument from "../ExpiredDocument/ExpiredDocument"
import BtnPrimary from "../../components/layout/buttons/primary/BtnPrimary"
import axios from "axios"
import { Buffer } from "buffer";
import parse from 'html-react-parser';
moment.locale('ca'); 

function DetallDocument(props: DetallDocumentProps) {

    let navigate = useNavigate();
    const [htmlData, sethtmlData] = useState(null as any);
    const [tags, setTags] = useState([] as any);
    const [contentText, setContentText] = useState(null as any);

    const [modalSave, setModalSave] = useState(false);
    const [likeDocument, setLikeDocument] = useState(null as any);
    const [customNomDocument, setCustomNomDocument] = useState("");
    const [fileName, setFileName] = useState("");
    const [modal, setModal] = useState(false);
    const [docNotFound, setDocNotFound] = useState(false);
    const [unpublishedDoc, setUnpublishedDoc] = useState(false);
    const [loading, setLoading] = useState(true);
    const [otherDocs, setOtherDocs] = useState([] as any);
    const [otherLaws, setOtherLaws] = useState([] as any);
    const [searchedText, setSearchedText] = useState("");

    const [pdfurl, setpdfUrl] = useState('');
    const [urlExists, setUrlExists] = useState(false);
    const [xmlUrlExists, setXmlUrlExists] = useState(false);
    const [epubUrlExists, setEpubUrlExists] = useState(false);
    const [url, setUrl] = useState('');
    const [fileSize, setFileSize] = useState(null as any);
    const [ userName, setName] = useState(null as any);
    const [showTopBtn, setShowTopBtn] = useState(false);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const cookieValue = document.cookie.split(';').find(cookie => cookie.trim().startsWith('clientToken='))?.split('=')[1] || '';
        if(cookieValue != null && cookieValue != "")
        {
            setName("");
        }
        else
        {
            setName(null)
        }
        // axios.get('/.auth/me').then((response: any) => {
        //   setName(response.data[0].user_claims.find((x: any) => x.typ == 'name').val);
        // })
        // .catch((error) => {
        //   setName(null)
        //   console.log(error);
        // });
    }, []);

    const checkUrlExists = (url: any, url2: any) => {
        fetch(url)
          .then((response) => {
            if (response.ok) {
              setUrlExists(true);
              let contentLength = response.headers.get('content-length');
              if(contentLength != null) {
                const megabytes = parseInt(contentLength) / (1024 * 1024);
                setFileSize(megabytes.toFixed(2).toString().replace('.', ','));
              }
            } else {
              setUrlExists(false);
            }
          })
          .catch(() => {
            setUrlExists(false);
          });

          fetch(url2.replaceAll('[type]', 'epub'))
          .then((response) => {
            if (response.ok) setEpubUrlExists(true);
            else setEpubUrlExists(false);
          })
          .catch(() => {
            setEpubUrlExists(false);
          });

          fetch(url2.replaceAll('[type]', 'xml'))
          .then((response) => {
            if (response.ok) setXmlUrlExists(true);
            else setXmlUrlExists(false);
          })
          .catch(() => {
            setXmlUrlExists(false);
          });
      };

    const getLikeDocument = async (doc: string) => {
        await props.apiService.GetLikeDocument(doc)
        .then((result) => {
            if(result != 0) setLikeDocument(result);
        })
    }

    const onChangeTitle = (event: any) => {
        setCustomNomDocument(event.target.value)
    } 

    const saveLikeDocument = async () => {
        await props.apiService.SaveLikeDocument(fileName, customNomDocument)
        .then((result) => {
            toast.success('Document desat com a favorit.')
            setLikeDocument(result);
            setModalSave(false)
        })
        .catch((error) => {
            console.log(error)
            toast.error('Error al desar el document com a favorit.')
        })
    }

    const saveFavorite = async () => {
        if(userName != null){
            if(likeDocument == null) {
                setModalSave(true);                
            } else {
                await props.apiService.DeleteLikeDocument(fileName)
                .then(() => {
                    toast.success('Document esborrat de favorits.')
                    setLikeDocument(null);
                })
                .catch((error) => {
                    console.log(error)
                    toast.error('Error al esborrar el document de favorits.')
                })
            }
            
        }
        else setModal(true);
    }

    const onChangeText = (event: any) => {
        setSearchedText(event.target.value);
    }

    const scrollIntoViewWithOffset = (elem:any, offset:any) => {
        window.scrollTo({
          behavior: 'smooth',
          top:
            elem.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
        })
      }

    const onReturnPressed = (event: any) => {
        if (event.key === 'Enter') {
            var elements:any = document.getElementById("Searched");
            if(elements != null && elements.length == null)
            {
                scrollIntoViewWithOffset(elements, 100);
                //elements.scroll({ behavior: 'smooth' });
            } else {
                if(elements != null && elements.length > 0)
                {
                    scrollIntoViewWithOffset(elements[0], 100);
                    //elements[0].scroll({ behavior: 'smooth' });
                }
            }
        }
    }

    function resaltarCoincidencias(texto: any, palabraBusqueda: any) {
        const palabraNormalizada = palabraBusqueda.replace(/[’']/g, "['’]");
        const regex = new RegExp(`(${palabraNormalizada})`, 'gi');
        return texto.replace(/(<[^>]+>|[^<>\s]+)/g, (match: any) => {
            if (match.startsWith('<')) {
              return match;
            } else {
              return match.replace(regex, '<span id="Searched" style="background-color: yellow;">$1</span>');
            }
          });
        }

    const searchText = () => {
        if(contentText != null)
        {
            let textoResaltado = contentText;
            if (searchedText !== "") textoResaltado = resaltarCoincidencias(contentText, searchedText);

            return ( 
                <div id="htmlContent" dangerouslySetInnerHTML={{ __html: textoResaltado }} />
            )
        }        
    }

    useEffect(() => {
        if(window.location.pathname.split('/')[3] != null && (window.location.pathname.split('/')[3].toLowerCase() == "documents" || window.location.pathname.split('/')[3].toLowerCase() == "annexos"))
        {
            let fileAnnexName = decodeURI(window.location.pathname.split('/')[4].split('.')[0].normalize('NFD')).toLocaleUpperCase() + '.' + window.location.pathname.split('/')[4].split('.')[1];
            let urlAnnex = process.env.REACT_APP_BLOB_STORAGE_URI + '/' + window.location.pathname.split('/')[2] + '/annexes/' + fileAnnexName;
            let urlAnnex2 = process.env.REACT_APP_BLOB_STORAGE_URI + '/' + window.location.pathname.split('/')[2] + '/annexes/' + decodeURI(window.location.pathname.split('/')[4].split('.')[0]) + '.' + window.location.pathname.split('/')[4].split('.')[1];;
            let urlDoc = process.env.REACT_APP_BLOB_STORAGE_URI + '/' + window.location.pathname.split('/')[2] + '/pdf/' + fileAnnexName;
            if(window.location.pathname.split('/')[3].toLowerCase() == "annexos"){
                fileAnnexName = decodeURI(window.location.pathname.split('/')[4].split('.')[0].normalize('NFD')) + '.' + window.location.pathname.split('/')[4].split('.')[1];
                urlAnnex = process.env.REACT_APP_BLOB_STORAGE_URI + '/annexos/' + fileAnnexName;
            }
            fetch(urlAnnex)
            .then((response) => {
                if (response.ok) window.open(urlAnnex,'_self');
                else {
                    fetch(urlAnnex2)
                    .then((response) => {
                        if (response.ok) window.open(urlDoc,'_self');
                        else {
                            fetch(urlDoc)
                            .then((response) => {
                                if (response.ok) window.open(urlDoc,'_self');
                                else {
                                    setDocNotFound(true)
                                }
                            })
                            .catch(() => {
                                setDocNotFound(true)
                            });
                        }
                    })
                    .catch(() => {
                        fetch(urlDoc)
                        .then((response) => {
                            if (response.ok) window.open(urlDoc,'_self');
                            else {
                                setDocNotFound(true)
                            }
                        })
                        .catch(() => {
                            setDocNotFound(true)
                        });
                    });
                    
                }
            })
            .catch(() => {
                fetch(urlAnnex2)
                    .then((response) => {
                        if (response.ok) window.open(urlDoc,'_self');
                        else {
                            fetch(urlDoc)
                            .then((response) => {
                                if (response.ok) window.open(urlDoc,'_self');
                                else {
                                    setDocNotFound(true)
                                }
                            })
                            .catch(() => {
                                setDocNotFound(true)
                            });
                        }
                    })
                    .catch(() => {
                        fetch(urlDoc)
                        .then((response) => {
                            if (response.ok) window.open(urlDoc,'_self');
                            else {
                                setDocNotFound(true)
                            }
                        })
                        .catch(() => {
                            setDocNotFound(true)
                        });
                    });
            });
        }

        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });

        let doc: any;

        if(window.location.pathname.startsWith('/bopa/') == true)
        {
            const pieces = window.location.pathname.split('/')
            const last = pieces[pieces.length - 1];
            if (last.includes('aspx')) doc = last.replace('.aspx', '');
        }
        else
        {
            const queryParameters = new URLSearchParams(window.location.search)
            doc = queryParameters.get("doc");
        }

        if(doc == undefined || doc == null || doc == "")
        {
            setDocNotFound(true);
            setLoading(false);
        }
        else
        {
            getLikeDocument(doc);
            setFileName(doc)
            props.apiService.GetDocumentByFileName(doc)
            .then((response: any) => {
                let year = parseInt(moment(response[0].dataPublicacioButlleti).format('YYYY')) - 1988;
                let num = response[0].numButlleti;
                let yearString = year.toString();
                let numString = num;
                if(year.toString().length == 1) yearString = '00' + year
                else if(year.toString().length == 2) yearString = '0' + year
                if(num.toString().length == 1) numString = '00' + num
                else if(num.toString().length == 2) numString = '0' + num
                else numString = num
                let fileUri = yearString + numString
                let pdfUrl = process.env.REACT_APP_BLOB_STORAGE_URI + '/' + fileUri + '/pdf/' + doc + '.pdf';
                let othersUrl = process.env.REACT_APP_BLOB_STORAGE_URI + '/[type]/' + fileUri + '/' + doc + '.[type]';
                checkUrlExists(pdfUrl, othersUrl)
                setpdfUrl(pdfUrl)
                setUrl(othersUrl)

                if(moment(response[0].dataFiPublicacio).diff(moment(),'minutes') < 0)
                {
                    setUnpublishedDoc(true)
                    setLoading(false)
                }
                else
                {
                    response.map((doc: any, i: any) => {
                        if(doc.fileType == 'html')
                        {
                            let tagsAux = [];
                            // Llógica de tagsAux
                            if(doc.organismePare != null && doc.organisme != null) {
                                let numOrganisme = parseInt(doc.organismePare.split('.')[0])
                                if(numOrganisme != 18 && numOrganisme!= 17) {
                                    if (doc.organismePare != null) tagsAux.push(doc.organismePare.includes('.') ? doc.organismePare.split('.')[1].trim() : doc.organismePare)
                                    if (doc.organisme != null) tagsAux.push(doc.organisme.includes('.') ? doc.organisme.split('.')[1].trim() : doc.organisme)
                                    if (doc.tema != null) tagsAux.push(doc.tema.includes('.') ? doc.tema.split('.')[1].trim() : doc.tema)
                                } else {
                                    if (doc.organisme != null) tagsAux.push(doc.organisme.includes('.') ? doc.organisme.split('.')[1].trim() : doc.organisme)
                                    if (doc.temaPare != null) tagsAux.push(doc.temaPare.includes('.') ? doc.temaPare.split('.')[1].trim() : doc.temaPare)
                                    if (doc.tema != null) tagsAux.push(doc.tema.includes('.') ? doc.tema.split('.')[1].trim() : doc.tema)
                                }
                                setTags(Array.from(new Set(tagsAux)));
                            }
                            else if(doc.tema != null && doc.temaPare != null)
                            {
                                if (doc.temaPare != null) tagsAux.push(doc.temaPare.includes('.') ? doc.temaPare.split('.')[1].trim() : doc.temaPare)
                                if (doc.tema != null) tagsAux.push(doc.tema.includes('.') ? doc.tema.split('.')[1].trim() : doc.tema)
                                setTags(Array.from(new Set(tagsAux)));
                            }
                            
                            // Revisar si el BOPA es anterior al 08/06/2023
                            if(moment(doc.dataPublicacioButlleti).diff('2023-06-08T00:00:00.000Z', 'minutes') < 0)
                            {
                                doc.dataPublicacioButlleti = moment(doc.dataPublicacioButlleti).add(1,'day').format('YYYY-MM-DDTHH:MM:SS')
                            }
                            sethtmlData(doc)
                            fetch(doc.metadata_storage_path)
                            .then((res: any) =>  res.blob())
                            .then((res2) => { 
                                var reader = new FileReader();
                                reader.onload = function() {
                                    setContentText(reader.result);
                                    // var textoResaltado: any = reader.result;
                                    // var highlightStorage = sessionStorage.getItem('highlights') || '';
                                    // if (highlightStorage != null && highlightStorage != undefined &&                                        
                                    //     highlightStorage != '' && highlightStorage != 'null' && highlightStorage != 'undefined') {
                                    //     var highLights = JSON.parse(highlightStorage)
                                    //     Promise.all(highLights.content.map((highLight: any) => {
                                    //         const regex = /<em>(.*?)<\/em>/g;
                                    //         let matchResult;
                                    //         const contenidosEm = [];
                                    //         while ((matchResult = regex.exec(highLight)) !== null) {
                                    //             contenidosEm.push(matchResult[0]);
                                    //         }
                                    //         contenidosEm.map((highLight1) => {
                                    //             let highLightAux = highLight1.replaceAll("<em>", "").replaceAll("</em>", "")
                                    //             textoResaltado = textoResaltado.replaceAll(highLightAux, highLight1)
                                    //         })                                            
                                    //     })).then(() => {
                                    //         setContentText(textoResaltado);
                                    //     })
                                    //     .catch(() => {
                                    //         setContentText(textoResaltado);
                                    //     })
                                    // }
                                    // else {
                                    //     setContentText(textoResaltado);
                                    // }
                                    
                                    let othersUrl: string[] = [];
                                    var str = reader.result?.toString();    
                                    if(str != null && str.split('href="').length > 1 )
                                    {
                                        str.split('"').map((url) => {
                                            if(url.startsWith('/'))
                                            {
                                                let url1 = url.split('"')[0]
                                                if (othersUrl.findIndex(x => x == url1) == -1) othersUrl.push(url1)
                                            }
                                        })
                                    }
                                    if(othersUrl.length > 0)
                                    {
                                        othersUrl.map((item) => {
                                            if (item.split('doc=').length > 1) {
                                                props.apiService.GetDocumentByFileName(item.split('doc=')[1])
                                                .then((result) => {
                                                    let item = {
                                                        sumari: decodeURIComponent(result[0].sumari).replaceAll('+', ' '),
                                                        name: result[0].nomDocument
                                                    }
                                                    if(item.sumari.toString().startsWith('Llei') || item.sumari.toString().startsWith('llei'))
                                                    {
                                                        setOtherLaws((oldArray: any) => [...oldArray, item])
                                                    }
                                                    else if (item.sumari.toString().startsWith('<div') && item.sumari.split('>')[1].startsWith('Llei'))
                                                    {
                                                        setOtherLaws((oldArray: any) => [...oldArray, item])
                                                    }
                                                })
                                            } else if (item.split('Pagines/').length > 1) {
                                                props.apiService.GetDocumentByFileName(item.split('Pagines/')[1].replace('.aspx', ''))
                                                .then((result) => {
                                                    let item = {
                                                        sumari: decodeURIComponent(result[0].sumari).replaceAll('+', ' '),
                                                        name: result[0].nomDocument
                                                    }
                                                    
                                                    if(item.sumari.toString().startsWith('Llei') || item.sumari.toString().startsWith('llei'))
                                                    {
                                                        setOtherLaws((oldArray: any) => [...oldArray, item])
                                                    }
                                                    else if (item.sumari.toString().startsWith('<div') && item.sumari.split('>')[1].startsWith('Llei'))
                                                    {
                                                        setOtherLaws((oldArray: any) => [...oldArray, item])
                                                    }
                                                })
                                            }
                                        })
                                    }
                                }
                                reader.readAsText(res2);
                            })    
                        }
                    })

                    setLoading(false);
                    let documentWebFilters = {
                        textSearch: "",
                        skip: 0,
                        orderBy: 'DataPublicacioButlleti desc,OrganismeOrder,OrganismeChildOrder',
                        size: 3,
                        butlletiFilter: response[0].numButlleti,
                        anyFilter:[response[0].dataPublicacioButlleti.split("T")[0]]
                    }

                    props.apiService.GetPaginatedDocuments(documentWebFilters)
                    .then((result) => {
                        let otherDocsAux: any[] = [];
                        result.paginatedDocuments.map((otherDoc: any) => {
                            if(otherDoc.document.nomDocument != response[0].nomDocument)
                            {
                                otherDocsAux.push(otherDoc.document);
                            }
                        })
                        setOtherDocs(otherDocsAux);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                }
                
            })
            .catch((error: any) => {
                console.log(error)
                setDocNotFound(true);
                setLoading(false);
            })
        }
    }, []);

    return (
        <div className={styles.container} >
            <div className="top-to-btm">
                {" "}
                {showTopBtn && (
                    <BsArrowUpCircle
                        className="icon-position icon-style"
                        onClick={goToTop}
                    />
                )}{" "}
            </div>
            <div className='margin_top'></div>
            {
                loading == true ?
                    <div className={styles.container_main}>
                        <div className="app-spinner">
                            <img width={150} src={loadingLogo} alt="loading..." />
                        </div>
                    </div>
                : docNotFound == true ? <NotFoundDocument /> : unpublishedDoc == true ? <ExpiredDocument /> :


                <div className='container'>
                    <p></p>
                    <Breadcrumb>
                        { window.location.pathname.split('/')[1].toLowerCase() == "documents" ? <Breadcrumb.Item href="/Documents">Documents</Breadcrumb.Item> : null }
                        { window.location.pathname.split('/')[1].toLowerCase() == "butlletins" ? <Breadcrumb.Item href="/Butlletins">Butlletins</Breadcrumb.Item> : null }
                        { window.location.pathname.split('/')[1].toLowerCase() == "legislacio" ? <Breadcrumb.Item href="/Legislacio">Legislació</Breadcrumb.Item> : null }
                        <Breadcrumb.Item href={"/Documents"  + "?num=" + parseInt(htmlData.numButlleti) +"&yearItem=" + moment(htmlData.dataPublicacioButlleti).format('YYYY_MM')}>{"Núm. " + parseInt(htmlData.numButlleti) + " any " + moment(htmlData.dataPublicacioButlleti).format('YYYY') + " (" +  moment(htmlData.dataPublicacioButlleti).format('LL') + ")" }</Breadcrumb.Item>
                        <Breadcrumb.Item active className={styles.breadcrumb_active}>{parse(decodeURIComponent(htmlData.sumari).replaceAll('+', ' '))}</Breadcrumb.Item>
                    </Breadcrumb>

                    <div className={styles.container_main}>
                        <div className="row">
                            <div className="col-lg-9 col-sm-12">
                                <div className={styles.content_tags}>
                                    <div className={styles.tags} id="tags">
                                        {
                                            tags.length > 0 ? 
                                            (tags.map((item: any)=> {
                                                return (
                                                    <BadgeDoc
                                                        label={decode(item)}
                                                    />
                                                )
                                            }))
                                            : null
                                        }
                                    </div>
                                    <div className={styles.btn_favorit}>
                                        <ButtonIconPrimary
                                            icon={likeDocument != null ? <AiFillHeart/> : <BiHeart/> }
                                            onClick={saveFavorite}
                                        />
                                    </div>
                                </div>

                                <Modal show={modalSave} onHide={() => {setModalSave(false)}}>
                                    <Form id={"saveForm"}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Desar document</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                                <Form.Label>Títol</Form.Label>
                                                <Form.Control 
                                                    name="title"
                                                    className={styles.form_control} 
                                                    type="text" 
                                                    placeholder="Insereix"
                                                    onChange={onChangeTitle}/>                                          
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <BtnPrimary text="Desar document " iconRight={<BsArrowRight />} onClick={saveLikeDocument} />
                                        </Modal.Footer>
                                    </Form>
                                </Modal>
                                
                                <Modal show={modal} onHide={() => {setModal(false)}}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Desar com a favorit</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <AlertWarning message="Has d'accedir a la plataforma per poder desar el document als teus favorits." />
                                        {/* <BtnLogin onClick={() => {window.open(process.env.REACT_APP_GOOGLE_REDIRECT, "_self")}} iconLeft={<BsGoogle size="20"/>} text={" Google"}/>
                                        <p></p>
                                        <BtnLogin onClick={() => {window.open(process.env.REACT_APP_MICROSOFT_REDIRECT, "_self")}} iconLeft={<BsMicrosoft size="20"/>} text={" Microsoft"}/> */}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <BtnSecondary text="Sortir " iconRight={<BsArrowRight />} onClick={() => {setModal(false)}} />
                                    </Modal.Footer>
                                </Modal>

                                <div className={styles.body_medium} id="sumariContent">
                                    {parse(decodeURIComponent(htmlData.sumari).replaceAll('+', ' '))}
                                </div>

                                <div className={styles.content_export}>
                                    {
                                        urlExists == true ?
                                        <CardHelp
                                            onClick={() => {window.open(pdfurl, '_blank')}}
                                            icon={icPDF}
                                            label={"PDF (" + fileSize + " MB)"}
                                        />
                                     : null
                                    }
                                    {
                                        urlExists == true ?
                                        <div className="mx-2"></div>
                                     : null
                                    }
                                    
                                    <div id="docsItems">
                                        <Dropdown>
                                            <Dropdown.Toggle>
                                                <CardHelp
                                                    icon={Altres}
                                                    label="Altres"
                                                />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item>
                                                    <CardHelp
                                                        onClick={() => {
                                                            const byteCharacters = Buffer.from(htmlData.wordDocument, "base64");
                                                            const blob = new Blob([byteCharacters], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                                                            // Crear una URL para el objeto blob
                                                            const url = URL.createObjectURL(blob);
                                                            // Crear un elemento de enlace
                                                            const link = document.createElement('a');
                                                            // Establecer la URL del enlace
                                                            link.href = url;
                                                            // Establecer el nombre del archivo
                                                            link.download = htmlData.nomDocument + '.docx';
                                                            // Hacer clic en el enlace para descargar el archivo
                                                            link.click();
                                                        }}
                                                        icon={icWORD}
                                                        label={"Word"}
                                                    />
                                                </Dropdown.Item>
                                                { epubUrlExists == true ?
                                                <Dropdown.Item>
                                                    <CardHelp
                                                        onClick={() => {window.open(url.replaceAll('[type]', 'epub'), '_blank')}}
                                                        icon={icEPUB}
                                                        label={"ePUB"}
                                                    />
                                                </Dropdown.Item>
                                                : null }
                                                { xmlUrlExists == true ?
                                                <Dropdown.Item>
                                                    <CardHelp
                                                        onClick={() => {
                                                            axios({
                                                                url: (url.replaceAll('[type]', 'xml')),
                                                                method: 'GET',
                                                                responseType: 'blob', // important
                                                              }).then((response) => {
                                                                const url1 = window.URL.createObjectURL(new Blob([response.data]));
                                                                const link = document.createElement('a');
                                                                link.href = url1;
                                                                link.setAttribute('download', htmlData.nomDocument + '.xml');
                                                                document.body.appendChild(link);
                                                                link.click();
                                                              });
                                                        }}
                                                        icon={icXML}
                                                        label={"XML"}
                                                    />
                                                </Dropdown.Item>
                                                : null }
                                                <Dropdown.Item>
                                                    <CardHelp
                                                        onClick={() => {window.open('mailto:?body=' + window.location.href, '_blank')}}
                                                        icon={icEmail}
                                                        label={"Enviar per correu"}
                                                    />
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <CardHelp
                                                        onClick={() => {
                                                            var content = document.getElementById("contenido") as any;
                                                            var logotipo = document.getElementById("logotipo") as any;
                                                            var sumari = document.getElementById("sumariContent") as any;

                                                            var pri = document.getElementById("ifmcontentstoprint") as any;
                                                            pri = pri.contentWindow;
                                                            pri.document.open();
                                                            pri.document.write("<p></p>");
                                                            pri.document.write(logotipo.innerHTML.replace('class="logotipo"', 'style="height: 65px;" class="logotipo"'));
                                                            pri.document.write("<p></p>");

                                                            pri.document.write('<div style="font-family:sans-serif; padding: 20px 0px 20px 0px; font-size: 22px;">');
                                                            pri.document.write(sumari.innerHTML);
                                                            pri.document.write("</div>");

                                                            pri.document.write(`<table style="color: #636469; font-family:sans-serif; padding: 20px 0px 5px 0px; font-size: 18px;" width="100%"><tr>`);
                                                            tags.map((tag: any) => {
                                                                pri.document.write(`<td width="${100/tags.length}%">${tag}</td>`);
                                                            })
                                                            pri.document.write(`</tr></table>`);

                                                            pri.document.write("<p></p>");

                                                            pri.document.write('<div style="font-family:sans-serif; font-size: 14px;">');
                                                            pri.document.write(content.innerHTML);
                                                            pri.document.write("</div>");
                                                            
                                                            pri.document.write('<hr color="#16A085">');
                                                            pri.document.close();
                                                            pri.focus();
                                                            pri.print();
                                                        }}
                                                        icon={icPrint}
                                                        label={"Imprimir"}
                                                    />
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <iframe id="ifmcontentstoprint" style={{height: "0px", width: "0px", position: "absolute"}} ></iframe>
                                <div className={styles.content_search}>
                                    <Form.Control 
                                        className={styles.form_control} 
                                        type="text" 
                                        placeholder={'Que contingui...'} 
                                        onChange={onChangeText} 
                                        onKeyDown={onReturnPressed}/> 
                                </div>

                                <p></p>
                                <BtnSecondary
                                        onClick={() => navigate(-1)}
                                        disabled={ window.history.length > 1 ? false :  true}
                                        text="Tornar"
                                    />
                            {
                                contentText != null ?
                                    <div id="contenido" className={styles.body_regular}>
                                            {searchText()} 
                                    </div>
                                :
                                    <div className={styles.container_main}>
                                        <div className="app-spinner">
                                            <img width={150} src={loadingLogo} alt="loading..." />
                                        </div>
                                    </div>
                            }

                                <div className={styles.content_btn_tornar}>
                                    <BtnSecondary
                                        disabled={ window.history.length > 1 ? false :  true}
                                        onClick={() => navigate(-1)}
                                        // onClick = {() => { window.open("/" + (window.location.pathname.split('/')[1] == "bopa" ? "Documents" : window.location.pathname.split('/')[1]), '_self') }}
                                        text="Tornar"
                                    />
                                </div>

                            </div>

                            <div className="col-lg-3 col-sm-12">

                            {otherDocs.length > 0 ?
                                <div className={styles.container_altres_docs}>
                                    <div className={styles.p2}>Altres documents del butlletí</div>
                                    {
                                        otherDocs.map((doc: any) => {
                                            return(
                                                <div>
                                                    <div className="mt-2"></div>
                                                    <CardHelp
                                                        onClick={() => {window.open("/" + (window.location.pathname.split('/')[1] == "bopa" ? "Documents" : window.location.pathname.split('/')[1])+ '/Detall?doc=' + doc.nomDocument, '_self')}}
                                                        icon={Doc}
                                                        label={parse(decodeURIComponent(doc.sumari).replaceAll('+', ' '))}
                                                    />
                                                </div>
                                            )

                                        })
                                    }
                                    <div className={styles.content_btns}>
                                        {/* <BtnLink
                                        text="Carrega més"
                                        />
                                        <div className="mx-2"></div> */}   
                                        <BtnSecondary
                                            text="Veure tot"
                                            onClick={() => {window.open(`/Documents?yearItem=${moment(htmlData.dataPublicacioButlleti).format('YYYY_MM')}&num=${parseInt(htmlData.numButlleti)}`,'_self')}}
                                        />
                                    </div>
                                </div>
                                : null }

                                <div className="mt-4"></div>
                                {
                                    otherLaws.length > 0 ?
                                    <div className={styles.container_altres_docs}>
                                    <div className={styles.p2}>Lleis relacionades</div>
                                        {
                                            otherLaws.map((doc: any) => {
                                                return (
                                                    <div>
                                                    <div className="mt-2"></div>
                                                    <CardHelp
                                                        onClick={() => {window.open("/" + (window.location.pathname.split('/')[1] == "bopa" ? "Documents" : window.location.pathname.split('/')[1])  + '/Detall?doc=' + doc.name, '_self')}}
                                                        icon={Doc}
                                                        label={parse(doc.sumari)}
                                                    />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

function reemplazarTextoEnHTML(htmlString: any, textoNuevo: any, replaceText: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    var textoSinHTML = doc.body.textContent || "";
    textoSinHTML = textoSinHTML.replace(/[\n\t]/g, '')
    replaceText = replaceText.replace(/[\n\t]/g, ' ')
    // console.log(textoNuevo)
    textoSinHTML = textoSinHTML.replace(replaceText, textoNuevo);
    
    console.log(textoSinHTML)
    console.log(replaceText)
    console.log(textoSinHTML.trim().includes(replaceText.trim()))
    const nuevoHTML = htmlString.replace(textoSinHTML, textoNuevo);
    return nuevoHTML;
  }
  

export default DetallDocument
