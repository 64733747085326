import styles from './Legal.module.sass'
import '../../styles/global.sass'

function Legal() {
    return(
        <div className={styles.container}>
            <div className='margin_top'></div>
            <div className='container'>
                <h1>Avís legal</h1>
                <div className='col-lg-8 col-sm-12'>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            Domini i seu electrònica
                        </div>
                        <div className={styles.body_regular}>
                            El domini d'Internet www.bopa.ad és la seu electrònica del Butlletí Oficial del Principat d'Andorra. A partir de l'1 de gener del 2015 únicament el Butlletí Oficial del Principat d'Andorra publicat en suport digital es considera autèntic i té validesa jurídica, salvat el que disposa l'article 4 de la Llei 25/2014, del 30 d'octubre, del Butlletí Oficial del Principat d'Andorra. Dels números del Butlletí Oficial del Principat d'Andorra publicats amb anterioritat a l'1 de gener del 2015 només l'edició impresa en paper té la consideració d'oficial i autèntica.
                            El Servei del Butlletí Oficial del Principat d'Andorra es reserva el dret a efectuar canvis a la seva seu electrònica sense avís previ amb la finalitat d'actualitzar, corregir, modificar, afegir o eliminar els continguts de la seu electrònica del Butlletí Oficial del Principat d'Andorra o el seu disseny.
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            Entramats
                        </div>
                        <div className={styles.body_regular}>
                            El Servei del Butlletí Oficial del Principat d'Andorra prohibeix expressament la realització d'entramats o l'ús per part de tercers de qualssevol altres mecanismes que alterin el disseny, la configuració original o els continguts de la seu electrònica del Butlletí Oficial del Principat d'Andorra. 
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            Galetes
                        </div>
                        <div className={styles.body_regular}>
                            Les galetes són necessàries perquè l'usuari pugui navegar per la seu electrònica del Butlletí Oficial del Principat d'Andorra, i es poden deshabilitar directament des del navegador web.
                            <br></br>
                            El Servei del Butlletí Oficial del Principat d'Andorra en cap cas no utilitza les galetes per adquirir dades personals identificatives de l'usuari ni registra les adreces IP. L'ús de les galetes es reserva exclusivament a facilitar a l'usuari l'accessibilitat al lloc web i per a estadístiques. 
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            Hiperenllaços
                        </div>
                        <div className={styles.body_regular}>
                            Els usuaris que es proposin establir un hiperenllaç entre la seu electrònica del Butlletí Oficial del Principat d'Andorra i la seva pàgina web accepten i reconeixen expressament que:
                            <br></br>
                            - L'establiment de l'hiperenllaç no implica necessàriament l'existència de relacions entre el Servei del Butlletí Oficial del Principat d'Andorra i el propietari de la pàgina web en què s'estableixi l'hiperenllaç, ni l'acceptació o l'aprovació per part del Servei del Butlletí Oficial del Principat d'Andorra dels seus continguts i serveis. Per tant, no es pot considerar que el Servei del Butlletí Oficial del Principat d'Andorra hagi autoritzat expressament l'hiperenllaç ni que hagi supervisat, assumit o ofert en qualsevol forma els serveis oferts o posats a disposició a la pàgina web des de la qual s'estableix l'hiperenllaç.
                            <br></br>
                            - El Servei del Butlletí Oficial del Principat d'Andorra no es fa responsable de la informació ni d'altres continguts integrats a portals web de tercers en els quals s'hagi inserit un hiperenllaç de la seu electrònica del Butlletí Oficial del Principat d'Andorra, ni del seu accés per part de l'usuari, ni del resultat obtingut com a conseqüència d'aquest accés, ni dels danys i perjudicis que se'n puguin derivar.
                            <br></br>
                            - La pàgina web en què s'estableixi l'hiperenllaç no podrà contenir cap marca, logotip, denominació, eslògan o qualsevol altre signe distintiu que pertanyi al Servei del Butlletí Oficial del Principat d'Andorra, excepte els que formin part del mateix enllaç o hiperenllaç.
                            <br></br>
                            - La pàgina web en què s'estableixi l'hiperenllaç no contindrà informacions amb continguts il·lícits, immorals o contra l'ordre públic, o contra drets de tercers.
                            <br></br>
                            <br></br>
                            Condicions generals d'utilització de la informació continguda a la seu electrònica del ‘Butlletí Oficial del Principat d'Andorra'
                            Pel que fa a la utilització de la informació inserida a la seu electrònica del Butlletí Oficial del Principat d'Andorra, el Servei del Butlletí Oficial del Principat d'Andorra en permet:
                            <br></br>
                            - La còpia i la difusió.
                            <br></br>
                            - L'adaptació, l'extracció, la reordenació i la combinació.
                            <br></br>
                            - La reproducció, la distribució, la comunicació pública o la transformació necessària per a aquesta utilització, en qualsevol modalitat i format.
                            <br></br>
                            <br></br>
                            Les condicions per a aquesta utilització són les següents:
                            <br></br>
                            - Es prohibeix desnaturalitzar el sentit de la informació.
                            <br></br>
                            - Es prohibeix indicar, insinuar o suggerir que Servei del Butlletí Oficial del Principat d'Andorra participa, patrocina o dóna suport a aquesta utilització.
                            <br></br>
                            - Les metadades sobre la data d'actualització i les condicions d'utilització de la informació, si s'inclouen en la informació, s'han de conservar sense alterar-les, i no es poden suprimir.
                            <br></br>
                            - Es prohibeix la utilització de dominis, logotips i elements gràfics que pertanyin al Servei del Butlletí Oficial del Principat d'Andorra, i la seva forma de presentació.
                            <br></br>
                            - El Servei del Butlletí Oficial del Principat d'Andorra no es fa responsable de l'ús que els usuaris facin de la informació inserida a la seva seu electrònica, ni tampoc dels danys soferts o de les pèrdues econòmiques que, de forma directa o indirecta, produeixin o puguin produir perjudicis econòmics, materials o sobre les dades, provocats per l'ús de la informació utilitzada.


                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            Sol·licitud i tractament de les dades personals
                        </div>
                        <div className={styles.body_regular}>
                            Les dades personals que l'usuari faciliti al Servei del Butlletí Oficial del Principat d'Andorra s'incorporaran al fitxer anomenat "Subscriptors al Butlletí Oficial del Principat d'Andorra", propietat del Servei del Butlletí Oficial del Principat d'Andorra, que està inscrit a l'Agència de Protecció de Dades d'Andorra amb aquest mateix nom.
                            El Servei del Butlletí Oficial del Principat d'Andorra ha adoptat les mesures necessàries per garantir la seguretat de les dades de caràcter personal, com també per evitar-ne l'alteració, la pèrdua, el tractament o l'accés no autoritzat, en compliment del que estableix la Llei qualificada 15/2003, del 18 de desembre, de protecció de dades personals.
                            En el moment en què l'usuari proporciona les seves dades personals al Servei del Butlletí Oficial del Principat d'Andorra, dóna el seu consentiment exprés i inequívoc per al tractament de les seves dades. 
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            Drets d'accés, rectificació, supressió i oposició de les dades personals
                        </div>
                        <div className={styles.body_regular}>
                            L'usuari podrà exercir els drets d'accés, rectificació, supressió i oposició del tractament de les seves dades de caràcter personal. Aquests drets es poden fer efectius a la mateixa seu electrònica del Butlletí Oficial del Principat d'Andorra o bé adreçant-se al Servei del Butlletí Oficial del Principat d'Andorra, c/ Dr. Vilanova, 15, pl. -5, d'Andorra la Vella (Principat d'Andorra). 
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            Propietat intel·lectual i industrial
                        </div>
                        <div className={styles.body_regular}>
                            El Servei del Butlletí Oficial del Principat d'Andorra és titular o ha obtingut la llicència corresponent sobre els drets d'explotació i reproducció de la seu electrònica del Butlletí Oficial del Principat d'Andorra, així com dels drets de propietat intel·lectual i industrial de l'estructura, la selecció, l'ordenació i la presentació dels seus continguts, productes i serveis, i dels programes d'ordinador utilitzats en relació amb la mateixa seu electrònica del Butlletí Oficial del Principat d'Andorra.
                            L'accés, la navegació, la utilització, l'allotjament i la descàrrega de continguts, productes i serveis de la seu electrònica del Butlletí Oficial del Principat d'Andorra no s'entendrà en cap cas com una renúncia, transmissió, llicència o cessió total o parcial dels drets esmentats més amunt per part del Servei del Butlletí Oficial del Principat d'Andorra​. El Servei del Butlletí Oficial del Principat d'Andorra també és titular o ha obtingut la llicència corresponent dels continguts que publiqui a les diferents plataformes de comunicació, com ara, a títol enunciatiu però no limitatiu, Youtube, Twitter, Vimeo i Google+.
                            Si l'usuari té coneixement de l'existència de qualsevol contingut il·lícit, il·legal, contrari a les lleis o que pogués suposar una infracció dels drets de propietat intel·lectual o industrial, ho ha de notificar al Servei del Butlletí Oficial del Principat d'Andorra perquè adopti les mesures escaients.
                            L'usuari d'aquest portal web es compromet a respectar els drets enunciats i a evitar qualsevol actuació que pogués perjudicar-los. En qualsevol cas, el Servei del Butlletí Oficial del Principat d'Andorra es reserva l'exercici de tots els mitjans i les accions legals que li corresponguin en defensa dels seus drets legítims. 
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                            Resolució de controvèrsies, legislació aplicable i jurisdicció competent
                        </div>
                        <div className={styles.body_regular}>
                            Totes les controvèrsies que puguin sorgir pel que fa al compliment d'aquestes condicions generals es resoldran amistosament entre la part presumptament incomplidora i el Servei del Butlletí Oficial del Principat d'Andorra, el qual hi destinarà, si escau, el personal i els mitjans tècnics necessaris. En aquest cas, cada part assumirà els costos d'aquest personal i els mitjans tècnics que hi destini.
                            <br></br>
                            Des de l'endemà de la data en què el Servei del Butlletí Oficial del Principat d'Andorra comuniqui el motiu de la controvèrsia per escrit a la part presumptament incomplidora, ambdós parts disposen d'un mes natural per assolir un acord que la resolgui. En cas contrari, o si el Servei del Butlletí Oficial del Principat d'Andorra no pogués dur a terme aquesta comunicació o si, havent-la fet, no fos possible aquest procediment amistós de resolució de conflictes, el Servei del Butlletí Oficial del Principat d'Andorra podrà exercitar les accions judicials escaients.
                            <br></br>
                            Aquestes condicions generals es regeixen per la legislació andorrana, amb submissió expressa a la competència dels tribunals andorrans. 
                        </div>
                    </div> 
                </div>
            </div>
        </div>                
        








        
    )
}

export default Legal