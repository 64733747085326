import styles from './Manual.module.sass'
import '../../styles/global.sass'
import ManualUsuari from '../../assets/ilustrations/ManualUsuari.png'

function Manual() {
    return (
        <div className={styles.container}>
            <div className='margin_top'></div>
            <div className='container'>
                <h1>Manual d'usuari</h1>
                <div>
                    <div className={styles.section}>
                        <div className={styles.p1}>
                            Com buscar un document?
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                        Cerca temàtica
                        </div>
                        <div className={styles.body_regular}>
                            Permet realitzar cerques específiques seleccionant matèries i organismes concrets entre tots els documents publicats al BOPA, des de l'1 de gener de 1989 fins a l'actualitat.
                            <p></p>
                            És recomanable acotar la cerca per data de publicació. Es pot seleccionar mitjançant el calendari o introduir-la amb el format “dd/mm/aaaa”.
                        </div>
                    </div> 
                    <div className={styles.section}>
                        <div className={styles.p2}>
                        Cerca per paraules
                        </div>
                        <div className={styles.body_regular}>
                        Permet trobar qualsevol paraula inclosa dins els documents publicats.
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                        Regles generals per aquesta cerca:
                        </div>
                        <div className={styles.body_regular}>
                            <ol className={styles.body_regular}>
                                <li className={styles.body_regular}>Les paraules es poden introduir en majúscules o minúscules indistintament, i amb o sense accent o dièresis. D'aquesta manera el resultat de la recerca serà el mateix si s'escriu: "DISPOSICIÓ", "DISPOSICIO", "disposició" o “disposicio". Cal tenir en compte que, excepte pels supòsits establerts en l'apartat anterior, les paraules introduïdes es cerquen de forma exacta. Per exemple, si introduïm "dispo" no trobarem la paraula "disposició". Cal tenir en compte que, excepte pels supòsits establerts en l'apartat anterior, les paraules introduïdes es cerquen de forma exacta. Per exemple, si introduïm "dispo" no trobarem la paraula "disposició".</li>
                                <li className={styles.body_regular}>També es poden escriure les paraules entre cometes i així fer que la cerca sigui exacta.</li>
                                <li className={styles.body_regular}>Es poden utilitzar els condicionants de Google com el “AND” amb el símbol +, “OR” amb la barra | (altGr) i la tecla 1, o “NOT” amb el símbol - per acotar encara més la cerca.</li>
                            </ol>
                            <p></p>
                            El resultat es mostra per el document amb més rellevància, per data i per ordre protocol·lari.
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.p2}>
                        Com buscar un BOPA concret?
                        </div>
                        <div className={styles.body_regular}>
                        Clica a “Butlletins” i busca la publicació, o bé a través del calendari. Estan per ordre cronològic descendent, és a dir el més actual primer
                        </div>
                        <p></p>
                        <img src={ManualUsuari} />
                    </div>

                    <div className={styles.section}>
                        <div className={styles.p2}>
                        Espai personal: Accés usuari
                        </div>
                        <div className={styles.body_regular}>
                        Avantatges de registrar-se com usuari del BOPA
                        <p></p>
                        És un servei que permet rebre alertes per mail, cada vegada que es pública un BOPA, dels documents que us interessin, guardar les vostres cerques, i guardar documents com a favorits. Registrat com a usuari: Per accedir al seu espai personal, accés a l'usuari, s'ha d'entrar amb el certificat electrònic de Govern pels ciutadans d'Andorra o bé amb el compte de Microsoft o Google.
                        <p></p>
                        En aquest espai, es podran configurar les alertes, les cerques amb alerta i els meus favorits
                        </div>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>          
    )
}

export default Manual