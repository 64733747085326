import CardHelpProps from "./CardHelpProps"
import styles from './CardHelp.module.sass'
import { Link } from "react-router-dom"

function CardHelp(props:CardHelpProps) {
    return (
        props.path != null ?
        <Link to={props.path} className={styles.btn_link}>
            <div className={styles.card} onClick={props.onClick}>
                <img src={props.icon} className={styles.icon} />
                <div className={styles.caption_regular}>{props.label}</div>      
            </div>
        </Link>
            : 
        <div className={styles.card} onClick={props.onClick}>
            <img src={props.icon} className={styles.icon} />
            <div className={styles.caption_regular}>{props.label}</div>      
        </div>           
    )
}

export default CardHelp